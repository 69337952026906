import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flex: 1,
    paddingTop: 30,
  },
  nextButtonContainer: {
    height: 60,
  },
  nextButton: {
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  nextButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  innerContainer: {
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 30,
    justifyContent: 'space-between',
  },
  biometricAuthtentiactionType: {
    fontSize: 15,
  },
  biometricAuthtentiactionTextContainer: {
    marginBottom: 15,
    marginTop: 12,
    paddingHorizontal: 30,
    width: '100%',
  },
  biometricAuthtentiactionText: {
    fontSize: 12,
    color: '#777777',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-around',
    height: 50,
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: 120,
  },
  loginButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
})

export default styles
