export const SET_SELECTED_DISEASE_DETAILS = 'SET_SELECTED_DISEASE_DETAILS'
export const SET_DISEASE_PROBAND = 'SET_DISEASE_PROBAND'
export const SET_INDIVIDUAL_DISEASE = 'SET_INDIVIDUAL_DISEASE'
export const SET_SCREEN_WHERE_TO_NAVIGATE = 'SET_SCREEN_WHERE_TO_NAVIGATE'
export const SET_SKIP_LOGIC = 'SET_SKIP_LOGIC'
export const SET_CONVERTED_MEMBER_DISEASE = 'SET_CONVERTED_MEMBER_DISEASE'
export const SET_CONVERTED_MEMBER_SKIP_LOGIC = 'SET_CONVERTED_MEMBER_SKIP_LOGIC'
export const SET_FROM_ONBOARDING_DISEASES = 'SET_FROM_ONBOARDING_DISEASES'
export const SET_TYPE_BY_ORG = 'SET_TYPE_BY_ORG'
export const SET_IS_SINGLE_CATEGORY_DISEASE = 'SET_IS_SINGLE_CATEGORY_DISEASE'

export const RESET_DISEASES_STORE = 'RESET_DISEASES_STORE'
