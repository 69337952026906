import * as actionTypes from './actionTypes'

const setRoutesAction = (data) => ({
  type: actionTypes.SET_ROUTES,
  data,
})

const setNextIndexAction = (data) => ({
  type: actionTypes.SET_NEXT_INDEX,
  data,
})
const setHasForcePassword = (data) => ({
  type: actionTypes.SET_FORCE_PASSWORD,
  data,
})

const setWorkflowFlags = (data) => ({
  type: actionTypes.SET_WORKFLOW_FLAGS,
  data,
})

const setIsCustomWorkflow = (data) => ({
  type: actionTypes.SET_IS_CUSTOM_WORKFLOW,
  data,
})

const clearWorkflowDataAction = () => ({
  type: actionTypes.CLEAR_WORKFLOW_DATA,
})

const setWorkflowType = (data) => ({
  type: actionTypes.SET_WORKFLOW_TYPE,
  data,
})

export {
  setRoutesAction,
  setNextIndexAction,
  setWorkflowFlags,
  setIsCustomWorkflow,
  clearWorkflowDataAction,
  setHasForcePassword,
  setWorkflowType,
}
