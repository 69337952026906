import { CUSTOM_FLOW_ROUTES } from './routes'

const defaultRoutes = [
  CUSTOM_FLOW_ROUTES?.PersonalGender.name,
  CUSTOM_FLOW_ROUTES?.PersonalGenderIdentity.name,
  CUSTOM_FLOW_ROUTES?.DiseaseListMainScreen.name,
  CUSTOM_FLOW_ROUTES?.PersonalGeneticTesting.name,
  CUSTOM_FLOW_ROUTES?.PersonalEthnicity.name,
  CUSTOM_FLOW_ROUTES?.PersonalAncestry.name,
  CUSTOM_FLOW_ROUTES?.ParentsGrandparentsList.name,
]

export default defaultRoutes
