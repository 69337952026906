import { Text, View, Image } from 'react-native'

import { useSelector } from 'react-redux'
import { i18n } from '$localization/config.js'

import styles from '../../static/main.styles'
import ContinueButtonPage3 from './components/ContinueButtonPage3'

function FamilyScreenWalkthroughPage3({
  tutorialWithSurvey,
  onFamilyScreenWalkthroughCloseAction,
  extraHeaderImageHeight,
}) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const contentDefault = i18n.t(
    'when_you_are_ready_to_see_your_risk_assessment_go_to_the_risk_section'
  )?.default
  const contentWithSurvey = i18n.t(
    'your_clinician_has_a_few_more_questions_for_you_to_fill_out_in_survey_section'
  )?.default

  return (
    <View
      style={styles.walkthroughContentContainer(colorSettings?.splash_bgcolor)}
    >
      <View style={{ height: 190 + extraHeaderImageHeight }}>
        <View
          style={{
            flex: 0.9,
            backgroundColor: colorSettings?.onboarding_bgcolor,
          }}
        />

        <View style={styles.headerImageContainer}>
          <Image
            style={styles.headerImage}
            source={require('$assets/images/new_assets/tutorial-images-set-2/slide-3.png')}
          />
        </View>
      </View>

      <View style={{ height: 16 }} />

      <View style={{ height: '100%' }}>
        <View style={{ paddingVertical: 8, paddingHorizontal: 50 }}>
          <Text
            style={styles.walkthroughContentText(
              colorSettings?.text_input_title_color_1
            )}
          >
            {tutorialWithSurvey ? contentWithSurvey : contentDefault}
          </Text>
        </View>

        <View style={{ height: 16 }} />

        <ContinueButtonPage3
          onFamilyScreenWalkthroughCloseAction={
            onFamilyScreenWalkthroughCloseAction
          }
          tutorialWithSurvey={tutorialWithSurvey}
        />
      </View>
    </View>
  )
}

export default FamilyScreenWalkthroughPage3
