import { StyleSheet, useWindowDimensions } from 'react-native'

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    flex: 1.0,
  },
  contentContainer: {
    flex: 1.0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    backgroundColor: 'white',
    alignItems: 'center',
  },
  contentHeader: {
    width: '100%',
  },
  contentBody: {
    flex: 1.0,
    alignItems: 'center',
    justifyContent: 'center',
  },

  dismissButtonView: {
    flexDirection: 'row',
    margin: 10,
    justifyContent: 'flex-end',
  },

  nextButton: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 37.5,
    borderWidth: 0.5,
    height: 60,
    marginTop: 8,
  },
  nextButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
  },

  footerButtonContainer: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerButtonText: {
    textAlign: 'center',
    fontFamily: 'montserrat-medium',
    fontSize: 16,
    color: 'rgb(65,70,97)',
  },

  bulletText: {
    color: 'rgb(102,102,102)',
    fontFamily: 'montserrat',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: 24,
  },
  messageHeadText: {
    fontFamily: 'karla-bold',
    fontSize: 18,
    lineHeight: 20,
    color: 'rgb(65,70,97)',
    textAlign: 'center',
    paddingBottom: 12,
  },
  messageBodyText: {
    color: 'rgb(102,102,102)',
    marginVertical: 12,
    fontFamily: 'montserrat',
    fontSize: 15,
    lineHeight: 20,
    textAlign: 'center',
  },
  paginatorContainer: {
    backgroundColor: 'white',
    paddingTop: 10,
  },
  paginatorDotStyle: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginHorizontal: -4,
  },
  paginatorDotStyleWeb: {
    height: 15,
    width: 15,
    borderRadius: 8,
    marginHorizontal: 0,
    paddingHorizontal: 0,
  },
  containerButton: {
    justifyContent: 'flex-end',
  },
  scrollContainer: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  siblingsScrollViewContainer: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 16,
    marginVertical: 25,
  },
  maxWidthAndHeight: {
    width: '100%',
    height: '100%',
  },
})

export default styles
