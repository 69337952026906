import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  paginatorContainer: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    width: '100%',
    paddingVertical: 8,
    height: 60,
  },
  paginatorDotStyle: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginHorizontal: -4,
  },
  doneButtonContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    height: 60,
    width: '25%',
  },
  doneButton: {
    width: '100%',
    height: '100%',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingRight: 16,
  },
  doneButtonText: {
    color: 'rgba(255,255,255,0.5)',
    color: 'white',
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'center',
    fontSize: 16,
  },
})
