import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  safeAreaStyles: {
    flex: 1,
    width: '100%',
    backgroundColor: 'white',
  },
  screenContentContainer: { justifyContent: 'center' },
  switchToFGAccountHeaderTextContainer: {
    paddingHorizontal: 15,
    paddingVertical: 20,
    justifyContent: 'center',
    maxWidth: 600,
    alignItems: 'center',
    alignSelf: 'center',
  },
  switchToFGAccountHeaderText: (colorSettings) => ({
    textAlign: 'center',
    fontSize: 18,
    fontFamily: 'montserrat-medium',
    color: colorSettings?.text_input_title_color_1 ?? 'grey',
  }),
  formContainer: {
    flex: 1,
    width: '100%',
    maxWidth: 500,
    paddingHorizontal: 25,
    marginTop: 5,
    alignSelf: 'center',
  },
  formFieldTextInputPasswordContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
  formFieldTextInputField: {
    fontFamily: 'montserrat',
    fontSize: 18,
    fontWeight: 'normal',
    color: 'black',
  },
  formFieldTextInputPassword: {
    paddingRight: 24,
  },
  formFieldShowPasswordContainer: {
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 1,
  },
  formFieldShowPasswordImage: {
    height: 24,
    width: 24,
    resizeMode: 'contain',
  },
  formFieldHelperTextContainer: {
    marginVertical: 10,
  },
  formFieldPasswordHelperText: {
    color: 'rgba(74,74,74,0.5)',
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'left',
  },
})

export default styles
