import { Platform } from 'react-native'
import { printToFileAsync } from 'expo-print'
import { shareAsync } from 'expo-sharing'
import uuid from 'react-native-uuid'

const printPpdfOnWeb = async (htmlString) => {
  const html2pdf = require('html2pdf.js')

  const pdfOptions = {
    margin: [15, 20],
    filename: `${uuid.v4()}.pdf`,
    image: { type: 'jpeg', quality: 1 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
    pagebreak: { mode: ['avoid-all'] },
  }

  try {
    await html2pdf().set(pdfOptions).from(htmlString).save()
  } catch (error) {
    console.error('Error generating PDF:', error)
  }
}

export const printPdf = async (htmlTemplate) => {
  if (Platform.OS === 'web') return printPpdfOnWeb(htmlTemplate)
  try {
    const result = await printToFileAsync({
      html: htmlTemplate,
      base64: false,
      margins: {
        left: 15,
        top: 20,
        right: 15,
        bottom: 20,
      },
    })
    await shareAsync(result.uri, { UTI: '.pdf', mimeType: 'application/pdf' })
  } catch (error) {
    console.log(error)
  }
}
