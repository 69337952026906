const operation = (list1, list2, isUnion = false) =>
  list1.filter(
    (a) => isUnion === list2.some((b) => a.ancestry_id === b.ancestry_id)
  )

const union = (list1, list2) => operation(list1, list2, true)
const firstListDiff = operation
const secondListDiff = (list1, list2) => firstListDiff(list2, list1)

export { union, firstListDiff, secondListDiff }
