import { Text, View, TextInput, StyleSheet } from 'react-native'

const InputTextAnswerView = ({
  colorSettings,
  currentInput,
  setCurrentInput,
  validationChecks,
  textOnFocus,
}) => (
  <View style={styles.mainContainer}>
    <TextInput
      nativeID="web_text_input"
      allowFontScaling
      onChangeText={setCurrentInput}
      onBlur={validationChecks}
      onEndEditing={validationChecks}
      value={`${currentInput}`}
      onFocus={textOnFocus}
      returnKeyType="done"
      multiline
      placeholder="Please describe"
      placeholderTextColor={
        colorSettings?.text_input_placeholder_color_2 || 'rgba(74, 74, 74, 0.5)'
      }
      style={[
        styles.inputField,
        {
          borderBottomColor: colorSettings?.btn_no_fill_border_2 || 'black',
          color: colorSettings?.text_input_color_2 || 'black',
        },
      ]}
    />
  </View>
)

const styles = StyleSheet.create({
  mainContainer: {
    marginBottom: 20,
  },
  inputField: {
    // backgroundColor: "black",
    width: '100%',
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    // color: "white",
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'center',
    height: 50,
    borderBottomWidth: 0.5,
    alignSelf: 'center',
  },
})

export default InputTextAnswerView
