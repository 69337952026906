import React from 'react'
import { createStackNavigator } from '@react-navigation/stack' // enabled for web

import { useSelector } from 'react-redux'

import { i18n } from '$localization/config.js'

import RelativePositiveGeneTest from '$screens/personal_details/relative_gene_test/RelativePositiveGeneTest.js'
import RelativeNegativeGeneTest from '$screens/personal_details/relative_gene_test/RelativeNegativeGeneTest.js'

import headerNavigationDefaultOptions from '$navigation/_utils/HeaderNavigationDefaultOptions'
import HeaderTitleComponent from '$navigation/_components/HeaderTitleComponent'
import LeftButtonComponent from '$navigation/_components/LeftButtonComponent'

const Stack = createStackNavigator()

function RelativeGeneTestNavigator() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator initialRouteName="RelativePositiveGeneTestScreen">
      <Stack.Screen
        name="RelativePositiveGeneTestScreen"
        component={RelativePositiveGeneTest}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('genetic_testings').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
      <Stack.Screen
        name="RelativeNegativeGeneTestScreen"
        component={RelativeNegativeGeneTest}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('genetic_testings').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}

export default RelativeGeneTestNavigator
