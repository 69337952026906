import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: (colorSettings) => ({
    backgroundColor: colorSettings?.onboarding_bgcolor,
    width: '100%',
    flex: 1,
  }),
  mainContentContainer: {
    padding: 15,
    marginBottom: 25,
    flexGrow: 1,
  },
  shortWorkflowHeaderContainer: {
    width: '100%',
    flex: 0.2,
    paddingHorizontal: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  shortWorkflowHeaderTextContainer: {
    width: '100%',
    paddingVertical: 3,
    maxWidth: 350,
  },
  shortWorkflowHeaderTextMain: (colorSettings) => ({
    color: colorSettings?.text_input_title_color_2 || 'black',
    textAlign: 'center',
    fontFamily: 'karla-bold',
    fontSize: 24,
  }),
  shortWorkflowHeaderTextSub: (colorSettings) => ({
    color: colorSettings?.text_input_title_color_2 || 'black',
    textAlign: 'center',
    fontFamily: 'karla',
    fontSize: 16,
  }),
})

export default styles
