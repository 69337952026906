import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flex: 1.0,
  },
  contentContainer: {
    // backgroundColor: "#eee",
    flex: 1.0,
  },
  content: {
    flex: 1.0,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  titleTextContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 32,
  },
  titleText: {
    fontFamily: 'karla-bold',
    fontSize: 24,
    textAlign: 'center',
  },

  optionsContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
  },
  optionButton: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
    width: '100%',
    borderRadius: 30,
    borderWidth: 2,
    marginVertical: 5,
  },
  optionButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
  },

  mainTextInputContainer: {
    justifyContent: 'center',
  },
  mainTextInput: {
    justifyContent: 'center',
    alignItems: 'center',
    // color: "black",
    color: 'rgba(74,74,74,1.0)',
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'left',
  },

  helperTextContainer: {
    width: '100%',
    justifyContent: 'center',
  },

  helperText: {
    fontFamily: 'montserrat',
    fontSize: 14,
    color: 'rgba(74,74,74,0.5)',
    textAlign: 'left',
  },

  loadingIndicatorContainer: {
    position: 'absolute',
    bottom: 32,
    height: 60,
    width: '100%',
  },

  nextButtonContainer: {
    height: 60,
    width: '100%',
  },
  nextButton: {
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    borderWidth: 1,
  },
  nextButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
})

export default styles
