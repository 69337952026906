import { Text, View } from 'react-native'
import styles from '../main.styles'

const ScreenDivider = () => (
  <View style={styles.dividerOrContainer}>
    <View style={styles.dividerOrLines} />
    <Text style={styles.dividerOrText}>OR</Text>
    <View style={styles.dividerOrLines} />
  </View>
)

export default ScreenDivider
