import { Component } from 'react'
import {
  SafeAreaView,
  Text,
  View,
  ScrollView,
  StyleSheet,
  Image,
  ImageBackground,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Modal,
  Dimensions,
  SectionList,
  Platform,
} from 'react-native'

import DefaultNavigationOptions from '$navigation/_components/DefaultNavigationOptions.js'

class ResearchDataSettings extends Component {
  // ========================== header ==========================
  static navigationOptions = ({ navigation }) => {
    const options = DefaultNavigationOptions({
      navigation,
      hasCloseButton: true,
      closeButtonType: 'back',
    })

    return options
  }

  // ========================== end of header ==========================

  render() {
    return (
      <SafeAreaView>
        <Text>Research Data Settings</Text>
      </SafeAreaView>
    )
  }
}

export default ResearchDataSettings
