import { Text, View } from 'react-native'
import styles from './styles'
import { i18n } from '$localization/config.js'

function GeneItemList({ item }) {
  const renderGeneticResultText = () => {
    if (!item?.result) return

    switch (item.result) {
      case 'unsure':
        return `${item.umls_name} - ${i18n.t('unsure.title')}`
      case 'vus':
        return `${item.umls_name} - VUS`
      case 'n':
        return `${item.umls_name}`
      default:
        return `${item.umls_name} - ${i18n.t('positive').lowercase}`
    }
  }

  return (
    <View style={styles.genesViewListItemContainer}>
      <Text style={styles.genesViewListItemText}>
        {renderGeneticResultText()}
      </Text>
    </View>
  )
}

export default GeneItemList
