import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { i18n } from '$localization/config.js'

const ButtonShowMoreView = ({
  colorSettings,
  toggled,
  handleOnPress,
  renderThis,
}) => {
  const styleHandler = () => {
    if (toggled) {
      return {
        backgroundColor:
          colorSettings?.selectable_btn_active_1 || 'rgba(0,0,0,0.2)',
        borderColor: colorSettings?.selectable_btn_border_active_1,
      }
    }
    return {
      borderColor: colorSettings?.selectable_btn_border_inactive_1,
    }
  }

  const HandleShowThisButton = () => {
    if (renderThis) {
      return (
        <TouchableOpacity
          style={[styles.showMoreButton, styleHandler()]}
          onPress={() => handleOnPress()}
          activeOpacity={1}
        >
          <Text
            style={[
              styles.text,
              {
                color: colorSettings?.selectable_btn_text_active_1 || 'black',
              },
            ]}
          >
            {i18n.t('show_more').default}
          </Text>
        </TouchableOpacity>
      )
    }
    return <View />
  }

  return <HandleShowThisButton />
}

const styles = StyleSheet.create({
  showMoreButton: {
    borderWidth: 2,
    borderColor: 'rgba(255,255,255, .5)',
    height: 36,
    marginTop: 10,
    borderRadius: 18,
    justifyContent: 'center',
    marginBottom: 20,
    marginHorizontal: 100,
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  text: {
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    textAlign: 'center',
  },
})

export default ButtonShowMoreView
