import { View, Text, Image } from 'react-native'

import { i18n } from '$localization/config.js'
import styles from '../static/main.styles'

function DummyRisksTab({ screenWidth }) {
  return (
    <View>
      <View style={styles.dummyRiskTabContainer}>
        <View
          style={styles.dummyRiskTabContentContainer(
            'rgba(255,255,255,1.0)',
            screenWidth
          )}
        >
          <View style={styles.dummyRiskTabContent}>
            <Image
              source={require('$assets/images/icon/Tab_bar/Icons/risks/Active.png')}
              style={styles.dummyRiskTabContentImage}
            />

            <Text style={styles.dummyRiskTabContentText('rgb(65,70,98)')}>
              {i18n.t('risks').title}
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}

export default DummyRisksTab
