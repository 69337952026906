const sampleData = {
  header_title: 'Custom Main Title',
  sub_title: 'sub title here',
  custom_categories: [
    {
      id: 1,
      name: 'name',
      type: 'text',
      is_required: true,
      label: 'What is your name?',
      placeholder: 'Name',
      order: 1,
      input_choices: [],
      has_toggle: false,
    },
    {
      id: 2,
      name: 'gender',
      type: 'toggle',
      is_required: true,
      label: 'Do you have siblings?',
      placeholder: 'Gender',
      order: 2,
      input_choices: [
        { id: 1, choice: 'male' },
        { id: 2, choice: 'female' },
      ],
      has_toggle: false,
    },
    {
      id: 3,
      name: 'hobbies',
      type: 'multi-select',
      is_required: false,
      label: 'What are your Hobbies?',
      placeholder: 'Hobbies',
      order: 3,
      input_choices: [
        { id: 1, choice: 'singing', order: 3 },
        { id: 2, choice: 'dancing', order: 2 },
        { id: 3, choice: 'painting', order: 4 },
        { id: 4, choice: 'playing musical instruments', order: 1 },
      ],
      has_toggle: false,
    },
    {
      id: 3,
      name: 'civil status',
      type: 'single-select',
      is_required: false,
      label: "What's your Civil Status?",
      placeholder: 'Civil Status',
      order: 3,
      input_choices: [
        { id: 1, choice: 'married', order: 4 },
        { id: 2, choice: 'single', order: 1 },
        { id: 3, choice: 'divorced', order: 3 },
        { id: 4, choice: 'widowed', order: 2 },
      ],
      has_toggle: false,
    },
    {
      id: 4,
      name: 'age',
      type: 'number',
      is_required: true,
      label: 'Age',
      placeholder: 'Age',
      order: 4,
      input_choices: [],
      has_toggle: true,
    },
    {
      id: 5,
      name: 'date',
      type: 'date',
      is_required: true,
      label: 'Date of Birth',
      placeholder: 'Date of Birth',
      order: 5,
      input_choices: [],
      has_toggle: false,
    },
  ],
}

export default sampleData
