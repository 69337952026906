import { View, Text } from 'react-native'
import ButtonMainView from './ButtonMain.view'
import { i18n } from '$localization/config.js'

export const ButtonMainLogic = ({ colorSettings, buttonItem, onPress }) => {
  const convertItemsToButtonText = (item) => {
    let buttonTitle = item
      .toLowerCase()
      .replace(/\//g, ' ')
      .replace(/[^\w-\s]/gi, '')
      .replace(/\s/g, '_')
      .replace(/_conditions/, '')

    if (item.toLowerCase().includes('cancer'))
      return i18n.t(buttonTitle).default
    if (!item.includes('cancer') || !item.includes('polyps')) {
      buttonTitle += '_conditions'
    }

    return i18n.t(buttonTitle).default || item
  }

  const handleItemOnPress = () => {
    onPress(buttonItem.title)
  }

  return (
    <ButtonMainView
      colorSettings={colorSettings}
      buttonName={convertItemsToButtonText(buttonItem?.title ?? '')}
      onPress={handleItemOnPress}
    />
  )
}
