const survey = {
  id: 1,
  name: 'Patient Info',
  total_pages: 4,
  total_questions: 15,

  // org: Arizona Oncology Fake
  survey_organization: {
    id: 1,
    clinician_id: 1,
    survey_id: 1,
  },

  survey_categories: [
    {
      id: 1,
      category_name: 'Name',
      survey_id: 1,
      category_label: 'What is your Name?',
      order: 1,
      page_num: 1,
      question_workflows: [
        {
          id: 1,
          order: 1,
          sub_order: null,
          show: true,
          master_question_id: 1,
          survey_category_id: 1,
          master_question: {
            id: 1,
            question_uuid: 'first_name',
            question_label: '',
            type: 'text_short',
            place_holder_text: 'First Name',
            master_question_choices: [],
            question: null,
            // question: {
            //   id: null,
            //   question_label: null,
            //   master_question_id: null,
            //   survey_organization_id: null,
            //   question_choices: [
            //     {id: null, show: true, master_question_choice_id: null, question_id: null}
            //   ]
            // },
            question_prerequisites: [
              // {id: null, operator: '', value: '', master_question_id: '', master_question_prerequisite_id: ''}
            ],
          },
        },
        {
          id: 2,
          order: 2,
          sub_order: null,
          show: true,
          master_question_id: 2,
          survey_category_id: 1,
          master_question: {
            id: 2,
            question_uuid: 'middle_name',
            question_label: '',
            type: 'text_short',
            place_holder_text: 'Middle Name',
            master_question_choices: [],
            question: null,
            // question: {
            //   id: null,
            //   question_label: null,
            //   master_question_id: null,
            //   survey_organization_id: null,
            //   question_choices: [
            //     {id: null, show: true, master_question_choice_id: null, question_id: null}
            //   ]
            // },
            question_prerequisites: [
              // {id: null, operator: '', value: '', master_question_id: '', master_question_prerequisite_id: ''}
            ],
          },
        },
        {
          id: 3,
          order: 3,
          sub_order: null,
          show: true,
          master_question_id: 3,
          survey_category_id: 1,
          master_question: {
            id: 3,
            question_uuid: 'last_name',
            question_label: '',
            type: 'text_short',
            place_holder_text: 'Last Name',
            master_question_choices: [],
            question: null,
            // question: {
            //   id: null,
            //   question_label: null,
            //   master_question_id: null,
            //   survey_organization_id: null,
            //   question_choices: [
            //     {id: null, show: true, master_question_choice_id: null, question_id: null}
            //   ]
            // },
            question_prerequisites: [
              // {id: null, operator: '', value: '', master_question_id: '', master_question_prerequisite_id: ''}
            ],
          },
        },
      ],
    },
    {
      id: 2,
      category_name: 'Address',
      survey_id: 1,
      category_label: 'What is your Address?',
      order: 2,
      page_num: 1,
      question_workflows: [
        {
          id: 4,
          order: 1,
          sub_order: null,
          show: true,
          master_question_id: 4,
          survey_category_id: 2,
          master_question: {
            id: 4,
            question_uuid: 'address_1',
            question_label: '',
            type: 'text_short',
            place_holder_text: 'Address 1',
            master_question_choices: [],
            question: null,
            // question: {
            //   id: null,
            //   question_label: null,
            //   master_question_id: null,
            //   survey_organization_id: null,
            //   question_choices: [
            //     {id: null, show: true, master_question_choice_id: null, question_id: null}
            //   ]
            // },
            question_prerequisites: [
              // {id: null, operator: '', value: '', master_question_id: '', master_question_prerequisite_id: ''}
            ],
          },
        },
        {
          id: 5,
          order: 2,
          sub_order: null,
          show: true,
          master_question_id: 5,
          survey_category_id: 2,
          master_question: {
            id: 5,
            question_uuid: 'address_2',
            question_label: '',
            type: 'text_short',
            place_holder_text: 'Address 2',
            master_question_choices: [],
            question: null,
            // question: {
            //   id: null,
            //   question_label: null,
            //   master_question_id: null,
            //   survey_organization_id: null,
            //   question_choices: [
            //     {id: null, show: true, master_question_choice_id: null, question_id: null}
            //   ]
            // },
            question_prerequisites: [
              // {id: null, operator: '', value: '', master_question_id: '', master_question_prerequisite_id: ''}
            ],
          },
        },
        {
          id: 6,
          order: 3,
          sub_order: null,
          show: true,
          master_question_id: 6,
          survey_category_id: 2,
          master_question: {
            id: 6,
            question_uuid: 'city',
            question_label: '',
            type: 'text_short',
            place_holder_text: 'City',
            master_question_choices: [],
            question: null,
            // question: {
            //   id: null,
            //   question_label: null,
            //   master_question_id: null,
            //   survey_organization_id: null,
            //   question_choices: [
            //     {id: null, show: true, master_question_choice_id: null, question_id: null}
            //   ]
            // },
            question_prerequisites: [
              // {id: null, operator: '', value: '', master_question_id: '', master_question_prerequisite_id: ''}
            ],
          },
        },
        {
          id: 7,
          order: 4,
          sub_order: null,
          show: true,
          master_question_id: 7,
          survey_category_id: 2,
          master_question: {
            id: 7,
            question_uuid: 'state',
            question_label: '',
            type: 'long_list_search',
            place_holder_text: 'State...',
            master_question_choices: [
              { id: 1, choice: 'Florida', value: 'fl', master_question_id: 7 },
              {
                id: 2,
                choice: 'New Hampshire',
                value: 'nh',
                master_question_id: 7,
              },
              { id: 3, choice: 'Texas', value: 'tx', master_question_id: 7 },
              { id: 4, choice: 'Arizona', value: 'az', master_question_id: 7 },
              { id: 5, choice: 'Colorado', value: 'co', master_question_id: 7 },
            ],
            question: null,
            // question: {
            //   id: null,
            //   question_label: null,
            //   master_question_id: null,
            //   survey_organization_id: null,
            //   question_choices: [
            //     {id: null, show: true, master_question_choice_id: null, question_id: null}
            //   ]
            // },
            question_prerequisites: [
              // {id: null, operator: '', value: '', master_question_id: '', master_question_prerequisite_id: ''}
            ],
          },
        },
        {
          id: 8,
          order: 5,
          sub_order: null,
          show: true,
          master_question_id: 8,
          survey_category_id: 2,
          master_question: {
            id: 8,
            question_uuid: 'zip_code',
            question_label: '',
            type: 'text_short',
            place_holder_text: 'Zip Code',
            master_question_choices: [],
            question: null,
            // question: {
            //   id: null,
            //   question_label: null,
            //   master_question_id: null,
            //   survey_organization_id: null,
            //   question_choices: [
            //     {id: null, show: true, master_question_choice_id: null, question_id: null}
            //   ]
            // },
            question_prerequisites: [
              // {id: null, operator: '', value: '', master_question_id: '', master_question_prerequisite_id: ''}
            ],
          },
        },
      ],
    },
    {
      id: 3,
      category_name: 'Exercise',
      survey_id: 1,
      category_label: 'Describe your exercise routine in a few sentences',
      order: 1,
      page_num: 2,
      question_workflows: [
        {
          id: 9,
          order: 1,
          sub_order: null,
          show: true,
          master_question_id: 9,
          survey_category_id: 3,
          master_question: {
            id: 9,
            question_uuid: 'exercise_routine',
            question_label: '',
            type: 'text_long',
            place_holder_text: 'Exercise Routine',
            master_question_choices: [],
            question: null,
            // question: {
            //   id: null,
            //   question_label: null,
            //   master_question_id: null,
            //   survey_organization_id: null,
            //   question_choices: [
            //     {id: null, show: true, master_question_choice_id: null, question_id: null}
            //   ]
            // },
            question_prerequisites: [
              // {id: null, operator: '', value: '', master_question_id: '', master_question_prerequisite_id: ''}
            ],
          },
        },
      ],
    },
    {
      id: 4,
      category_name: 'Age',
      survey_id: 1,
      category_label: 'What is your age',
      order: 2,
      page_num: 2,
      question_workflows: [
        {
          id: 10,
          order: 1,
          sub_order: null,
          show: true,
          master_question_id: 10,
          survey_category_id: 4,
          master_question: {
            id: 10,
            question_uuid: 'age',
            question_label: '',
            type: 'integer',
            place_holder_text: 'age',
            master_question_choices: [],
            question: null,
            // question: {
            //   id: null,
            //   question_label: null,
            //   master_question_id: null,
            //   survey_organization_id: null,
            //   question_choices: [
            //     {id: null, show: true, master_question_choice_id: null, question_id: null}
            //   ]
            // },
            question_prerequisites: [
              // {id: null, operator: '', value: '', master_question_id: '', master_question_prerequisite_id: ''}
            ],
          },
        },
      ],
    },
    {
      id: 5,
      category_name: 'Genetic Testing Question',
      survey_id: 1,
      category_label:
        'Have you ever had genetic testing for hereditary cancer?',
      order: 3,
      page_num: 2,
      question_workflows: [
        {
          id: 11,
          order: 1,
          sub_order: null,
          show: true,
          master_question_id: 11,
          survey_category_id: 5,
          master_question: {
            id: 11,
            question_uuid: 'genetic_testing_hereditary_cancer',
            question_label: '',
            type: 'yes_or_no',
            place_holder_text: '',
            master_question_choices: [],
            question: null,
            // question: {
            //   id: null,
            //   question_label: null,
            //   master_question_id: null,
            //   survey_organization_id: null,
            //   question_choices: [
            //     {id: null, show: true, master_question_choice_id: null, question_id: null}
            //   ]
            // },
            question_prerequisites: [
              // {id: null, operator: '', value: '', master_question_id: '', master_question_prerequisite_id: ''}
            ],
          },
        },
      ],
    },
    {
      id: 6,
      category_name: 'Sports Exercise',
      survey_id: 1,
      category_label: 'Select all the sports you have played?',
      order: 1,
      page_num: 3,
      question_workflows: [
        {
          id: 12,
          order: 1,
          sub_order: null,
          show: true,
          master_question_id: 12,
          survey_category_id: 6,
          master_question: {
            id: 12,
            question_uuid: 'sports_exercise',
            question_label: '',
            type: 'select_all_that_apply',
            place_holder_text: '',
            master_question_choices: [
              {
                id: 6,
                choice: 'Hockey',
                value: 'hockey',
                master_question_id: 12,
              },
              {
                id: 7,
                choice: 'Baseball',
                value: 'baseball',
                master_question_id: 12,
              },
              { id: 8, choice: 'Golf', value: 'golf', master_question_id: 12 },
              {
                id: 9,
                choice: 'Football',
                value: 'football',
                master_question_id: 12,
              },
              {
                id: 10,
                choice: 'Basketball',
                value: 'basketball',
                master_question_id: 12,
              },
            ],
            question: null,
            // question: {
            //   id: null,
            //   question_label: null,
            //   master_question_id: null,
            //   survey_organization_id: null,
            //   question_choices: [
            //     {id: null, show: true, master_question_choice_id: null, question_id: null}
            //   ]
            // },
            question_prerequisites: [
              // {id: null, operator: '', value: '', master_question_id: '', master_question_prerequisite_id: ''}
            ],
          },
        },
      ],
    },
    {
      id: 7,
      category_name: 'Height Range',
      survey_id: 1,
      category_label: 'Select the range your height is in',
      order: 2,
      page_num: 3,
      question_workflows: [
        {
          id: 13,
          order: 1,
          sub_order: null,
          show: true,
          master_question_id: 13,
          survey_category_id: 7,
          master_question: {
            id: 13,
            question_uuid: 'height_range',
            question_label: '',
            type: 'select_one',
            place_holder_text: '',
            master_question_choices: [
              {
                id: 11,
                choice: '0-24 in.',
                value: '0_24',
                master_question_id: 13,
              },
              {
                id: 12,
                choice: '25-48 in.',
                value: '25_48',
                master_question_id: 13,
              },
              {
                id: 13,
                choice: '49-72 in.',
                value: '49_72',
                master_question_id: 13,
              },
              {
                id: 14,
                choice: '73-96 in.',
                value: '73_96',
                master_question_id: 13,
              },
            ],
            question: null,
            // question: {
            //   id: null,
            //   question_label: null,
            //   master_question_id: null,
            //   survey_organization_id: null,
            //   question_choices: [
            //     {id: null, show: true, master_question_choice_id: null, question_id: null}
            //   ]
            // },
            question_prerequisites: [
              // {id: null, operator: '', value: '', master_question_id: '', master_question_prerequisite_id: ''}
            ],
          },
        },
      ],
    },
    {
      id: 8,
      category_name: 'Phone',
      survey_id: 1,
      category_label: 'Phone Number',
      order: 1,
      page_num: 4,
      question_workflows: [
        {
          id: 14,
          order: 1,
          sub_order: null,
          show: true,
          master_question_id: 14,
          survey_category_id: 8,
          master_question: {
            id: 14,
            question_uuid: 'phone_number',
            question_label: '',
            type: 'phone',
            place_holder_text: '',
            master_question_choices: [],
            question: null,
            // question: {
            //   id: null,
            //   question_label: null,
            //   master_question_id: null,
            //   survey_organization_id: null,
            //   question_choices: [
            //     {id: null, show: true, master_question_choice_id: null, question_id: null}
            //   ]
            // },
            question_prerequisites: [
              // {id: null, operator: '', value: '', master_question_id: '', master_question_prerequisite_id: ''}
            ],
          },
        },
      ],
    },
    {
      id: 9,
      category_name: 'Date of birth',
      survey_id: 1,
      category_label: 'Date of birth',
      order: 2,
      page_num: 4,
      question_workflows: [
        {
          id: 15,
          order: 1,
          sub_order: null,
          show: true,
          master_question_id: 15,
          survey_category_id: 9,
          master_question: {
            id: 15,
            question_uuid: 'dob',
            question_label: '',
            type: 'date',
            place_holder_text: '',
            master_question_choices: [],
            question: null,
            // question: {
            //   id: null,
            //   question_label: null,
            //   master_question_id: null,
            //   survey_organization_id: null,
            //   question_choices: [
            //     {id: null, show: true, master_question_choice_id: null, question_id: null}
            //   ]
            // },
            question_prerequisites: [
              // {id: null, operator: '', value: '', master_question_id: '', master_question_prerequisite_id: ''}
            ],
          },
        },
      ],
    },
  ],

  survey_style_settings: [
    {
      id: 1,
      survey_id: 1,
      style_setting_id: 1,
      survey_organization_id: 1,
      value: '#9B59B6',
    },
    {
      id: 2,
      survey_id: 1,
      style_setting_id: 2,
      survey_organization_id: 1,
      value: '#65D1B9',
    },
    {
      id: 3,
      survey_id: 1,
      style_setting_id: 3,
      survey_organization_id: 1,
      value: '#ffffff',
    },
    {
      id: 4,
      survey_id: 1,
      style_setting_id: 4,
      survey_organization_id: 1,
      value: '"Montserrat Medium", sans-serif',
    },
    {
      id: 5,
      survey_id: 1,
      style_setting_id: 5,
      survey_organization_id: 1,
      value: '#ffffff',
    },
    {
      id: 6,
      survey_id: 1,
      style_setting_id: 6,
      survey_organization_id: 1,
      value: '1px solid #ffffff',
    },
  ],
}

const empty_survey = {
  id: null,
  name: '',
  total_pages: 0,
  total_questions: 0,
  survey_organization: null,
  survey_categories: [],
  completed_survey: null,
  completed_survey_answers: [],
  survey_style_settings: [],
}

const style_settings_mock = [
  // id = style_setting_id
  { id: 1, name: 'Background Color', code: 'background_color' },
  { id: 2, name: 'Button Color', code: 'button_color' },
  { id: 3, name: 'Text Color', code: 'text_color' },
  { id: 4, name: 'Font Family', code: 'font_family' },
  { id: 5, name: 'Progress Bar Color', code: 'progress_bar_color' },
  { id: 6, name: 'Input border bottom', code: 'input_border_bottom' },
]

export default {
  survey,
  empty_survey,
  style_settings_mock,
}
