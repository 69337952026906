import * as actionTypes from './actionTypes'

const storeMemberProfileAction = (profile) => ({
  type: actionTypes.STORE_MEMBER_PROFILE,
  data: profile,
})

const clearMemberProfileAction = () => ({
  type: actionTypes.CLEAR_MEMBER_PROFILE,
})

const toggleVitalStatusAction = (isDeceased) => ({
  type: actionTypes.TOGGLE_VITAL_STATUS,
  data: isDeceased,
})

const storeAgeOfDeathAction = (age) => ({
  type: actionTypes.STORE_AGE_OF_DEATH,
  data: age,
})

const storeCurrentMember = (currentMember) => ({
  type: actionTypes.SET_CURRENT_MEMBER,
  data: currentMember,
})

const clearCurrentMember = () => ({
  type: actionTypes.SET_CURRENT_MEMBER,
})

const setSharingSettingMember = (data) => ({
  type: actionTypes.SET_SHARING_SETTING_MEMBER,
  data,
})

const clearSharingSettingMember = () => ({
  type: actionTypes.CLEAR_SHARING_SETTING_MEMBER,
})

const setShowInviteHelperModal = (data) => ({
  type: actionTypes.SHOW_INVITE_HELPER_MODAL,
  data,
})

const setInvitedMember = (data) => ({
  type: actionTypes.SET_INVITED_MEMBER,
  data,
})

const setNewParentsForMember = (data) => ({
  type: actionTypes.SET_NEW_PARENTS_FOR_MEMBER,
  data,
})

const setDidAddNewMember = (data) => ({
  type: actionTypes.SET_DID_ADD_NEW_MEMBER,
  data,
})

const setAddNewMemberPayload = (data) => ({
  type: actionTypes.SET_ADD_NEW_MEMBER_PAYLOAD,
  data,
})

const setAddTwinsPayload = (data) => ({
  type: actionTypes.SET_ADD_TWINS_PAYLOAD,
  data,
})

/* Reloaders */
const setReloadersToDefault = () => ({
  type: actionTypes.SET_RELOADERS_TO_DEFAULT,
})

const setShouldSectionalReload = (data) => ({
  type: actionTypes.SET_SHOULD_SECTIONAL_RELOAD,
  data,
})

const setShouldReloadParents = (data) => ({
  type: actionTypes.SET_SHOULD_RELOAD_PARENTS,
  data,
})

const setShouldReloadParentsBloodRelation = (data) => ({
  type: actionTypes.SET_SHOULD_RELOAD_PARENTS_BLOOD_RELATION,
  data,
})

const setShouldReloadTwins = (data) => ({
  type: actionTypes.SET_SHOULD_RELOAD_TWINS,
  data,
})

const setShouldReloadInviteStatus = (data) => ({
  type: actionTypes.SET_SHOULD_RELOAD_INVITE_STATUS,
  data,
})

const setShouldReloadDiseases = (data) => ({
  type: actionTypes.SET_SHOULD_RELOAD_DISEASES,
  data,
})

const setShouldReloadGeneticTestings = (data) => ({
  type: actionTypes.SET_SHOULD_RELOAD_GENETIC_TESTINGS,
  data,
})
/* Reloaders */

export {
  storeMemberProfileAction,
  clearMemberProfileAction,
  toggleVitalStatusAction,
  storeAgeOfDeathAction,
  storeCurrentMember,
  clearCurrentMember,
  setSharingSettingMember,
  clearSharingSettingMember,
  setShowInviteHelperModal,
  setInvitedMember,
  setNewParentsForMember,
  setDidAddNewMember,
  setAddNewMemberPayload,
  setAddTwinsPayload,
  setReloadersToDefault,
  setShouldSectionalReload,
  setShouldReloadParents,
  setShouldReloadInviteStatus,
  setShouldReloadParentsBloodRelation,
  setShouldReloadTwins,
  setShouldReloadDiseases,
  setShouldReloadGeneticTestings,
}
