import { StyleSheet, Platform } from 'react-native'

const darkRed = 'rgba(178, 43, 38,0.4)'
const lightRed = 'rgba(238, 200, 197,0.9)'
const darkerRed = 'rgba(178, 43, 38,1)'

const darkGreen = 'rgba(117, 141, 106, 0.4)'
const lightGreen = 'rgba(117, 141, 106, 0.2)'
const darkerGreen = 'rgba(117, 141, 106, 1)'

const darkYellow = 'rgba(135, 103, 33,0.4)'
const lightYellow = 'rgba(135, 103, 33,0.2)'
const darkerYellow = 'rgba(135, 103, 33,1)'

const darkBlue = 'rgba(74, 131, 176,0.4)'
const lightBlue = 'rgba(74, 131, 176,0.2)'
const darkerBlue = 'rgba(74, 131, 176,1)'

export default StyleSheet.create({
  mainContainer: {
    zIndex: 999,
    position: 'absolute',
    borderWidth: 2,
    borderRadius: 10,
    flexDirection: 'row',
    backgroundColor: lightRed,
    alignSelf: 'center',
    minWidth: '80%',
    top: 25,
    alignItems: 'center',
    justifyContent: 'center',
    ...Platform.select({
      web: {
        maxWidth: 800,
        minWidth: 400,
        width: '100%',
        alignSelf: 'center',
      },
    }),
  },
  icon: {
    alignSelf: 'center',
    marginHorizontal: 17,
  },
  iconContainer: {
    flex: 1,
  },
  messageContainer: {
    marginVertical: 14,
    flex: 2,
  },
  messageTitle: {
    fontFamily: 'montserrat-semibold',
    fontSize: 15,
  },
  messageBody: { fontFamily: 'karla' },
  exitButton: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 14,
    flex: 0.5,
  },
})

export {
  darkRed,
  lightRed,
  darkerRed,
  darkGreen,
  lightGreen,
  darkerGreen,
  darkYellow,
  lightYellow,
  darkerYellow,
  darkBlue,
  lightBlue,
  darkerBlue,
}
