import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  subContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  mainLogo: { flex: 1, marginBottom: 5 },
  mainLogoInnerContainer: {
    flex: 0.4,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  clinicianLogoContainer: {
    flex: 0.4,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
  },
  clinicianLogo: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    alignItems: 'center',
    padding: 18,
    maxWidth: 800,
    marginBottom: 5,
  },
  welcomeText: {
    width: '100%',
    fontSize: 16,
    fontFamily: 'karla',
    textAlign: 'center',
    paddingLeft: 18,
    paddingRight: 18,
    fontWeight: '500',
    flexWrap: 'wrap',
  },
  loginButtonContainer: {
    flex: 0.2,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  loginButton: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    height: 65,
    width: 280,
    borderWidth: 1.0,
  },
  loginButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  poweredByLogo: { alignItems: 'center', flex: 0.08, justifyContent: 'center' },
  logoImage: { height: '70%', marginTop: 20, resizeMode: 'contain' },
  spacerLogo: { flex: 0.1 },
  appInfoContainer: { flex: 0.05, justifyContent: 'flex-end' },
})
