import { TouchableOpacity, View } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { useSelector } from 'react-redux'
import styles from '../styles'

function PrintPdfButton({ onPress }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <View style={styles.printPdfButtonContainer}>
      <TouchableOpacity style={styles.printPdfButton} onPress={onPress}>
        <AntDesign
          name="pdffile1"
          size={28}
          color={colorSettings?.nav_title_light}
        />
      </TouchableOpacity>
    </View>
  )
}

export default PrintPdfButton
