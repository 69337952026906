import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  horizontalBorder: {
    width: '120%',
    borderBottomWidth: 0.7,
    marginTop: 32,
    marginBottom: 15,
  },
  titleText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    fontFamily: 'karla-bold',
    fontSize: 24,
    marginBottom: 23,
    width: '80%',
  },
  choiceOfDiagnosisAgeContainer: {
    width: 300,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  choiceOfDiagnosisAgeButton: {
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  choiceOfDiagnosisAgeButtonText: {
    fontSize: 16,
    fontWeight: '600',
    color: 'white',
    fontFamily: 'montserrat',
    textDecorationLine: 'underline',
  },
  answerListContainer: {
    width: '100%',
    zIndex: -1,
  },
  innerQuestionnaireContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: 40,
  },
  questionnaireTitle: {
    marginBottom: 30,
    zIndex: -1,
  },
  keyboardAvoidingView: {
    flex: 1,
    flexDirection: 'column',
  },
})
