import { useState } from 'react'
import ButtonShowMoreView from './ButtonShowMore.view'

export const ButtonShowMoreLogic = ({
  colorSettings,
  toggleState = false,
  onPress,
  renderThis = false,
}) => {
  const [toggle, setToggle] = useState(toggleState)

  const handleOnPress = () => {
    setToggle((val) => !val)
    onPress(!toggle)
  }

  return (
    <ButtonShowMoreView
      colorSettings={colorSettings}
      toggled={toggle}
      handleOnPress={handleOnPress}
      renderThis={renderThis}
    />
  )
}
