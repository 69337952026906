import { useSelector } from 'react-redux'
import { Dimensions } from 'react-native'
import { useEffect, useState } from 'react'
import FamilyScreenWalkthroughPage1 from '../pages/FamilyScreenWalkthroughPage1/FamilyScreenWalkthroughPage1'
import FamilyScreenWalkthroughPage2 from '../pages/FamilyScreenWalkthroughPage2/FamilyScreenWalkthroughPage2'
import FamilyScreenWalkthroughPage3 from '../pages/FamilyScreenWalkthroughPage3/FamilyScreenWalkthroughPage3'

const windowDimensions = Dimensions.get('window')

function WalkthroughScreens({ index, onFamilyScreenWalkthroughCloseAction }) {
  const [dimensions, setDimensions] = useState({
    window: windowDimensions,
  })

  const { colorSettings, tutorialWithSurvey } = useSelector(
    (state) => state.store
  )

  const extraHeaderImageHeight = dimensions.window.height > 670 ? 20 : 0

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setDimensions({ window })
    })
    return () => subscription?.remove()
  })

  const renderCorrectFamilyScreenWalkthroughPage = () => {
    switch (index) {
      case 0:
        return (
          <FamilyScreenWalkthroughPage1
            colorSettings={colorSettings}
            extraHeaderImageHeight={extraHeaderImageHeight}
          />
        )
      case 1:
        return (
          <FamilyScreenWalkthroughPage2
            extraHeaderImageHeight={extraHeaderImageHeight}
            tutorialWithSurvey={tutorialWithSurvey}
            onFamilyScreenWalkthroughCloseAction={
              onFamilyScreenWalkthroughCloseAction
            }
          />
        )
      case 2:
        return (
          <FamilyScreenWalkthroughPage3
            extraHeaderImageHeight={extraHeaderImageHeight}
            tutorialWithSurvey={tutorialWithSurvey}
            onFamilyScreenWalkthroughCloseAction={
              onFamilyScreenWalkthroughCloseAction
            }
          />
        )
      default:
        return null
    }
  }

  return <>{renderCorrectFamilyScreenWalkthroughPage()}</>
}

export default WalkthroughScreens
