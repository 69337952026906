import React from 'react'
import ErrorCard from './errorCard'
import { i18n } from '$localization/config.js'

const ErrorView = (payload) => {
  const {
    onPress = () => {},
    title = '',
    messageBody = '',
    type = 'error',
  } = payload

  const titleKeyHandler = () => {
    if (type === 'warning') {
      return 'warn'
    }
    return 'error'
  }

  return (
    <ErrorCard
      type={type}
      title={`${i18n.t(titleKeyHandler()).default}: ${title}`}
      messageBody={messageBody}
      onPress={() => onPress(false)}
    />
  )
}

export default ErrorView
