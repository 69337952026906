export const VIEW_MY_INFO = 'view_my_info'
export const FAMILY = 'family'
export const SURVEYS = 'surveys'
export const RISKS = 'risks'
export const FAMILY_UPDATES = 'family_updates'
export const GENETIC_RESOURCES = 'genetic_resources'
export const DOCUMENTS = 'documents'
export const CLINICIANS = 'clinicians'
export const SHARING_SETTINGS = 'sharing_settings'
export const INVITE = 'invite'

export const LOGOUT = 'logout'
