import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  mainTabBarStyle: (colorSettings) => ({
    backgroundColor: colorSettings?.post_onboarding_nav_bgcolor,
  }),
  indicatorStyles: (colorSettings) => ({
    borderWidth: 1.5,
    backgroundColor: colorSettings?.nav_title_light,
    borderColor: colorSettings?.nav_title_light,
  }),
  labelStyles: (color) => ({
    color,
    margin: 5,
    fontWeight: 'bold',
  }),
  customTabBarContainer: (colorSettings) => ({
    flexDirection: 'row',
    height: 40,
    backgroundColor: colorSettings?.post_onboarding_nav_bgcolor,
    justifyContent: 'center',
    alignItems: 'center',
  }),
  customTabBarContent: { maxWidth: 1400, flexDirection: 'row', flex: 1 },
  customTabBarButton: (isFocused) => ({
    flex: 1,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomColor: isFocused ? '#ffffff' : '',
    borderBottomWidth: isFocused ? 3 : 0,
  }),
  customTabBarButtonText: {
    fontSize: 16,
    color: '#fff',
    fontFamily: 'montserrat-semibold',
  },
})

export default styles
