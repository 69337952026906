import * as actionTypes from './actionTypes'

const initialState = {
  selectedScreens: [],
  nextRouteIndex: 0,
  isCustomWorkflow: false,
  isShortCustomWorkflow: false,
  hasLastVisitedScreen: false,
  hasForcedPassword: false,
  workflowType: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_ROUTES:
      return {
        ...state,
        nextRouteIndex: action.data.nextRouteIndex,
        selectedScreens: action.data.selectedScreens,
      }

    case actionTypes.SET_NEXT_INDEX:
      return {
        ...state,
        nextRouteIndex: action.data.nextRouteIndex,
      }

    case actionTypes.SET_WORKFLOW_FLAGS:
      return {
        ...state,
        isCustomWorkflow: action.data.isCustomWorkflow,
        isShortCustomWorkflow: action.data.isShortCustomWorkflow,
        hasLastVisitedScreen: action.data.hasLastVisitedScreen,
      }

    case actionTypes.SET_FORCE_PASSWORD:
      return {
        ...state,
        hasForcedPassword: action.data.hasForcedPassword,
      }

    case actionTypes.SET_IS_CUSTOM_WORKFLOW:
      return {
        ...state,
        isCustomWorkflow: action.data.isCustomWorkflow,
      }

    case actionTypes.SET_WORKFLOW_TYPE:
      return {
        ...state,
        workflowType: action.data.workflowType,
      }

    case actionTypes.CLEAR_WORKFLOW_DATA:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
