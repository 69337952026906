export default class Account {
  // (TODO: To be removed)
  authCredentials = {
    accessToken: '',
    refreshToken: '',
  }

  userID = ''

  accountID = ''

  email = ''

  firstName = ''

  lastName = ''

  dialingCode = ''

  phoneNumber = ''

  dob = ''

  constructor(data) {
    this.accountID = data?.id ?? ''
    this.userID = data?.user_id ?? ''
    this.email = data?.email ?? ''
    this.firstName = data?.first_name ?? ''
    this.lastName = data?.last_name ?? ''
    this.dialingCode = data?.dialing_code ?? ''
    this.phoneNumber = data?.phone_number ?? ''
    this.dob = data?.dob ?? null
  }
}
