import { createStore, compose } from 'redux'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { Platform } from 'react-native'

import RootReducer from '$redux/rootReducer.js'

let composeEnhancers = compose

if (__DEV__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

const persistConfig = {
  key: 'root',
  storage: Platform.OS === 'web' ? storage : AsyncStorage,
}

const persistedReducer = persistReducer(persistConfig, RootReducer)
const store = createStore(persistedReducer, composeEnhancers())
const persistor = persistStore(store)

export { store, persistor }
