import * as actionTypes from './actionTypes'

const initialState = {
  notificationsList: [],
  selectedNotification: null,

  /* Reloaders */
  shouldReloadNotificationsList: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CLEAR_NOTIFICATIONS_STORE:
      /* Update this for every additional data in store */
      return {
        notificationsList: [],
        selectedNotification: null,
        shouldReloadNotificationsList: false,
      }

    case actionTypes.SET_NOTIFICATIONS_LIST:
      return {
        ...state,
        notificationsList: action.data,
      }

    case actionTypes.SET_SELECTED_NOTIFICATION:
      return {
        ...state,
        selectedNotification: action.data,
      }

    case actionTypes.SET_SHOULD_RELOAD_NOTIFICATIONS_LIST:
      return {
        ...state,
        shouldReloadNotificationsList: action.data,
      }

    default:
      return state
  }
}
