import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  headerBarMainTitle: {
    // color: "#fff",
    fontFamily: 'karla-bold',
    fontSize: 18,
    textAlign: 'center',
  },
  headerBarSubtitle: {
    // color: "#fff",
    fontFamily: 'karla-bold',
    fontSize: 12,
    textAlign: 'center',
  },

  container: {
    backgroundColor: '#eee',
    flex: 1.0,
  },

  headerContainer: {
    // backgroundColor: "rgb(101,209,185)",
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    // color: "white",
    marginHorizontal: 34,
    fontFamily: 'montserrat',
    fontSize: 15,
    lineHeight: 24,
    textAlign: 'center',
  },
  headerHalfRoundedButtonContainer: {
    flexDirection: 'row',
    width: '100%',
    height: 50,
    paddingHorizontal: 20,
    marginVertical: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerHalfRoundedButton: {
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    // borderColor: "white",
    borderWidth: 2.0,
  },
  headerHalfRoundedButtonText: {
    // color: "white",
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    marginHorizontal: 4,
  },
  headerFullRoundedButtonContainer: {
    flexDirection: 'row',
    width: '100%',
    height: 50,
    paddingHorizontal: 20,
    marginVertical: 8,
    alignItems: 'center',
  },
  headerFullRoundedButton: {
    // backgroundColor: "rgba(74,74,74,0.1)",
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    borderWidth: 2.0,
  },
  headerFullRoundedButtonText: {
    // color: "white",
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    marginHorizontal: 4,
  },

  contentContainer: {
    // backgroundColor: "#eee",
    width: '100%',
    paddingVertical: 16,
    paddingBottom: 16,
  },
  content: {
    width: '100%',
    flex: 1.0,
    // padding: 16,
    // margin: 16,
    // alignItems: "center",
  },

  section: {
    width: '100%',
    alignItems: 'center',
  },
  sectionTitleView: {
    flexDirection: 'row',
    width: '100%',
    height: 16,
    alignItems: 'center',
  },
  sectionTitleText: {
    // color: "rgb(65,70,97)",
    fontFamily: 'karla-bold',
    fontSize: 14,
    marginHorizontal: 16,
  },

  itemView: {
    backgroundColor: 'white',
    flexDirection: 'row',
    marginVertical: 5,
    paddingHorizontal: 8,
    height: 80,
    alignItems: 'center',
    borderColor: 'rgba(200,200,200,0.5)',
    borderWidth: 2,
    borderRadius: 5,
  },
  itemImageContainer: {
    flex: 0.2,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemDetailsContainer: {
    flex: 0.8,
    marginHorizontal: 8,
    height: '100%',
    justifyContent: 'center',
  },
  itemDetailsTitleText: {
    color: 'rgb(74,74,74)',
    fontFamily: 'montserrat-medium',
    fontSize: 12,
  },
  itemDetailsSubtitleText: {
    color: 'rgb(102,102,102)',
    fontFamily: 'montserrat',
    fontSize: 11,
  },

  nextButton: {
    // backgroundColor: "rgba(132,209,192,1.0)",
    backgroundColor: '#fff',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    borderWidth: 0.5,
  },
  nextButtonText: {
    // color: "#fff",
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
})
