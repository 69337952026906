import { StyleSheet } from 'react-native'
import { hideRisk } from '../constans/constans'

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0,0,0,0.7)',
    flex: 1.0,
  },
  contentContainer: {
    flex: 1.0,
    justifyContent: 'center',
    alignSelf: 'center',
  },

  content: (currentContentWidth, currentContentHeight) => ({
    flex: 1.0,
    marginVertical: 50,
    width: currentContentWidth,
    height: currentContentHeight,
    alignSelf: 'center',
  }),
  contentHeader: {
    backgroundColor: 'rgb(27,156,252)',
  },
  contentBody: {
    backgroundColor: 'rgba(0,255,0,0.8)',
    flexDirection: 'column',
    marginHorizontal: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },

  paginatorContainer: {
    backgroundColor: 'white',
    paddingTop: 10,
  },
  carouselContainer: {
    width: '100%',
    height: 466,
  },
  carouselContentContainer: {
    backgroundColor: 'white',
    flex: 1,
    width: '100%',
    borderRadius: 10,
    overflow: 'hidden',
  },
  dismissButtonView: {
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    top: 16,
    right: 13,
    zIndex: 3,
  },
  dismissButtonIcon: { width: 24, height: 24, resizeMode: 'contain' },
  roundedContinueButton: (backgroundColor, borderColor) => ({
    backgroundColor,
    height: 60,
    marginHorizontal: 20,
    borderRadius: 50,
    justifyContent: 'center',
    borderWidth: 0.5,
    borderColor,
  }),
  roundedContinueButtonText: (textColor) => ({
    color: textColor,
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
  }),

  floatingButton: {
    backgroundColor: 'rgb(101,209,185)',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 17,
    right: 16,
    width: 50,
    height: 50,
    borderRadius: 50,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 2,
  },

  walkthroughContentContainer: (backgroundColor) => ({
    backgroundColor,
    flex: 1.0,
    width: '100%',
    overflow: 'hidden',
  }),

  walkthroughContentTitleText: (textColor) => ({
    fontFamily: 'karla-bold',
    fontSize: 21,
    lineHeight: 24,
    color: textColor,
    textAlign: 'center',
  }),

  walkthroughContentText: (textColor) => ({
    color: textColor,
    fontFamily: 'montserrat',
    fontSize: 15,
    lineHeight: 24,
    textAlign: 'center',
  }),

  headerImageContainer: {
    flex: 1,
    position: 'absolute',
    height: '100%',
    width: '100%',
    flexDirection: 'column-reverse',
  },
  headerImage: {
    alignSelf: 'center',
    justifyContent: 'flex-end',
    width: 339,
    height: 198,
    resizeMode: 'contain',
  },
  iconPlusSM: {
    alignSelf: 'center',
    borderRadius: 12.5,
    overflow: 'hidden',
    width: 38,
    height: 38,
    resizeMode: 'contain',
  },
  dummyTabsSeparator: {
    height: 120,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  dummyRiskTabContainer: {
    height: 49,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: -49,
    width: '100%',
  },
  dummyRiskTabContentContainer: (backgroundColor, screenWidth) => ({
    backgroundColor,
    position: 'absolute',
    height: 49,
    left: screenWidth / 2 + 14,
    right: screenWidth / 4 + 14,
    bottom: 0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  }),
  dummyRiskTabContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dummyRiskTabContentImage: { width: 25, height: 25 },
  dummyRiskTabContentText: (textColor) => ({
    color: textColor,
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 2,
  }),
  dummyProfileTabContainer: {
    flex: 1.0,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  dummyProfileTabImage: {
    resizeMode: 'contain',
    width: 30,
    height: 30,
    marginBottom: 2,
  },
  dummyProfileTabText: (textColor) => ({
    color: textColor,
    fontFamily: 'karla-bold',
    fontSize: 10,
    letterSpacing: 0.1,
    textAlign: 'center',
    marginBottom: 2,
  }),
  dummyFamilyTabContainer: {
    height: 49,
    flexDirection: 'row',
    justifyContent: hideRisk ? 'center' : 'flex-end',
    marginTop: -49,
    width: '100%',
  },
  dummyFamilyTabContentContainer: (backgroundColor) => ({
    backgroundColor,
    position: 'absolute',
    height: 49,
    alignSelf: 'center',
    bottom: 0,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  }),
  dummyFamilyTabContent: {
    flex: 1.0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dummyFamilyTabContentImage: { width: 25, height: 25, marginBottom: 2 },
  dummyFamilyTabContentText: (textColor) => ({
    color: textColor,
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 2,
  }),
})

export default styles
