import * as actionTypes from './actionTypes'

const initialState = {
  clinicians: [],
  selectedClinician: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.RESET_CLINICIAN_STORE:
      return {
        clinicians: [],
        selectedClinician: null,
      }

    case actionTypes.SET_CLINICIANS:
      return {
        ...state,
        clinicians: action.data,
      }

    case actionTypes.CLEAR_CLINICIANS:
      return {
        ...state,
        clinicians: null,
      }

    case actionTypes.ADD_CLINICIAN:
      if (state.clinicians?.length !== 0 || state.clinicians === null) {
        return {
          ...state,
          clinicians: [action.data],
        }
      }
      return {
        ...state,
        clinicians: [...state.clinicians, action.data],
      }

    case actionTypes.SET_SELECTED_CLINICIAN:
      return {
        ...state,
        selectedClinician: action.data,
      }

    case actionTypes.CLEAR_SELECTED_CLINICIAN:
      return {
        ...state,
        selectedClinician: null,
      }

    default:
      return state
  }
}
