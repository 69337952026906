import { Text, View } from 'react-native'
import CustomListView from './CustomList.view'

export const CustomListLogic = ({ data, renderItem, colorSettings }) => {
  const RenderItems = () => {
    if (data) {
      const lists = data.map((item) => renderItem(item))
      return lists
    }
    return <View />
  }

  return <CustomListView RenderItems={RenderItems} />
}
