/* Base relationship types */
export const PROBAND = 'proband'

export const PARTNER = 'partner'

export const CHILD = 'child'
export const SON = 'son'
export const DAUGHTER = 'daughter'
export const PREGNANCY = 'pregnancy'

export const SIBLING = 'sibling'
export const BROTHER = 'brother'
export const SISTER = 'sister'

export const NIBLING = 'nibling'
export const NEPHEW = 'nephew'
export const NIECE = 'niece'

export const COUSIN = 'cousin'

export const PIBLING = 'pibling'
export const AUNT = 'aunt'
export const UNCLE = 'uncle'

export const PARENT = 'parent'
export const FATHER = 'father'
export const MOTHER = 'mother'

export const GRANDPARENT = 'grandparent'
export const GRANDFATHER = 'grandfather'
export const GRANDMOTHER = 'grandmother'

/* Relationship groups */
export const YOU_AND_YOUR_CHILDREN = 'you_and_your_children'
export const BROTHERS_AND_SISTERS = 'brothers_and_sisters'
export const AUNTSUNCLES_DADS_SIDE = 'auntsuncles_dads_side'
export const AUNTSUNCLES_MOMS_SIDE = 'auntsuncles_moms_side'
export const GRANDPARENTS_DADS_SIDE = 'grandparents_dads_side'
export const GRANDPARENTS_MOMS_SIDE = 'grandparents_moms_side'
export const OTHER_FAMILY_MEMBERS = 'other_family_members'
export const OTHER_MEMBERS = 'other_members'
export const PARENTS = 'parents'
export const PARTNERS = 'partners'
export const CHILDREN = 'children'
export const SIBLINGS = 'siblings'
export const NIBLINGS = 'niblings'
export const PIBLINGS = 'piblings'
export const COUSINS = 'cousins'
export const PATERNAL_AUNTS_UNCLES = 'paternal aunts/uncles'
export const MATERNAL_AUNTS_UNCLES = 'maternal aunts/uncles'
export const GRANDPARENTS = 'grandparents'
export const PATERNAL_GRANDPARENTS = 'paternal grandparents'
export const MATERNAL_GRANDPARENTS = 'maternal grandparents'

/* Family sides */
export const PATERNAL = 'paternal'
export const MATERNAL = 'maternal'
