import { Platform, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgb(245,245,245)',
    flex: 1.0,
  },
  content: {
    paddingHorizontal: 16,
    flex: 1.0,
    ...Platform.select({
      web: {
        maxWidth: 800,
        minWidth: 280,
        width: '100%',
        alignSelf: 'center',
      },
    }),
  },
  familyInviteTextInputContainer: {
    ...Platform.select({
      web: {
        maxWidth: 500,
        minWidth: 280,
        width: '100%',
        alignSelf: 'center',
      },
    }),
  },

  contentCentered: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1.0,
  },

  headerContainer: {
    height: 60,
    justifyContent: 'center',
  },
  headerTitleText: {
    fontFamily: 'karla-bold',
    fontSize: 14,
    color: 'rgb(65,70,97)',
  },

  listContainer: {
    // shadowOpacity: 0.5,
    // shadowColor: "rgb(187,187,187)",
    // shadowOffset: { width: 0, height: 2 },
    // borderColor: "rgba(200, 200, 200, 0.5)",
    // borderWidth: 0.5,
    flex: 1.0,
  },
  listItemContainer: {
    backgroundColor: 'white',
    borderColor: 'rgb(234,234,234)',
    height: 75,
    justifyContent: 'center',
  },
  listItemContent: {
    padding: 16,
    flexDirection: 'row',
    flex: 1.0,
  },
  listItemTitleContainer: {
    justifyContent: 'center',
    paddingHorizontal: 16,
    flex: 1.0,
  },
  listItemTitleText: {
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    color: 'rgb(74,74,74)',
  },
  listItemSubtitleText: {
    fontFamily: 'montserrat',
    fontSize: 11,
    color: 'rgb(102,102,102)',
  },

  listItemButtonContainer: {
    width: 75,
    height: 30,
    borderRadius: 15,
    borderWidth: 2.0,
    borderColor: 'rgb(219,219,219)',
  },
  listItemButton: {
    flex: 1.0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItemButtonTitle: {
    fontFamily: 'montserrat-medium',
    fontSize: 10,
  },
  scrollViewContentContainer: {
    marginHorizontal: 30,
    marginVertical: 30,
  },
})

export default styles
