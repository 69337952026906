import { Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { styles } from '../styles.main'
import { i18n } from '$localization/config.js'

function ClinicianWelcomeButton({ dimensions, handleStartSurveyClick }) {
  const colorSplashSettings = useSelector((state) => state.store.splashSettings)
  const isDesktopBrowser = dimensions.height > 970

  return (
    <View
      style={[
        styles.loginButtonContainer,
        isDesktopBrowser && {
          flex: 0.1,
        },
      ]}
    >
      {/* <Login button> */}
      <TouchableOpacity
        onPress={handleStartSurveyClick}
        style={[
          styles.loginButton,
          {
            backgroundColor:
              colorSplashSettings?.custom_welcome_buttonColor || 'white',
            borderColor:
              colorSplashSettings?.custom_welcome_buttonBorderColor || 'black',
          },
        ]}
      >
        <Text
          style={[
            styles.loginButtonText,
            {
              color:
                colorSplashSettings?.custom_welcome_buttonTextColor || 'black',
            },
          ]}
        >
          {i18n.t('start_survey').default}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default ClinicianWelcomeButton
