import { useState } from 'react'
import { Platform } from 'react-native'
import InputAgeView from './InputAge.view'
import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'
import { i18n } from '$localization/config.js'
import { isAgeValid } from '$screens/main/family/edit_profile/_utils/Utils.js'

export const InputAgeLogic = ({
  colorSettings,
  headerTitle,
  onChange,
  currentAge,
  memberAge,
  memberAgeOfDeath,
  isDeceased = false,
  mainAgeInput = false,
  onActiveScreen,
  onFocusEvent = () => {},
  ageOfDiagnosisType,
}) => {
  const [age, setAge] = useState(currentAge)
  const [prevAge, setPrevAge] = useState(currentAge)

  const ageInputValidation = () => {
    const incomingAge = memberAge || ''
    const inputAge = `${age} ${ageOfDiagnosisType}` || ''
    const AOD = parseInt(memberAgeOfDeath) || ''
    const comparedAge = isDeceased ? AOD : incomingAge

    // if AOD/age is zero, blank or null validate max age, and validate if input is 0
    if (comparedAge === 0 || !comparedAge) {
      if (!isAgeValid('130', inputAge)) {
        return invalidAgeHandler()
      }
    }

    if (!isAgeValid(`${comparedAge}`, `${inputAge}`)) return invalidAgeAlert()

    onChange(age)
  }

  const onFocus = () => {
    setAge('')
    setPrevAge(age)
    if (Platform.OS === 'android') {
      onFocusEvent()
    }
  }

  const invalidAgeHandler = () => {
    const dateType = {
      years: i18n.t('age_of_diagnosis_must_not_be_greater_than_130').default,
      months: 'Age of diagnosis must not be greater than 1560', // need to be translated
      weeks: 'Age of diagnosis must not be greater than 6778',
      days: 'Age of diagnosis myst not be greater than 47450',
    }

    invalidAgeAlert(
      dateType[ageOfDiagnosisType],
      i18n.t('invalid_age_of_diagnosis').default
    )
    onChange(prevAge)
    setAge(prevAge)
  }

  const invalidAgeAlert = (
    message = i18n.t('the_age_of_diagnosis_cannot_be_greater_than_current_age')
      .default,
    title = i18n.t('invalid_age_of_diagnosis').default
  ) => {
    showDefaultAlert(title, message)
    onChange(prevAge)
    setAge(prevAge)
  }

  const maxLength = {
    years: 3,
    months: 4,
    weeks: 4,
    days: 5,
  }

  return (
    <InputAgeView
      colorSettings={colorSettings}
      headerTitle={headerTitle}
      validationChecks={ageInputValidation}
      currentAge={age}
      onChange={setAge}
      onFocus={onFocus}
      onActiveScreen={onActiveScreen}
      maxLength={maxLength[ageOfDiagnosisType]}
    />
  )
}
