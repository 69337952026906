export const SET_PROBAND = 'SET_PROBAND'
export const CLEAR_PROBAND = 'CLEAR_PROBAND'

export const SET_PROBAND_PROFILE = 'SET_PROBAND_PROFILE'
export const CLEAR_PROBAND_PROFILE = 'CLEAR_PROBAND_PROFILE'

export const SET_PROBAND_ID = 'SET_PROBAND_ID'
export const SET_PROBAND_FAMILY_ID = 'SET_PROBAND_FAMILY_ID'

export const SET_PROBAND_CLINICIAN_CODE = 'SET_PROBAND_CLINICIAN_CODE'
export const SET_PROBAND_BIOLOGICAL_GENDER = 'SET_PROBAND_BIOLOGICAL_GENDER'
export const SET_PROBAND_PRONOUN = 'SET_PROBAND_PRONOUN'
export const SET_PROBAND_GENDER_IDENTITY = 'SET_PROBAND_GENDER_IDENTITY'

export const SET_PROBAND_DISEASE = 'SET_PROBAND_DISEASE'
export const CLEAR_PROBAND_DISEASE = 'CLEAR_PROBAND_DISEASE'

export const SET_PROBAND_SKIP_LOGIC = 'SET_PROBAND_SKIP_LOGIC'
export const CLEAR_PROBAND_SKIP_LOGIC = 'CLEAR_PROBAND_SKIP_LOGIC'

export const SET_PROBAND_ETHNICITY = 'SET_PROBAND_ETHNICITY'
export const CLEAR_PROBAND_ETHNICITY = 'CLEAR_PROBAND_ETHNICITY'

export const SET_PROBAND_ASHKENAZI = 'SET_PROBAND_ASHKENAZI'
export const CLEAR_PROBAND_ASHKENAZI = 'CLEAR_PROBAND_ASHKENAZI'

export const SET_PROBAND_RACE = 'SET_PROBAND_RACE'
export const CLEAR_PROBAND_RACE = 'CLEAR_PROBAND_RACE'

export const SET_PROBAND_GENETIC_TESTING = 'SET_PROBAND_GENETIC_TESTING'
export const CLEAR_MEMBER_GENETIC_TESTING = 'CLEAR_MEMBER_GENETIC_TESTING'

export const SET_BASIC_FAMILY_MEMBERS = 'SET_BASIC_FAMILY_MEMBERS'
export const SET_FAMILY_SUMMARY_DATA = 'SET_FAMILY_SUMMARY_DATA'
export const CLEAR_BASIC_FAMILY_MEMBERS = 'CLEAR_BASIC_FAMILY_MEMBERS'

export const SET_SHOULD_RELOAD_FAMILY_LIST = 'SET_SHOULD_RELOAD_FAMILY_LIST'

export const CLEAR_OTHER_PROBAND_DETAILS = 'CLEAR_OTHER_PROBAND_DETAILS'

export const SET_PROBAND_IMMEDIATE_FAMILY = 'SET_PROBAND_IMMEDIATE_FAMILY'
