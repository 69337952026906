import { Text, View, Image } from 'react-native'

import { useSelector } from 'react-redux'
import { i18n } from '$localization/config.js'

import styles from '../../static/main.styles'
import { hideRisk } from '../../constans/constans'
import ContinueButtonPage2 from './components/ContinueButtonPage2'

function FamilyScreenWalkthroughPage2({
  tutorialWithSurvey,
  extraHeaderImageHeight,
  onFamilyScreenWalkthroughCloseAction,
}) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const heightAdjustment = () => {
    if (!hideRisk || tutorialWithSurvey) {
      return { height: 30 }
    }
    return { height: 2 }
  }

  return (
    <View
      style={styles.walkthroughContentContainer(colorSettings?.splash_bgcolor)}
    >
      <View style={{ height: 200 + extraHeaderImageHeight }}>
        <View
          style={{
            flex: 0.9,
            backgroundColor: colorSettings?.onboarding_bgcolor,
          }}
        />

        <View style={styles.headerImageContainer}>
          <Image
            style={styles.headerImage}
            source={require('$assets/images/new_assets/tutorial-images-set-2/slide-2.png')}
          />
        </View>
      </View>

      <View style={heightAdjustment} />

      {/* <Content Body> */}
      <View style={{ height: '100%' }}>
        <View
          style={{
            paddingVertical: 8,
            paddingHorizontal: 8,
          }}
        >
          <Text
            style={styles.walkthroughContentText(
              colorSettings?.text_input_title_color_1
            )}
          >
            {
              i18n.t(
                'you_can_always_return_to_your_family_to_add_more_information_or_toninvite_family_members_to_fill_in_theirnown_data'
              )?.default
            }
          </Text>
        </View>
        {hideRisk || !tutorialWithSurvey ? (
          <ContinueButtonPage2
            onFamilyScreenWalkthroughCloseAction={
              onFamilyScreenWalkthroughCloseAction
            }
          />
        ) : null}
      </View>
    </View>
  )
}

export default FamilyScreenWalkthroughPage2
