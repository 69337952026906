import { StyleSheet, Platform, Dimensions } from 'react-native'
import { getStatusBarHeight, ifIphoneX } from 'react-native-iphone-x-helper'

const screenWidth = Dimensions.get('window').width
const containerBgColor = 'rgba(155,89,182,1.0)'
const buttonBgColor = 'rgba(132,209,192,1.0)'
const light = '#fff'

export default StyleSheet.create({
  containerWeb: {
    flex: 1.0,
    backgroundColor: containerBgColor,
  },
  container: {
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: screenWidth / 4,
    right: 0,
    ...ifIphoneX(
      {
        height: 100, // For iPhoneX screens
      },
      {
        height: 100, // For regular iPhone screens
      }
    ),
    ...Platform.select({
      ios: {
        top: getStatusBarHeight(true),
      },
      android: {
        height: 95, // For android phone screens
      },
    }),
  },
  exitButton: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
  },
  nextButton: {
    justifyContent: 'center',
    backgroundColor: buttonBgColor,
    height: '100%',
    width: '100%',
  },
  nextButtonText: {
    color: light,
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  nextButtonContainer: {
    height: 60,
  },
  backButton: {
    position: 'absolute',
    top: 45,
    left: 50,
  },
  webViewMainContainer: {
    backgroundColor: light,
  },
  webViewInnerContainer: {
    backgroundColor: light,
  },
  webViewContent: {
    flex: 1.0,
    height: '100%',
    width: '100%',
  },
})
