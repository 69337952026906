import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  flexContainer: {
    paddingBottom: 30,
    paddingHorizontal: 27,
  },
  fieldContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 30,
  },
  toggleField: {
    flex: null,
  },
})
