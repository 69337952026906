export const SET_IS_FIRST_LOGIN = 'SET_IS_FIRST_LOGIN'
export const TURN_ON_IS_FIRST_LOGIN = 'TURN_ON_IS_FIRST_LOGIN'
export const TURN_OFF_IS_FIRST_LOGIN = 'TURN_OFF_IS_FIRST_LOGIN'
export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER'
export const HIDE_GLOBAL_LOADER = 'HIDE_GLOBAL_LOADER'
export const SET_BOTTOM_TAB_BAR_TITLES = 'SET_BOTTOM_TAB_BAR_TITLES'
export const SET_SHOW_LOADING_VIEW = 'SET_SHOW_LOADING_VIEW'
export const SET_CUSTOM_WEB_VIEW_URI = 'SET_CUSTOM_WEB_VIEW_URI'
export const SET_SHOULD_PROCEED_TO_MAIN = 'SET_SHOULD_PROCEED_TO_MAIN'

export const SET_NAVIGATION_STATE = 'SET_NAVIGATION_STATE'
export const SET_SHOULD_SHOW_OTHER_FAMILY_MEMBERS_TUTORIAL =
  'SET_SHOULD_SHOW_OTHER_FAMILY_MEMBERS_TUTORIAL'
export const SET_DELAY_OTHER_FAMILY_MEMBERS_TUTORIAL =
  'SET_DELAY_OTHER_FAMILY_MEMBERS_TUTORIAL'

export const STORE_ACCOUNT = 'STORE_ACCOUNT' // To be deprecated
export const STORE_PROBAND = 'STORE_PROBAND' // To be deprecated

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const SET_APP_SETTINGS = 'SET_APP_SETTINGS'
export const SET_SPLASH_SETTINGS = 'SET_SPLASH_SETTINGS'
export const SET_COLOR_SETTINGS = 'SET_COLOR_SETTINGS'

export const SET_SURVEY_STATUS = 'SET_SURVEY_STATUS'
export const STORE_SURVEYCOUNT = 'STORE_SURVEYCOUNT'

export const SET_DISEASE_CATEGORIES = 'SET_DISEASE_CATEGORIES'
export const SET_SUB_CATEGORIES = 'SET_SUB_CATEGORIES'

export const SHOULD_ADD_PHONE_NUMBER = 'SHOULD_ADD_PHONE_NUMBER'

export const SET_BG_REFRESH_ALL = 'SET_BG_REFRESH_ALL'
export const SET_BG_REFRESH_INTERVAL_ALL = 'SET_BG_REFRESH_INTERVAL_ALL'

export const SET_LAST_LOGIN_SCREEN = 'SET_LAST_LOGIN_SCREEN'
export const SET_INVITE_ID = 'SET_INVITE_ID'
export const SET_USER_HAS_PASSWORD = 'SET_USER_HAS_PASSWORD'
export const SET_NOTIFICATION_ERROR = 'SET_NOTIFICATION_ERROR'
export const SET_IS_ONBOARDING = 'SET_IS_ONBOARDING'
export const SET_CURRENT_SCREEN_STACK = 'SET_CURRENT_SCREEN_STACK'
