import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ButtonWithListView from './ButtonWithList.view'
// utils
import { translationConverter } from '../../utils/_utils'

const ButtonWithListLogic = ({
  colorSettings,
  toggled = false,
  onPress,
  title,
  data,
  onEdit,
  fromUmls,

  diseaseFilter,
}) => {
  const [isToggled, setIsToggled] = useState(toggled)
  const [preventActionEffect, setPreventActionEffect] = useState(false)

  const { probandDiseases } = useSelector((state) => state.diseaseStore)

  useEffect(() => {
    if (!isToggled) {
      return setPreventActionEffect(true)
    }

    setPreventActionEffect(false)
  }, [isToggled])

  useEffect(() => {
    if (diseaseFilter) {
      setPreventActionEffect(false)
      setIsToggled(true)
    }
  }, [probandDiseases])

  const updateState = () => {
    onPress(!isToggled)
    if (!preventActionEffect) setIsToggled((currentState) => !currentState)
  }

  return (
    <ButtonWithListView
      colorSettings={colorSettings}
      updateState={updateState}
      isToggled={isToggled}
      buttonTitle={translationConverter(title, 'default', fromUmls) ?? title}
      preventToggleEffect={preventActionEffect}
      data={data}
      onEdit={onEdit}
    />
  )
}

export default ButtonWithListLogic
