import { View, Text, TouchableOpacity, StyleSheet, Image } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import MaterialIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import translateDisease from '$screens/personal_details/relative_gene_test/_utils/TextTranslation.js'

const deleteImage = require('$assets/images/icon/trash/white/trash.png')
const editImage = require('$assets/images/icon/edit/white.png')

const textColor = '#fff'
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    flexDirection: 'row',
    marginBottom: 16,
  },
  title: {
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    color: textColor,
    flex: 0.7,
  },
  image: { alignSelf: 'center', height: 24, width: 24 },
  editButton: {
    flex: 0.2,
    marginLeft: 30,
  },
  deleteButton: {
    flex: 0.1,
  },
})

const MainListView = ({ colorSettings, data, toggleEdit, toggleRemove }) => {
  const RenderItems = ({ item }) => {
    const renderItems = (
      <View style={styles.container}>
        <Text
          style={[
            styles.title,
            {
              color: colorSettings?.text_input_title_color_2 || 'black',
            },
          ]}
        >
          {`${translateDisease(item.disease_name)}, ${item.age_diagnosed}`}
        </Text>
        <TouchableOpacity
          style={styles.editButton}
          onPress={() => toggleEdit(item.disease_id)}
        >
          <MaterialIcons
            name="pencil-outline"
            size={20}
            color={colorSettings?.text_input_label_color_2 || 'black'}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.deleteButton}
          onPress={() => toggleRemove(item.disease_id)}
        >
          <Ionicons
            name="trash"
            size={20}
            color={colorSettings?.text_input_label_color_2 || 'black'}
          />
        </TouchableOpacity>
      </View>
    )
    return renderItems
  }

  const RenderList = ({ currentData }) => {
    let listItems = <View />
    if (currentData) {
      listItems = currentData.map((item) => (
        <RenderItems key={item.id} item={item} />
      ))
    }
    return listItems
  }

  return (
    <View>
      <RenderList currentData={data} />
    </View>
  )
}

// MainListView.propTypes = {
//   colorSettings: PropTypes.shape({
//     text_input_title_color_2: PropTypes.string,
//   }),
//   data: PropTypes.shape([]),
//   toggleEdit: PropTypes.func,
//   toggleRemove: PropTypes.func,
// }

MainListView.defaultProps = {
  colorSettings: {
    text_input_title_color_2: 'black',
  },
  data: [],
  toggleEdit: () => {},
  toggleRemove: () => {},
}

export default MainListView
