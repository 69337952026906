import * as actionTypes from './actionTypes'

const initialState = {
  probandDiseases: [],
  individualDisease: null,
  skipLogic: [],
  selectedDisease: {
    diseasesPerType: null,
    diseaseType: null,
  },
  fromMainDisease: null,
  convertedMemberDisease: null,
  convertedMemberSkipLogic: null,
  fromOnboarding: true,
  diseaseTypeByOrg: null,
  isSingleCategoryDisease: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.RESET_DISEASES_STORE:
      /* Update this for every additional data in store */
      return {
        probandDiseases: [],
        individualDisease: null,
        skipLogic: [],
        selectedDisease: {
          diseasesPerType: null,
          diseaseType: null,
        },
        fromMainDisease: null,
        convertedMemberDisease: null,
        convertedMemberSkipLogic: null,
        fromOnboarding: true,
        diseaseTypeByOrg: null,
        isSingleCategoryDisease: false,
      }

    case actionTypes.SET_SELECTED_DISEASE_DETAILS:
      return {
        ...state,
        selectedDisease: action.data,
      }

    case actionTypes.SET_DISEASE_PROBAND:
      return {
        ...state,
        probandDiseases: action.data,
      }

    case actionTypes.SET_INDIVIDUAL_DISEASE:
      return {
        ...state,
        individualDisease: action.data,
      }

    case actionTypes.SET_SCREEN_WHERE_TO_NAVIGATE:
      return {
        ...state,
        fromMainDisease: action.data,
      }

    case actionTypes.SET_SKIP_LOGIC:
      return {
        ...state,
        skipLogic: action.data,
      }

    case actionTypes.SET_CONVERTED_MEMBER_DISEASE:
      return {
        ...state,
        convertedMemberDisease: action.data,
      }

    case actionTypes.SET_CONVERTED_MEMBER_SKIP_LOGIC:
      return {
        ...state,
        convertedMemberSkipLogic: action.data,
      }

    case actionTypes.SET_FROM_ONBOARDING_DISEASES:
      return {
        ...state,
        fromOnboarding: action.data,
      }

    case actionTypes.SET_TYPE_BY_ORG:
      return {
        ...state,
        diseaseTypeByOrg: action.data,
      }

    case actionTypes.SET_IS_SINGLE_CATEGORY_DISEASE:
      return {
        ...state,
        isSingleCategoryDisease: action.data,
      }

    default: {
      return state
    }
  }
}
