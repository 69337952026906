import { Text, View, Image } from 'react-native'

import { i18n } from '$localization/config.js'

import styles from '../../static/main.styles'

function FamilyScreenWalkthroughPage1({
  colorSettings,
  extraHeaderImageHeight,
}) {
  const titleText = i18n.t(
    'congratulationsnyouve_completed_yournfamily_history'
  )?.default
  const messageText = i18n.t(
    'if_you_need_to_add_anyone_elsenjust_use_the_'
  )?.default

  return (
    <View
      style={styles.walkthroughContentContainer(colorSettings?.splash_bgcolor)}
    >
      <View style={{ height: 200 + extraHeaderImageHeight }}>
        <View
          style={{
            flex: 0.9,
            backgroundColor: colorSettings?.onboarding_bgcolor,
          }}
        />

        <View style={styles.headerImageContainer}>
          <Image
            style={styles.headerImage}
            source={require('$assets/images/new_assets/tutorial-images-set-2/slide-1.png')}
          />
        </View>
      </View>

      <View style={{ height: 20 }} />

      <View style={{ height: '100%' }}>
        <View>
          <Text
            style={styles.walkthroughContentTitleText(
              colorSettings?.text_input_title_color_1
            )}
          >
            {titleText}
          </Text>
        </View>

        <View style={{ paddingVertical: 8 }}>
          <Text
            style={styles.walkthroughContentText(
              colorSettings?.text_input_title_color_1
            )}
          >
            {messageText}
          </Text>

          <Image
            style={styles.iconPlusSM}
            source={require('$assets/images/new_assets/add-circle.png')}
          />
        </View>
      </View>
    </View>
  )
}

export default FamilyScreenWalkthroughPage1
