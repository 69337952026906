import * as actionTypes from './actionTypes'

const setProbandAction = (data) => ({
  type: actionTypes.SET_PROBAND,
  data,
})

const clearProbandAction = () => ({
  type: actionTypes.CLEAR_PROBAND,
})

const setProbandProfileAction = (data) => ({
  type: actionTypes.SET_PROBAND_PROFILE,
  data,
})

const clearProbandProfileAction = () => ({
  type: actionTypes.CLEAR_PROBAND_PROFILE,
})

const setProbandID = (id) => ({
  type: actionTypes.SET_PROBAND_ID,
  data: id,
})

const setProbandFamilyId = (id) => ({
  type: actionTypes.SET_PROBAND_FAMILY_ID,
  data: id,
})

const setProbandClinicianCode = (code) => ({
  type: actionTypes.SET_PROBAND_CLINICIAN_CODE,
  data: code,
})

const setProbandBiologicalGender = (gender) => ({
  type: actionTypes.SET_PROBAND_BIOLOGICAL_GENDER,
  data: gender,
})

const setProbandPronoun = (data) => ({
  type: actionTypes.SET_PROBAND_PRONOUN,
  data,
})

const setProbandGenderIdentity = (gender) => ({
  type: actionTypes.SET_PROBAND_GENDER_IDENTITY,
  data: gender,
})

const setBasicFamilyMembers = (data) => ({
  type: actionTypes.SET_BASIC_FAMILY_MEMBERS,
  data,
})

const setFamilySummaryData = (data) => ({
  type: actionTypes.SET_FAMILY_SUMMARY_DATA,
  data,
})

const clearBasicFamilyMembers = () => ({
  type: actionTypes.CLEAR_BASIC_FAMILY_MEMBERS,
})

const setShouldReloadFamilyList = (data) => ({
  type: actionTypes.SET_SHOULD_RELOAD_FAMILY_LIST,
  data,
})

const setProbandGeneticTesting = (data) => ({
  type: actionTypes.SET_PROBAND_GENETIC_TESTING,
  data,
})

const clearProbandGeneticTesting = () => ({
  type: actionTypes.CLEAR_MEMBER_GENETIC_TESTING,
})

const setProbandEthnicity = (ethnicity) => ({
  type: actionTypes.SET_PROBAND_ETHNICITY,
  data: ethnicity,
})

const clearProbandEthnicity = () => ({
  type: actionTypes.CLEAR_PROBAND_ETHNICITY,
})

const setProbandAshkenazi = (ashkenazi) => ({
  type: actionTypes.SET_PROBAND_ASHKENAZI,
  data: ashkenazi,
})

const clearProbandAshkenazi = () => ({
  type: actionTypes.CLEAR_PROBAND_ASHKENAZI,
})

const setProbandRace = (race) => ({
  type: actionTypes.SET_PROBAND_RACE,
  data: race,
})

const clearProbandRace = () => ({
  type: actionTypes.CLEAR_PROBAND_RACE,
})

const setProbandDisease = (disease) => ({
  type: actionTypes.SET_PROBAND_DISEASE,
  data: disease,
})

const clearProbandDisease = () => ({
  type: actionTypes.CLEAR_PROBAND_DISEASE,
})

const setProbandSkipLogic = (disease) => ({
  type: actionTypes.SET_PROBAND_SKIP_LOGIC,
  data: disease,
})

const clearProbandSkipLogic = () => ({
  type: actionTypes.CLEAR_PROBAND_SKIP_LOGIC,
})

const clearOtherProbandDetails = () => ({
  type: actionTypes.CLEAR_OTHER_PROBAND_DETAILS,
})

const setProbandImmediateFamily = (data) => ({
  type: actionTypes.SET_PROBAND_IMMEDIATE_FAMILY,
  data,
})

export {
  setProbandAction,
  clearProbandAction,
  setProbandProfileAction,
  clearProbandProfileAction,
  setProbandID,
  setProbandFamilyId,
  setProbandClinicianCode,
  setProbandBiologicalGender,
  setProbandPronoun,
  setProbandGenderIdentity,
  setBasicFamilyMembers,
  clearBasicFamilyMembers,
  setShouldReloadFamilyList,
  setProbandGeneticTesting,
  clearProbandGeneticTesting,
  setProbandEthnicity,
  clearProbandEthnicity,
  setProbandAshkenazi,
  clearProbandAshkenazi,
  setProbandDisease,
  clearProbandDisease,
  setProbandSkipLogic,
  clearProbandSkipLogic,
  clearOtherProbandDetails,
  setProbandImmediateFamily,
  setProbandRace,
  clearProbandRace,
  setFamilySummaryData,
}
