import { TouchableOpacity, Text, View, StyleSheet } from 'react-native'
import styles from './Styles'
import { i18n } from '$localization/config.js'

const SeeMoreButton = ({ colorSettings, onPress }) => (
  <View style={styles.mainContainer}>
    <TouchableOpacity style={styles.button} onPress={onPress}>
      <Text
        style={[
          styles.title,
          {
            color: colorSettings?.text_input_label_color_2 || 'black',
          },
        ]}
      >
        {i18n.t('see_more').default}...
      </Text>
    </TouchableOpacity>
  </View>
)

// SeeMoreButton.propTypes = {
//   colorSettings: PropTypes.shape({
//     text_input_label_color_2: PropTypes.string,
//   }),
//   onPress: PropTypes.func,
// }

SeeMoreButton.defaultProps = {
  colorSettings: {},
  onPress: () => {},
}

export default SeeMoreButton
