import { useSelector } from 'react-redux'
import { TouchableOpacity, View } from 'react-native'
import { forwardRef } from 'react'
import {
  carouselData,
  dotsDistance,
  paginationDotStyle,
  tappableDots,
} from '../constans/constans'

const WalkthroughDots = forwardRef(({ activeIndex }, ref) => {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return carouselData.map((screen, index) => (
    <TouchableOpacity
      style={[paginationDotStyle, { marginHorizontal: dotsDistance }]}
      key={index}
      onPress={() => {
        if (!tappableDots) return
        ref.current?.stopAutoplay()
        ref.current?._snapToItem(ref.current?._getPositionIndex(index))
      }}
    >
      <View
        style={[
          paginationDotStyle,
          {
            backgroundColor:
              activeIndex === index
                ? colorSettings?.pagination_dot_active
                : colorSettings?.pagination_dot_inactive,
          },
        ]}
      />
    </TouchableOpacity>
  ))
})

export default WalkthroughDots
