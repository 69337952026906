import { Text } from 'react-native'
import styles from '../styles'

function DiseaseListItem({ item }) {
  const diseaseNameWithAgeString = `${item.disease_short_name}, ${
    item.age_diagnosed_string ?? '?'
  }`

  return (
    <Text style={styles.sectionListSummaryDetailsText}>
      {diseaseNameWithAgeString}
    </Text>
  )
}

export default DiseaseListItem
