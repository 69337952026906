import { Component } from 'react'
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  Platform,
} from 'react-native'

import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'
import DashboardBadge from '../components/dashboard_badge_component/DashboardBadge.layout.js'

import styles from '$screens/main/dashboard/_styles/main.styles.js'

class DashboardNav extends Component {
  proband = this.props.proband

  isSmallScreen = this.props.isSmallScreen

  isWebBrowser = this.props.isWebBrowser

  buttonName = this.props.buttonName

  icon = this.props.icon

  showBadge = this.props.showBadge

  navigateTo = this.props.navigateTo ?? function () {}

  hideSeparator = this.props.hideSeparator ?? false

  setTab = this.props.setTab

  activeTabIDTest = this.props.globalState

  renderBadge = (showBadge = false) => {
    const { navigation } = this.props
    if (!showBadge) return null
    return (
      <DashboardBadge
        proband={this.proband}
        buttonName={this.buttonName}
        navigation={navigation}
      />
    )
  }

  render() {
    const { colorSettings, isSmallScreen, isWebBrowser } = this.props

    return (
      <TouchableOpacity
        style={[
          styles.buttonDetails,
          {
            backgroundColor: colorSettings?.dashboard_section_btn_bg,
            borderBottomWidth: !this.hideSeparator ? 1.0 : 0,
            borderColor: colorSettings?.dashboard_section_btn_bg,
          },
        ]}
        onPress={() => this.navigateTo()}
      >
        <View
          style={[
            styles.profImageContainer,
            isSmallScreen && isWebBrowser && { flex: 1.5 },
          ]}
        >
          <Image
            style={{
              alignSelf: 'center',
              width: 40,
              height: 40,
              resizeMode: 'contain',
            }}
            source={this.icon}
            resizeMode="contain"
          />
        </View>

        <View style={styles.buttonNameContainer}>
          <Text
            style={[
              styles.buttonName,
              {
                color: colorSettings?.dashboard_section_btn_text,
              },
            ]}
          >
            {i18n.t(this.buttonName)?.default ?? this.buttonName}
          </Text>
          {this.renderBadge(this.showBadge)}
        </View>

        <View style={styles.buttonNext}>
          <Image
            source={require('$assets/images/new_assets/icon-dash-arrow.png')}
            style={{ width: 10, height: 16, resizeMode: 'contain' }}
          />
        </View>
      </TouchableOpacity>
    )
  }
}

function mapStateToProps(state) {
  const { store } = state
  return {
    colorSettings: store.colorSettings,
  }
}

export default connect(mapStateToProps, null)(DashboardNav)
