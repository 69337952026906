import * as actionTypes from './actionTypes'

const setRiskFactorsAction = (data) => ({
  type: actionTypes.SET_RISK_FACTORS,
  data,
})

const clearRiskFactorsAction = () => ({
  type: actionTypes.CLEAR_RISK_FACTORS,
})

export { setRiskFactorsAction, clearRiskFactorsAction }
