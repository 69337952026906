import { Component } from 'react'
import { View, Text, Image, TouchableOpacity } from 'react-native'

import Alert from '$components/Alert'

import { i18n } from '$localization/config.js'

import section_styles from '$screens/main/family/edit_profile/_styles/section.styles.js'
import styles from './_styles/section.styles'

const POSITIVE_GENETIC_TESTING_RESULT_TYPE_1 = 'p'
const POSITIVE_GENETIC_TESTING_RESULT_TYPE_2 = 'lp'
const NEGATIVE_GENETIC_TESTING_RESULT_TYPE_1 = 'n'
const NEGATIVE_GENETIC_TESTING_RESULT_TYPE_2 = 'ln'

class MemberGeneticTestingsSection extends Component {
  colorSettings = this.props.colorSettings

  parentController = this.props.parentController

  healthHistoryData = this.props.healthHistoryData

  geneTestsData = this.props.geneTestsData

  state = {
    healthHistoryData: this.healthHistoryData,
    geneTestsData: this.geneTestsData,
    memberGeneticTestings: this.geneTestsData?.testings ?? [],
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { healthHistoryData } = newProps
    const { geneTestsData } = newProps

    return {
      ...prevState,
      healthHistoryData,
      geneTestsData,
      memberGeneticTestings: geneTestsData?.testings ?? [],
    }
  }

  /* UPDATED */
  getPositiveGeneticTestings = () => {
    const { memberGeneticTestings } = this.state

    const positiveGeneticTestings = []

    memberGeneticTestings.forEach((e) => {
      const resultType = `${e?.result}`.toLowerCase()

      if (
        resultType === POSITIVE_GENETIC_TESTING_RESULT_TYPE_1 ||
        resultType === POSITIVE_GENETIC_TESTING_RESULT_TYPE_2
      )
        positiveGeneticTestings.push(e)
    })

    return positiveGeneticTestings
  }

  /* UPDATED */
  getNegativeGeneticTestings = () => {
    const { memberGeneticTestings } = this.state

    const negativeGeneticTestings = []

    memberGeneticTestings.forEach((e) => {
      const resultType = `${e?.result}`.toLowerCase()

      if (
        resultType === NEGATIVE_GENETIC_TESTING_RESULT_TYPE_1 ||
        resultType === NEGATIVE_GENETIC_TESTING_RESULT_TYPE_2
      )
        negativeGeneticTestings.push(e)
    })

    return negativeGeneticTestings
  }

  /* UPDATED */
  categorizeGeneticTestings = () => {
    const positiveGeneticTestings = this.getPositiveGeneticTestings()
    const negativeGeneticTestings = this.getNegativeGeneticTestings()

    return { positiveGeneticTestings, negativeGeneticTestings }
  }

  navigateToGeneticTestings = () => {
    this.parentController?.navigateToGeneticTestings()
  }

  /* UPDATED */
  navigateToPositiveTestingsOnly = () => {
    this.parentController?.navigateToPositiveTestingsOnly()
  }

  /* UPDATED */
  navigateToNegativeTestingsOnly = () => {
    this.parentController?.navigateToNegativeTestingsOnly()
  }

  /* UPDATED */
  onPressDeleteGeneticTestingItem = (itemID) => {
    const confirmDelete = () =>
      this.parentController?.deleteGeneticTestingItem(itemID)

    Alert.alert(
      i18n.t('confirm_remove')?.default,
      i18n.t('are_you_sure_you_want_to_remove_this_genetic_test')?.default,
      [
        {
          text: i18n.t('yes')?.default,
          style: 'default',
          onPress: () => confirmDelete(),
        },
        { text: i18n.t('no')?.default, style: 'cancel' },
      ]
    )
  }

  render() {
    const { geneTestsData } = this.state
    const isGeneTested = geneTestsData?.geneTested
    const { positiveGeneticTestings, negativeGeneticTestings } =
      this.categorizeGeneticTestings()

    return (
      <View>
        <View style={section_styles.contentItem}>
          <View
            style={[
              section_styles.contentItemLabelTextContainer,
              { flex: 0.7 },
            ]}
          >
            <Text style={section_styles.contentItemLabelText}>
              {i18n.t('has_this_person_ever_had_genetic_testing')?.default}
            </Text>
          </View>

          <View
            style={[
              section_styles.contentItemTextInputContainer,
              { flex: 0.3 },
            ]}
          >
            <TouchableOpacity
              style={styles.healthHistoryAddButton}
              onPress={this.navigateToPositiveTestingsOnly}
            >
              <Text
                style={[
                  styles.healthHistoryAddButtonText,
                  {
                    color: this.colorSettings?.linked_text,
                  },
                ]}
              >
                {i18n.t('add')?.default}
              </Text>
            </TouchableOpacity>
          </View>
        </View>

        {isGeneTested && (
          <PositiveTestingsListContainer
            geneticTestingList={positiveGeneticTestings}
            onPressEdit={() => this.navigateToPositiveTestingsOnly()}
            onPressDeleteItem={(itemID) =>
              this.onPressDeleteGeneticTestingItem(itemID)
            }
          />
        )}

        {isGeneTested && (
          <NegativeTestingsListContainer
            geneticTestingList={negativeGeneticTestings}
            onPressEdit={() => this.navigateToNegativeTestingsOnly()}
            onPressDeleteItem={(itemID) =>
              this.onPressDeleteGeneticTestingItem(itemID)
            }
          />
        )}
      </View>
    )
  }
}

function PositiveTestingsListContainer({
  geneticTestingList = [],
  onPressDeleteItem = function () {},
}) {
  if (geneticTestingList.length === 0) return null
  return (
    <View style={{ marginHorizontal: 16 }}>
      <View style={section_styles.contentItemListContainer}>
        <View style={section_styles.contentItemListTitleContainer}>
          <Text style={[section_styles.contentItemListTitle, { flex: 0.8 }]}>
            {i18n.t('positive_results')?.default}
          </Text>
        </View>

        <View style={section_styles.itemListRowContainer}>
          <GeneticTestingItemContainer
            geneticTestingList={geneticTestingList}
            onPressDeleteItem={onPressDeleteItem}
          />
        </View>
      </View>
    </View>
  )
}

function NegativeTestingsListContainer({
  geneticTestingList = [],
  onPressDeleteItem = function () {},
}) {
  if (geneticTestingList.length === 0) return null
  return (
    <View style={{ marginHorizontal: 16 }}>
      <View style={section_styles.contentItemListContainer}>
        <View style={section_styles.contentItemListTitleContainer}>
          <Text style={[section_styles.contentItemListTitle, { flex: 0.8 }]}>
            {i18n.t('negative_results')?.default}
          </Text>
        </View>

        <View style={section_styles.itemListRowContainer}>
          <GeneticTestingItemContainer
            geneticTestingList={geneticTestingList}
            onPressDeleteItem={onPressDeleteItem}
          />
        </View>
      </View>
    </View>
  )
}

const GeneticTestingItemContainer = ({
  geneticTestingList = [],
  onPressDeleteItem = function () {},
}) => {
  const layout = geneticTestingList.map((element) => {
    const geneTitleName = `${element?.gene}`.toLowerCase()
    const name = i18n.t(geneTitleName)?.title ?? element?.gene

    return (
      <GeneticTestingItem
        key={`GeneticTestingItem-${element?.id}`}
        id={element?.id}
        name={name}
        onPressDelete={onPressDeleteItem}
      />
    )
  })

  return layout
}

function GeneticTestingItem({ key, id, name, onPressDelete = function () {} }) {
  return (
    <View key={key} style={section_styles.itemListRow}>
      <View style={section_styles.itemListRowLabelContainer}>
        <Text style={section_styles.itemListRowLabelText}>
          {i18n.t(name)?.default ?? name}
        </Text>
      </View>

      <View style={section_styles.itemListRowAccessoryContainer}>
        <TouchableOpacity
          onPress={() => onPressDelete(id)}
          style={section_styles.itemListRowAccessory}
        >
          <Image
            source={require('$assets/images/new_assets/icon-trash.png')}
            style={{ width: 14, height: 16, resizeMode: 'contain' }}
          />
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default MemberGeneticTestingsSection
