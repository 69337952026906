import { Component } from 'react'

import Proband from '$data_models/Proband.js'
import MemberProfile from '$data_models/MemberProfile.js'

const RELATIONSHIP_DATA = 'relationshipData'
const PARENT_SPECIFIC = 'parentSpecific'
const CHILD_SPECIFIC = 'childSpecific'
const IS_ADOPTED = 'isAdopted'
const IS_ADOPTED_OUT = 'isAdoptedOut'
const IS_CHILD_OF_RELATIVE = 'isChildOfRelative'
const BLOOD_RELATED_PARENTS = 'bloodRelatedParents'

class RelationshipInfoSectionController extends Component {
  colorSettings = this.props.colorSettings

  parentController = this.props.parentController

  proband = this.props.proband ?? new Proband()

  memberProfile = this.props.memberProfile ?? new MemberProfile()

  currentMember = this.props.currentMember ?? null

  state = {
    currentMember: this.currentMember,
    memberProfile: this.memberProfile,
    relationshipData: this.memberProfile?.relationshipData,
    contentIsShown: false,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { memberProfile } = newProps
    const { currentMember } = newProps
    const relationshipData = memberProfile?.relationshipData

    return {
      ...prevState,
      memberProfile,
      currentMember,
      relationshipData,
    }
  }

  /* UPDATED */
  _updateState_ = (key, val) => {
    const { memberProfile } = this.state
    const relationshipData = memberProfile?.relationshipData
    relationshipData[key] = val

    /* Update parent's state */
    this.parentController?.updateState(RELATIONSHIP_DATA, relationshipData)
  }

  /* UPDATED */
  updateParentSpecificData = (key, val) => {
    const { memberProfile } = this.state
    const relationshipData = memberProfile?.relationshipData

    const _parentSpecific_ = relationshipData[PARENT_SPECIFIC]
    _parentSpecific_[key] = val

    this._updateState_(PARENT_SPECIFIC, _parentSpecific_)
  }

  /* UPDATED */
  toggleBloodRelatedParents = (val) => {
    this.updateParentSpecificData(BLOOD_RELATED_PARENTS, val)
  }

  /* UPDATED */
  removeParentsBloodRelation = () => {
    this.parentController?.removeParentsBloodRelation()
  }

  /* UPDATED */
  toggleIsAdopted = (val) => {
    this._updateState_(IS_ADOPTED, val)

    /* Additional data to change. */
    this.toggleIsAdoptedOut(false)
    this.toggleIsChildOfRelative(false)
  }

  /* UPDATED */
  updateChildSpecificData = (key, val) => {
    const { memberProfile } = this.state
    const relationshipData = memberProfile?.relationshipData

    const _childSpecific_ = relationshipData[CHILD_SPECIFIC]
    _childSpecific_[key] = val

    this._updateState_(CHILD_SPECIFIC, _childSpecific_)
  }

  /* UPDATED */
  toggleIsAdoptedOut = (val) => {
    this.updateChildSpecificData(IS_ADOPTED_OUT, val)

    /* Additional data to change. */
    this.toggleIsChildOfRelative(false)
  }

  /* UPDATED */
  toggleIsChildOfRelative = (val) => {
    this.updateChildSpecificData(IS_CHILD_OF_RELATIVE, val)
  }

  /* UPDATED */
  toggleIsTwin = (val) => {
    const IS_TWIN = 'isTwin'
    this._updateState_(IS_TWIN, val)
  }

  /* UPDATED */
  navigateToAddTwin = () => {
    this.parentController?.navigateToAddTwin()
  }

  /* UPDATED */
  removeTwinGroup = (twinGroupID = null, memberID) => {
    this.parentController?.removeTwinGroup(twinGroupID, memberID)
  }

  /* UPDATED */
  navigateToSelectParent = (currentParentData, partnerDataSource) => {
    this.parentController?.navigateToSelectParent(
      currentParentData,
      partnerDataSource
    )
  }

  /* UPDATED */
  navigateToSelectParentsBloodRelation = () => {
    this.parentController?.navigateToSelectParentsBloodRelation()
  }

  showTooltip = (title = '', message = '') => {
    this.parentController?.showTooltip(title, message)
  }

  scrollDownBy = (y = 0) => {
    this.parentController?.scrollDownBy(y)
  }

  showContentHandler = () => {
    this.setState((prevState) => ({
      contentIsShown: !prevState.contentIsShown,
    }))
  }
}

export default RelationshipInfoSectionController
