import { View, Text } from 'react-native'

import styles from './LabelField.style'

export default function LabelField({ title, colorSettings }) {
  return (
    <Text
      style={[
        styles.titleText,
        {
          color: colorSettings?.text_input_title_color_2 || 'black',
        },
      ]}
    >
      {title}
    </Text>
  )
}
