import { Component } from 'react'
import { Text, TouchableOpacity, StyleSheet } from 'react-native'

import { i18n } from '$localization/config.js'

const styles = StyleSheet.create({
  buttonSelect: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    borderWidth: 2.0,
    height: 60,
    width: '100%',
    marginBottom: 14,
  },
  selectButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
})

export default class RelativeGeneTestButton extends Component {
  colorSettings = this.props.colorSettings

  parent = this.props.parent

  genes = this.props.genesAPI

  state = {
    selectOption: false,
  }

  componentDidMount() {
    if (this.genes.result == 'p') {
      this.setState({ selectOption: true })
    }
  }

  onSelect = () => {
    this.setState(
      (prevState) => ({
        selectOption: !prevState.selectOption,
      }),
      this.onToggle(this.state.selectOption)
    )
  }

  onToggle = (option) => {
    if (!option) {
      this.parent.addToTempList(this.genes)
    } else {
      this.parent.removeToTempList(this.genes)
    }
  }

  toggleButtonColor = () => {
    const style = {
      backgroundColor: this.colorSettings?.selectable_btn_inactive_1,
      borderColor: this.colorSettings?.selectable_btn_active_1,
    }

    if (this.state.selectOption) {
      style.backgroundColor =
        this.colorSettings?.selectable_btn_active_1 || 'rgba(0,0,0,0.2)'
      style.borderColor = this.colorSettings?.selectable_btn_inactive_1
    }

    return style
  }

  render() {
    const _geneObj_ = this.genes
    let geneName

    if (_geneObj_) {
      switch (`${_geneObj_?.gene}`.toLowerCase()) {
        case 'unsure':
        case 'other':
          // Translation for 'unsure' & 'other'
          const T_geneObj = `${_geneObj_?.gene}`.toLowerCase()
          geneName = i18n.t(T_geneObj).title
          break
        default:
          geneName = _geneObj_?.gene
          break
      }
    }

    return (
      <TouchableOpacity
        style={[styles.buttonSelect, this.toggleButtonColor()]}
        onPress={this.onSelect}
        activeOpacity={1.0}
      >
        <Text
          style={[
            styles.selectButtonText,
            {
              color: this.colorSettings?.selectable_btn_text_active_1,
            },
          ]}
        >
          {geneName}
        </Text>
      </TouchableOpacity>
    )
  }
}
