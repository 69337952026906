import SkipLogicList from './skipLogicList'

export default class DiseaseList {
  constructor(val) {
    this.list = val
    this.diseases = []
    this.skipList = []
  }

  insertionSort(arr) {
    for (let i = 1; i < arr.length; i++) {
      const holder = arr[i]
      const currentVal = arr[i].disease_id
      for (var j = i - 1; j >= 0 && arr[j].disease_id > currentVal; j--) {
        arr[j + 1] = arr[j]
      }
      arr[j + 1] = holder
    }
    return arr
  }

  getIndex(id) {
    const { list } = this
    for (let i = 0; i < list.length; i++) {
      if (list[i].disease_id === id) {
        return i
      }
    }
    return null
  }

  addDisease(disease) {
    const { list } = this
    const index = this.getIndex(disease.disease_id)

    if (list.length === 0) {
      list.push(disease)
      this.insertionSort(list)
      return list
    }

    if (index !== null) {
      list[index] = disease
    } else {
      list.push(disease)
      this.insertionSort(list)
    }

    return list
  }

  getDiseaseOfType(type) {
    const { list } = this
    const filteredDiseases = list.filter(
      (disease) => disease.disease_type === type
    )

    this.list = filteredDiseases

    return this
  }

  get(id, umlsID) {
    const { list } = this
    let filteredDiseases = list.filter((disease) => disease.disease_id === id)

    if (filteredDiseases.length < 1) {
      filteredDiseases = list.filter((disease) => disease.disease_id === umlsID)
    }

    return filteredDiseases?.[0] || false
  }

  deleteDisease(id) {
    const { list } = this
    const filteredDiseases = list.filter((item) => item.disease_id !== id)

    this.list = filteredDiseases

    return this
  }

  filterDiseaseItems(item) {
    if (item.skipLogic) {
      item.skipLogic.map((val) =>
        this.skipList.push({
          skip_logic_id: val.skip_logic_id,
          answer: val.answer,
        })
      )
    }
    return {
      disease_id: item.disease_id,
      age_diagnosed: item.age_diagnosed,
    }
  }

  getDiseaseSummary() {
    const { list } = this
    const filtered = list.map((item) => this.filterDiseaseItems(item))
    const Items = {
      diseases: filtered,
      skip_logic: this.skipList,
    }

    return Items
  }
}
