import {
  isEnrolledAsync,
  supportedAuthenticationTypesAsync,
} from 'expo-local-authentication'

const biometricsSupportHandler = async () => {
  try {
    return await supportedAuthenticationTypesAsync()
  } catch {
    return false
  }
}

const checkBiometricPermissionDataEnrolled = async () => {
  try {
    const hasFacialPermissionDataEnrolled = await isEnrolledAsync()
    if (hasFacialPermissionDataEnrolled) return true
    return false
  } catch {
    return false
  }
}

const handleBiometricsSupportAndPermissions = async () => {
  const biometricsSupported = await biometricsSupportHandler()
  if (!biometricsSupported.length)
    return { success: false, error: 'Biometrics not supported' }
  const biometricEnrolled = await checkBiometricPermissionDataEnrolled()
  if (!biometricEnrolled)
    return { success: false, error: 'Permissions not granted' }
  return true
}

const getBiometricName = async () => {
  const BIOMETRICS_TYPES = {
    FACE_RECOGNITION: {
      name: 'face-recognition',
      value: 2,
    },
    FINGERPRINT: {
      name: 'fingerprint',
      value: 1,
    },
    EYE_OUTLINE: {
      name: 'eye-outline',
      value: 3,
    },
  }

  try {
    const biometricsName = await supportedAuthenticationTypesAsync()

    if (biometricsName.includes(BIOMETRICS_TYPES.FACE_RECOGNITION.value))
      return BIOMETRICS_TYPES.FACE_RECOGNITION.name
    if (biometricsName.includes(BIOMETRICS_TYPES.FINGERPRINT.value))
      return BIOMETRICS_TYPES.FINGERPRINT.name
    if (biometricsName.includes(BIOMETRICS_TYPES.EYE_OUTLINE.value))
      return BIOMETRICS_TYPES.EYE_OUTLINE.name
    return BIOMETRICS_TYPES.FINGERPRINT.name
  } catch (err) {
    console.log(
      '🚀 \n\n file: Biometrics.js:62 \n\n getBiometricName \n\n err:',
      err
    )
  }
}

export {
  handleBiometricsSupportAndPermissions,
  biometricsSupportHandler,
  checkBiometricPermissionDataEnrolled,
  getBiometricName,
}
