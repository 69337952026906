export default class Clinician {
  clinician_id = null

  is_connected = false

  clinician_code = null

  logo = null

  org_name = ''

  org_type = ''

  parent_org = null

  password_reset_timeframe = null

  units = null

  super_org = null
}
