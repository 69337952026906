import { View, Text, Image, TouchableOpacity } from 'react-native'

import { i18n } from '$localization/config.js'
import custom_styles from '$screens/main/family/edit_profile/_styles/main.styles.js'

const ButtonIcon = require('$assets/images/new_assets/icon-plus-btn.png')

export default function AddRemoveTwinGroupButton({
  siblingsList,
  onPressAdd = function () {},
  // onPressRemove = function () {},
  //   twinGroup = null,
}) {
  // FIXME: This seems to cause the issues of being able to add twins even if there are not twins available
  const hasMemberSiblings = siblingsList?.length > 0

  return (
    <View
      style={[custom_styles.addRemoveButtonContainer, { borderColor: '#ccc' }]}
    >
      <TouchableOpacity
        style={custom_styles.addRemoveButton}
        onPress={onPressAdd}
        disabled={!hasMemberSiblings}
      >
        {ButtonIcon && (
          <Image
            source={ButtonIcon}
            style={{
              marginRight: ButtonIcon ? 10 : 0,
              height: 10,
              width: 10,
            }}
          />
        )}
        <Text
          style={[
            custom_styles.addRemoveButtonText,
            { color: 'rgb(153,153,153)' },
          ]}
        >
          {i18n.t('select_twins')?.default}
        </Text>
      </TouchableOpacity>
    </View>
  )
}
