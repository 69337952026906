import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native'

import RelationshipInfoSectionController from './RelationshipInfo.controller.js'
import ParentsSection from './_components/parents_section/Parents.section.js'
import BloodRelatedParentsSection from './_components/parents_section/BloodRelatedParents.section.js'
import AdoptedSection from './_components/adopted_section/Adopted.section.js'
import TwinsSection from './_components/twins_section/Twins.section.js'
import CollapseButton from '$components/CollapseButton/index.js'

import { i18n } from '$localization/config.js'

import section_styles from '$screens/main/family/edit_profile/_styles/section.styles.js'

class RelationshipInfoSection extends RelationshipInfoSectionController {
  render() {
    const { relationshipData, currentMember, contentIsShown } = this.state
    const { twinsList, colorSettings } = this.props

    const relationshipToProband = currentMember?.relationship_to_proband

    const RelationshipInfoSections = () => {
      switch (`${relationshipToProband}`?.toLowerCase()) {
        case 'partner':
        case 'paternal grandfather':
        case 'paternal grandmother':
        case 'maternal grandfather':
        case 'maternal grandmother':
          return (
            <View>
              <ParentsSection
                colorSettings={colorSettings}
                parentController={this}
                currentMember={currentMember}
                relationshipData={relationshipData}
              />
            </View>
          )

        default:
          return (
            <View>
              <ParentsSection
                colorSettings={colorSettings}
                parentController={this}
                currentMember={currentMember}
                relationshipData={relationshipData}
              />

              <View style={section_styles.sectionDivider} />

              <TwinsSection
                colorSettings={colorSettings}
                parentController={this}
                currentMember={currentMember}
                relationshipData={relationshipData}
                twinsList={twinsList}
              />

              <View style={section_styles.sectionDivider} />

              <AdoptedSection
                colorSettings={colorSettings}
                parentController={this}
                currentMember={currentMember}
                relationshipData={relationshipData}
              />

              <View style={section_styles.sectionDivider} />

              <BloodRelatedParentsSection
                colorSettings={colorSettings}
                parentController={this}
                currentMember={currentMember}
                relationshipData={relationshipData}
              />
            </View>
          )
      }
    }

    return (
      <View
        style={[
          section_styles.container,
          contentIsShown && section_styles.containerPadding,
        ]}
      >
        <CollapseButton
          colorSettings={colorSettings}
          fieldIsHidden={!contentIsShown}
          showFieldHandler={this.showContentHandler}
          arrowCollapsedPosition="down"
          titleTextStyle={section_styles.titleText}
        >
          <View
            style={[
              section_styles.titleTextContainer,
              section_styles.CollapseButtonInnerContainer,
            ]}
          >
            <View>
              <Text style={section_styles.titleText}>
                {i18n.t('relationship')?.default}
              </Text>
            </View>
            <View>
              <TouchableOpacity
                style={section_styles.infoButtonContainer}
                onPress={() =>
                  this.showTooltip(
                    i18n.t('relationship')?.default,
                    i18n.t(
                      'in_this_section_you_can_make_changes_to_exactly_how_someone_is_related_to_you_for_example_you_can_change_a_sibling_to_a_half-sibling_by_changing_one_of_their_parents_the_more_accurate_your_family_history_is_the_more_accurate_your_risk_assessment_will_be'
                    )?.default
                  )
                }
              >
                <Image
                  style={section_styles.infoImage}
                  source={require('$assets/images/new_assets/icon-info-teal.png')}
                />
              </TouchableOpacity>
            </View>
          </View>
        </CollapseButton>
        {contentIsShown && <RelationshipInfoSections />}
      </View>
    )
  }
}

export default RelationshipInfoSection
