import { View, Text, TouchableOpacity, Image, StyleSheet } from 'react-native'
import Icon from 'react-native-vector-icons/Ionicons'

const ButtonMainView = ({ colorSettings, buttonName, onPress }) => (
  <View style={styles.mainContainer}>
    <TouchableOpacity
      style={[
        styles.diseaseButton,
        {
          borderColor:
            colorSettings?.selectable_btn_border_inactive_1 ||
            'rgba(74, 74, 74, 0.5)',
        },
      ]}
      onPress={() => onPress()}
    >
      <View style={styles.diseaseButtonTextContainer}>
        <Text
          style={[
            styles.diseaseButtonText,
            {
              color: colorSettings?.text_input_label_color_2 || 'black',
            },
          ]}
        >
          {/* {props.buttonName + " " + (state.diseaseCount ? "(" + state.diseaseCount + ")" : '')} */}
          {buttonName}
        </Text>
      </View>
      <View style={styles.imageContainer}>
        <Icon
          name="chevron-forward"
          size={20}
          color={colorSettings?.text_input_label_color_2 || 'black'}
        />
      </View>
    </TouchableOpacity>
  </View>
)

const styles = StyleSheet.create({
  diseaseButton: {
    width: '100%',
    borderWidth: 2,
    borderColor: 'rgba(234,234,234,.5)',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    minHeight: 74,
    marginHorizontal: 16,
    borderRadius: 5,
    paddingRight: 16,
    paddingLeft: 24,
    paddingVertical: 10,
    marginBottom: 16,
    alignSelf: 'center',
  },
  imageContainer: {
    flex: 1,
    alignItems: 'flex-end',
  },
  image: {
    alignSelf: 'flex-end',
    width: 12,
    height: 20,
  },
  diseaseButtonText: {
    // flex: 2,
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    // color: '#fff'
  },
  diseaseButtonTextContainer: {
    flex: 2,
  },
  mainContainer: {
    // marginBottom: 17,
    // marginTop: 14,
    // marginHorizontal: 18
    width: '100%',
  },
})

export default ButtonMainView
