import { useRef } from 'react'
import { View, Text, TextInput } from 'react-native'

import section_styles from '$screens/main/family/edit_profile/_styles/section.styles.js'

function InputField(props) {
  const {
    colorSettings,
    placeholder,
    onChangeText,
    value,
    onChange,
    label,
    keyField,
    hasSeparator = true,
  } = props

  const inputElement = useRef(null)

  return (
    <View style={{ zIndex: -1 }}>
      {hasSeparator && <View style={section_styles.contentSeparatorLine} />}
      <View style={section_styles.contentItem}>
        <View style={section_styles.contentItemLabelTextContainer}>
          <Text
            style={[
              section_styles.contentItemLabelText,
              { color: colorSettings?.text_input_label_color_1 },
            ]}
            onPress={() => {
              inputElement.current.focus()
            }}
          >
            {label}
          </Text>
        </View>

        <View style={section_styles.contentItemTextInputContainer}>
          <TextInput
            nativeID="web_text_input"
            ref={inputElement}
            style={[
              section_styles.contentItemTextInput,
              { color: colorSettings?.text_input_color_1 },
            ]}
            maxLength={45}
            placeholder={placeholder}
            placeholderTextColor={
              colorSettings?.text_input_placeholder_color_1 ||
              'rgba(74,74,74,0.5)'
            }
            autoCorrect={false}
            autoCapitalize="words"
            onChangeText={(val) => onChangeText(keyField, val)}
            onChange={onChange}
            value={value}
          />
        </View>
      </View>
    </View>
  )
}

// InputField.propTypes = {
//   colorSettings: PropTypes.shape({
//     text_input_color_1: PropTypes.string,
//     text_input_placeholder_color_1: PropTypes.string,
//     text_input_label_color_1: PropTypes.string,
//   }),
//   placeholder: PropTypes.string,
//   onChangeText: PropTypes.func,
//   value: PropTypes.string,
//   onChange: PropTypes.func,
//   label: PropTypes.string,
//   keyField: PropTypes.string,
//   hasSeparator: PropTypes.bool,
// }

InputField.defaultProps = {
  colorSettings: {
    text_input_color_1: '',
    text_input_placeholder_color_1: '',
    text_input_label_color_1: '',
  },
  placeholder: '',
  onChangeText: () => {},
  value: '',
  onChange: () => {},
  label: '',
  keyField: '',
  hasSeparator: true,
}

export default InputField
