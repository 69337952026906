import { useState } from 'react'
import { TextInput, View } from 'react-native'

import LabelField from '../label-field/LabelField'
import styles from './TextField.style'

export default function TextField({ colorSettings, inputFieldData }) {
  const [inputData, setInputData] = useState()

  return (
    <View style={styles.mainContainer}>
      <LabelField title={inputFieldData.label} colorSettings={colorSettings} />
      <TextInput
        nativeID="web_text_input"
        onChangeText={(item) => setInputData(item)}
        value={inputData}
        placeholder={inputFieldData.placeholder}
        placeholderTextColor={
          colorSettings?.text_input_placeholder_color_2 ||
          'rgba(74, 74, 74, 0.5)'
        }
        style={[
          styles.inputField,
          {
            borderBottomColor: colorSettings?.btn_no_fill_border_2 || 'black',
            color: colorSettings?.text_input_color_2 || 'black',
          },
        ]}
        returnKeyType="done"
      />
    </View>
  )
}

// TextField.propTypes = {
//   colorSettings: PropTypes.shape({
//     text_input_placeholder_color_2: PropTypes.string,
//     btn_no_fill_border_2: PropTypes.string,
//     text_input_color_2: PropTypes.string,
//   }),
//   inputFieldData: PropTypes.shape({
//     label: PropTypes.string,
//     placeholder: PropTypes.string,
//   }),
// }

TextField.defaultProps = {
  colorSettings: {},
  inputFieldData: {},
}
