import SkipLogicItem from './skipLogicItem'

export default class SkipLogicList {
  constructor(val) {
    this.list = val
  }

  insertionSort(arr) {
    for (let i = 1; i < arr.length; i++) {
      const prevValue = arr[i]
      const currentVal = arr[i].skip_logic_id
      for (var j = i - 1; j >= 0 && arr[j].skip_logic_id > currentVal; j--) {
        arr[j + 1] = arr[j]
      }
      arr[j + 1] = prevValue
    }
    return arr
  }

  getIndex(id) {
    if (!id) return false
    const { list } = this
    for (let i = 0; i < list.length; i++) {
      if (list[i].skip_logic_id === id) {
        return i
      }
    }
    return null
  }

  add(val, onPress) {
    if (!val) return []
    const newList = new SkipLogicItem(val)
    const { list } = this
    const index = this.getIndex(newList.skip_logic_id)

    if (val.question_type === 'yes_or_no') {
      if (onPress) {
        newList.answer = 'y'
      } else {
        newList.answer = 'n'
      }
    }

    if (newList.answer === undefined) {
      newList.answer = ''
    }

    if (index === false || index === null) {
      list.push(newList)
    } else {
      list[index] = newList
    }

    this.insertionSort(list)
    // list.reverse()
    return list
  }

  remove(id) {
    const { list } = this
    const index = this.getIndex(id)
    list.splice(index, 1)
    return list
  }

  setAge(id, age) {
    const { list } = this
    const index = this.getIndex(id)
    list[index].answer = age
    return list
  }

  get(id) {
    const { list } = this
    const index = this.getIndex(id)
    return list[index]
  }
}
