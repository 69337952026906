import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  itemDetailsSubtitleText: {
    color: 'rgb(102,102,102)',
    fontFamily: 'montserrat',
    fontSize: 11,
    fontFamily: 'montserrat-italic',
  },
  navigationBar: {
    flexDirection: 'row',
    height: 50,
    alignItems: 'center',
  },
  navigationBarTitleContainer: {
    flexDirection: 'row',
    flex: 0.8,
    height: '100%',
    alignItems: 'center',
  },
  navigationBarTitleText: {
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    // color: "white",
    textAlign: 'left',
  },

  navigationBarBackButton: {
    height: '100%',
    paddingLeft: 2,
    marginRight: 16,
    justifyContent: 'center',
    width: 50,
  },

  navigationBarDoneButton: {
    flex: 0.2,
    height: '100%',
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  navigationBarDoneButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    color: 'white',
    textAlign: 'right',
  },

  sectionItemContainer: {
    backgroundColor: 'white',
    width: '100%',
    height: 65,
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
    borderTopColor: '#ccc',
    borderBottomColor: '#ccc',
  },
  sectionItem: {
    // backgroundColor: "pink",
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  sectionItemImageLess: {
    // backgroundColor: "pink",
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    paddingHorizontal: 32,
  },
  sectionItemImageContainer: {
    flex: 0.2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionItemImage: {
    width: 32,
    height: 32,
    resizeMode: 'contain',
  },
  sectionItemLabelTextContainer: {
    flex: 0.8,
    paddingHorizontal: 0,
    justifyContent: 'center',
  },
  sectionImageLessItemLabelTextContainer: {
    paddingHorizontal: 32,
    justifyContent: 'center',
  },
  sectionItemLabelText: {
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    color: 'rgb(74,74,74)',
    textAlign: 'left',
  },

  /* Merged from SelectParent.js */
  sectionItemHorizontalContainer: {
    flexDirection: 'row',
    flex: 1.0,
    height: '100%',
  },
  sectionItemTitle: {
    flex: 0.8,
    justifyContent: 'center',
    paddingLeft: 32,
  },
  sectionItemAccessory: {
    flex: 0.2,
    alignItems: 'center',
    justifyContent: 'center',
  },

  formContainer: {
    marginVertical: 24,
    borderColor: '#ccc',
    borderWidth: 1.0,
    borderRadius: 10,
  },
  formTitleContainer: {
    paddingLeft: 8,
    marginTop: 12,
    justifyContent: 'center',
  },
  formTitleText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 14,
    textAlign: 'left',
    color: 'rgba(67,70,97,1.0)',
  },
  horizontalLabeledTextInputContainer: {
    flexDirection: 'row',
    height: 50,
  },
  textInputLabelContainer: {
    flex: 0.3,
    justifyContent: 'center',
    paddingLeft: 12,
  },
  textInputLabel: {
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    textAlign: 'left',
    color: 'rgba(153,153,153,1.0)',
  },
  textInputContainer: {
    flex: 0.7,
    justifyContent: 'center',
    paddingRight: 12,
  },
  textInputField: {
    height: '100%',
    width: '100%',
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    color: 'rgb(74,74,74)',
    textAlign: 'right',
  },

  modalBackgroundContainer: {
    backgroundColor: 'transparent',
    paddingTop: 80,
    paddingBottom: 16,
    // alignItems: "center",
    flex: 1.0,
  },
  modalContentContainer: {
    backgroundColor: 'white',
    paddingVertical: 16,
    paddingHorizontal: 16,
    borderColor: '#ccc',
    borderWidth: 1.0,
    borderRadius: 10,
    overflow: 'hidden',
  },
})
