import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  mainContainer: {
    width: '100%',
  },
  inputField: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'center',
    height: 50,
    borderBottomWidth: 0.5,
    alignSelf: 'center',
  },
})
