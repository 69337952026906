import React from 'react'
import { View, TouchableOpacity, Image } from 'react-native'

const kebabMenuImage = require('$assets/images/new_assets/icon-more.png')

const style = {
  menuButtonContainer: {
    width: 90,
    height: '80%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButton: {
    width: '100%',
    height: '100%',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingRight: 20,
  },
  image: {
    width: 4,
    height: 16,
    resizeMode: 'contain',
  },
}

export default function SettingsMenuButton({ actionItem }) {
  return (
    <View style={style.menuButtonContainer}>
      <TouchableOpacity style={style.menuButton} onPress={actionItem}>
        <Image source={kebabMenuImage} style={style.image} />
      </TouchableOpacity>
    </View>
  )
}
