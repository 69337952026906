import { Platform, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgb(245,245,245)',
  },
  profile: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 90,
    borderRadius: 10,
    marginBottom: 16,
    marginHorizontal: 16,
  },
  profImageContainer: {
    flex: Platform.OS === 'web' ? 1 : 1.5,
    alignSelf: 'center',
    alignItems: 'center',
  },
  profDetails: {
    flex: 4,
    alignSelf: 'center',
    alignItems: 'flex-start',
  },
  profInfoName: {
    fontFamily: 'karla-bold',
    color: '#fff',
    fontSize: 18,
  },
  profInfo: {
    fontFamily: 'montserrat',
    color: '#fff',
    fontSize: 12,
  },
  profileNext: {
    flex: 1,
    alignSelf: 'center',
    alignItems: 'center',
  },
  DetailContainer: {
    backgroundColor: '#fff',
    borderRadius: 10,
    marginHorizontal: 16,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.2,
    shadowRadius: 2,
    backgroundColor: '#fff',
  },
  buttonDetails: {
    height: 72,
    borderBottomColor: 'rgb(245,245,245)',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonImage: {
    flex: 0.3,
    alignSelf: 'center',
    alignItems: 'center',
  },
  buttonNameContainer: {
    flex: 4,
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
  },
  buttonName: {
    fontFamily: 'montserrat-semibold',
    color: 'rgb(65,70,97)',
    fontSize: 14,
  },
  buttonNext: {
    flex: 1,
    alignSelf: 'center',
    alignItems: 'center',
  },

  logoutButton: {
    height: 50,
    marginTop: 16,
    marginHorizontal: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(234,234,234)',
    borderRadius: 10,
  },
  logoutButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    textAlign: 'center',
  },
})
export default styles
