import { Platform, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  mainContainer: {
    alignSelf: 'center',
  },
  headerTitle: {
    fontFamily: 'karla-bold',
    fontSize: 18,
    textAlign: 'center',
  },
  inputField: {
    width: 80,
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'center',
    height: 50,
    borderBottomWidth: 0.5,
    alignSelf: 'center',
    ...Platform.select({
      web: {
        outlineColor: '#ccc',
      },
    }),
  },
  textInputOuterContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  textInputInnerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

export default styles
