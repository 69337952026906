import { useState } from 'react'
import translateDisease from '$screens/personal_details/relative_gene_test/_utils/TextTranslation.js'
// components
import SubListView from './SubList.view'

export const SubListLogic = ({ colorSettings, currentData, onEdit }) => {
  const textChecker = (text) => {
    const translated = text.toLowerCase()
    const list = ['was it', 'was there']

    if (!list.some((string) => translated.includes(string))) {
      return translateDisease({ diseaseName: text })
    }

    for (const val of list) {
      if (translated.includes(val)) {
        const stringLength = val.length
        const string = translated.slice(stringLength + 1)
        const deleteLastCharacter = string.slice(0, string.length - 1)
        const addDot = deleteLastCharacter.concat('.')
        const firstCharacter = addDot.slice(0, 1)
        const capitalize = firstCharacter.toUpperCase()
        const lastCharacter = addDot.slice(1)
        const combinedString = capitalize.concat(lastCharacter)
        return translateDisease({ diseaseName: combinedString })
      }
    }
  }

  return (
    <SubListView
      colorSettings={colorSettings}
      currentData={currentData}
      onEdit={onEdit}
      textChecker={textChecker}
    />
  )
}
