import { Component } from 'react'
import { Text, TouchableOpacity, StyleSheet } from 'react-native'

import { i18n } from '$localization/config.js'

export default class SelectButton extends Component {
  parent = this.props.parent

  genes = this.props.genesAPI

  colorSettings = this.props.colorSettings

  renderCount = 0

  state = {
    selectOption: false,
  }

  _onSelect = () => {
    this.setState((previousState) => ({
      selectOption: !previousState.selectOption,
    }))

    if (!this.state.selectOption) {
      this.parent.addGene(this.props.id)
    } else {
      this.parent.removeGene(this.props.id)
    }
  }

  buttonName = (id) => {
    const { genes } = this
    let geneName

    for (const val in genes) {
      const _geneObj_ = genes[val]
      if (_geneObj_) {
        switch (`${_geneObj_?.gene}`.toLowerCase()) {
          case 'unsure':
          case 'other':
            // Translation for 'unsure' & 'other'
            const T_geneObj = `${_geneObj_?.gene}`.toLowerCase()
            geneName = i18n.t(T_geneObj).title
            break
          default:
            geneName = _geneObj_?.gene
            break
        }
      }

      // if (genes[val]['id'] == id) {
      // 	return genes[val]['gene']
      // }

      if (_geneObj_?.id === id) {
        return geneName
      }
    }

    if (!id) {
      return i18n.t('im_not_sure').default
    }
  }

  render() {
    const buttonActive = {
      backgroundColor:
        this.colorSettings?.selectable_btn_active_1 || 'rgba(0, 0, 0, 0.2)',
      borderColor: this.colorSettings?.btn_no_fill_border_2 || 'black',
    }
    const buttonInactive = {
      borderColor: this.colorSettings?.selectable_btn_active_1 || 'black',
    }

    return (
      <TouchableOpacity
        style={[
          styles.buttonSelect,
          {
            width: '100%',
            marginBottom: 14,
          },
          this.state.selectOption ? buttonActive : buttonInactive,
        ]}
        onPress={() => this._onSelect()}
        activeOpacity={1}
      >
        <Text
          style={[
            styles.selectButtonText,
            {
              color: this.colorSettings?.text_input_label_color_2 || 'black',
            },
          ]}
        >
          {this.buttonName(this.props.id)}
        </Text>
      </TouchableOpacity>
    )
  }
}

const styles = StyleSheet.create({
  buttonSelect: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    borderWidth: 1,
    borderColor: '#fff',
    height: 60,
    width: '45%',
  },
  selectButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
})
