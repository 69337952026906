const imageURLs = {
  FAMGENIX: {
    // Backgrounds
    'bg-green': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/bg-green.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/bg-green%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/bg-green%403x.png',
      },
    ],
    'bg-signup': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/bg-signup.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/bg-signup%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/bg-signup%403x.png',
      },
    ],
    'bg-intro-3': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/bg-intro-3.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/bg-intro-3%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/bg-intro-3%403x.png',
      },
    ],

    // Logo
    logo: [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/logo.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/logo%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/logo%403x.png',
      },
    ],

    // BackButton
    'back-black': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/back-black.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/back-black%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/back-black%403x.png',
      },
    ],
    'back-white': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/back-white.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/back-white%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/back-white%403x.png',
      },
    ],

    // SplashTutorial
    'illus-intro-1': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/illus-intro-1.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/illus-intro-1%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/illus-intro-1%403x.png',
      },
    ],
    'illus-intro-2': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/illus-intro-2.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/illus-intro-2%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/illus-intro-2%403x.png',
      },
    ],
    'illus-intro-3': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/illus-intro-3.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/illus-intro-3%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/illus-intro-3%403x.png',
      },
    ],

    // ShowPassword
    'eye-close-black': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/eye-close-black.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/eye-close-black%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/eye-close-black%403x.png',
      },
    ],
    'eye-black': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/eye-black.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/eye-black%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/eye-black%403x.png',
      },
    ],

    // Terms/Privacy
    'illus-terms': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/illusTerms.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/illusTerms%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/illusTerms%403x.png',
      },
    ],

    // Success
    'success-check-purple': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/success-check-purple.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/success-check-purple%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/success-check-purple%403x.png',
      },
    ],
    'success-badge': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/success-badge.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/success-badge%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/success-badge%403x.png',
      },
    ],

    // Info
    'icon-info-white': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/icon-info-white.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/icon-info-white%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/icon-info-white%403x.png',
      },
    ],

    // Close
    'icon-close-modal': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/icon-close-modal.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/icon-close-modal%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/icon-close-modal%403x.png',
      },
    ],

    // GenderIcons
    'gender-male': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/gender-male.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/gender-male%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/gender-male%403x.png',
      },
    ],
    'gender-female': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/gender-female.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/gender-female%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/gender-female%403x.png',
      },
    ],
    'gender-male-deceased': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/gender-male-deceased.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/gender-male-deceased%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/gender-male-deceased%403x.png',
      },
    ],
    'gender-female-deceased': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/gender-female-deceased.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/gender-female-deceased%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/gender-female-deceased%403x.png',
      },
    ],

    // ModalHelpers
    'modal-bg-family': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/modal-bg-family.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/modal-bg-family%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/modal-bg-family%403x.png',
      },
    ],
    'modal-bg-children': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/modal-bg-children.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/modal-bg-children%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/modal-bg-children%403x.png',
      },
    ],
    'modal-bg-siblings': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/modal-bg-siblings.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/modal-bg-siblings%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/modal-bg-siblings%403x.png',
      },
    ],

    // RelationshipTutorial
    't-1': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t-1.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t-1%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t-1%403x.png',
      },
    ],
    't-2': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t-2.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t-2%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t-2%403x.png',
      },
    ],
    't-3': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t-3.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t-3%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t-3%403x.png',
      },
    ],
    't1-img1': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t1-img1.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t1-img1%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t1-img1%403x.png',
      },
    ],
    't1-img2': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t1-img2.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t1-img2%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t1-img2%403x.png',
      },
    ],
    't1-img3': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t1-img3.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t1-img3%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t1-img3%403x.png',
      },
    ],
    't2-img1': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t2-img1.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t2-img1%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t2-img1%403x.png',
      },
    ],
    't2-img2': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t2-img2.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t2-img2%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t2-img2%403x.png',
      },
    ],
    't2-img3': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t2-img3.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t2-img3%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t2-img3%403x.png',
      },
    ],
    't3-img1': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t3-img1.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t3-img1%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t3-img1%403x.png',
      },
    ],
    't3-img2': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t3-img2.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t3-img2%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t3-img2%403x.png',
      },
    ],
    't3-img3': [
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t3-img3.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t3-img3%402x.png',
      },
      {
        uri: 'https://famgenix-logos-united-states-public.s3.us-east-2.amazonaws.com/famgenix/app/assets/images/t3-img3%403x.png',
      },
    ],
  },

  COH: {},
}

/*
"": [
  { uri: "" },
  { uri: "" },
  { uri: "" },
],
*/

export { imageURLs }
