import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  titleText: {
    color: 'white',
    fontFamily: 'karla-bold',
    fontSize: 24,
    textAlign: 'center',
  },

  collapsableContainer: {
    // backgroundColor: "green",
    // borderWidth: 1.0,
    // borderColor: "black",
    marginBottom: 10,
  },

  buttonContainer: {
    flexDirection: 'row',
    height: 75.0,
    paddingLeft: 24,
    marginBottom: 16.0,
    borderRadius: 5.0,
    borderWidth: 2.0,
    // borderColor: "rgba(234,234,234,0.5)",
  },
  buttonContent: {
    flex: 1.0,
  },
  buttonTitleTextContainer: {
    flex: 0.85,
    height: '100%',
    justifyContent: 'center',
  },
  buttonTitleText: {
    // color: "white",
    fontFamily: 'montserrat-semibold',
    fontSize: 18,
    textAlign: 'left',
  },
  buttonAccessoryButtonContainer: {
    flex: 0.15,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  roundedButtonContainer: {
    flexDirection: 'row',
    height: 60.0,
    paddingHorizontal: 20,
    // marginBottom: 10,
    borderRadius: 37.5,
    borderWidth: 2.0,
    borderColor: 'rgba(255,255,255,0.5)',
  },
  roundedButtonTitleTextContainer: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  roundedButtonTitleText: {
    color: 'white',
    fontFamily: 'montserrat-semibold',
    fontSize: 18,
    textAlign: 'center',
  },

  roundedSwitchButton: {
    marginHorizontal: 0,
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2.0,
    borderColor: 'white',
    borderRadius: 30,
  },
  roundedSwitchButtonText: {
    color: 'white',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    textAlign: 'center',
  },

  itemDetailsContainer: {
    // backgroundColor: "green",
    padding: 16,
  },
  itemDetailRowContainer: {
    // borderWidth: 1.0,
    // borderColor: "black",
    flexDirection: 'row',
    flex: 1.0,
    marginVertical: 8,
  },
  rowLabelContainer: {
    flex: 0.6,
  },
  rowLabelText: {
    color: 'white',
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    textAlign: 'left',
  },
  rowLabelTextBold: {
    color: 'white',
    fontFamily: 'montserrat-semibold',
    fontSize: 13,
    textAlign: 'left',
  },
  rowAccessoryContainer: {
    // borderWidth: 1.0,
    // borderColor: "black",
    flexDirection: 'row',
    flex: 0.4,
    justifyContent: 'flex-end',
  },
  rowAccessoryButton: {
    // borderWidth: 1.0,
    // borderColor: "black",
    flex: 0.5,
    height: '100%',
    alignItems: 'flex-end',
  },
  rowAccessoryButtonText: {
    // borderWidth: 1.0,
    // borderColor: "black",
    color: 'white',
    fontFamily: 'montserrat-semibold',
    fontSize: 13,
    textAlign: 'right',
  },

  inlineButton: {
    // backgroundColor: "white",
    // backgroundColor: "rgba(255,255,255,0.5)",
    marginHorizontal: 10,
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },

  opaqueRoundedContainer: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
    borderRadius: 22,
  },
  opaqueRoundedContainerTitleText: {
    color: 'white',
    fontFamily: 'montserrat-semibold',
    fontSize: 14,
    textAlign: 'center',
  },
})

export default styles
