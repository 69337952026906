export function ConnectionFilter(item) {
  if (!item) {
    return 'Pending'
  }

  switch (item) {
    case 'two-way-connected':
      return 'Connected'
    case 'one-way-connected':
      return 'Connected - Clinician opted out of updates'
    default:
      return item.charAt(0).toUpperCase() + item.slice(1)
  }
}

export function ConnectionStatusColor(item, colorSettings) {
  switch (item) {
    case 'disconnected' || 'declined':
      return colorSettings?.clinician_disconnected || 'rgba(153, 153, 153, 1)'
    case 'pending':
      return colorSettings?.clinician_pending || 'rgba(209, 101, 112, 1)'
    default:
      return colorSettings?.clinician_connected || 'rgba(132, 209, 192, 1)'
  }
}
