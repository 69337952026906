export const STORE_MEMBER_PROFILE = 'STORE_MEMBER_PROFILE'
export const CLEAR_MEMBER_PROFILE = 'CLEAR_MEMBER_PROFILE'

export const TOGGLE_VITAL_STATUS = 'TOGGLE_VITAL_STATUS'
export const STORE_AGE_OF_DEATH = 'STORE_AGE_OF_DEATH'

export const SET_CURRENT_MEMBER = 'SET_CURRENT_MEMBER'
export const CLEAR_CURRENT_MEMBER = 'CLEAR_CURRENT_MEMBER'

export const SET_SHARING_SETTING_MEMBER = 'SET_SHARING_SETTING_MEMBER'
export const CLEAR_SHARING_SETTING_MEMBER = 'CLEAR_SHARING_SETTING_MEMBER'
export const SHOW_INVITE_HELPER_MODAL = 'SHOW_INVITE_HELPER_MODAL'
export const SET_INVITED_MEMBER = 'SET_INVITED_MEMBER'

/* Reloaders */
export const SET_RELOADERS_TO_DEFAULT = 'SET_RELOADERS_TO_DEFAULT'
export const SET_SHOULD_SECTIONAL_RELOAD = 'SET_SHOULD_SECTIONAL_RELOAD'
export const SET_SHOULD_RELOAD_PARENTS = 'SET_SHOULD_RELOAD_PARENTS'
export const SET_SHOULD_RELOAD_PARENTS_BLOOD_RELATION =
  'SET_SHOULD_RELOAD_PARENTS_BLOOD_RELATION'
export const SET_SHOULD_RELOAD_TWINS = 'SET_SHOULD_RELOAD_TWINS'
export const SET_SHOULD_RELOAD_INVITE_STATUS = 'SET_SHOULD_RELOAD_INVITE_STATUS'
export const SET_SHOULD_RELOAD_DISEASES = 'SET_SHOULD_RELOAD_DISEASES'
export const SET_SHOULD_RELOAD_GENETIC_TESTINGS =
  'SET_SHOULD_RELOAD_GENETIC_TESTINGS'
/* Reloaders */

export const SET_NEW_PARENTS_FOR_MEMBER = 'SET_NEW_PARENTS_FOR_MEMBER'
export const SET_PARENTS_RELATIONSHIP_INFO = 'SET_PARENTS_RELATIONSHIP_INFO'

export const SET_DID_ADD_NEW_MEMBER = 'SET_DID_ADD_NEW_MEMBER'
export const SET_ADD_NEW_MEMBER_PAYLOAD = 'SET_ADD_NEW_MEMBER_PAYLOAD'

export const SET_ADD_TWINS_PAYLOAD = 'SET_ADD_TWINS_PAYLOAD'
