import { Component } from 'react'
import {
  View,
  Image,
  ImageBackground,
  Modal,
  Text,
  TouchableOpacity,
  Platform,
} from 'react-native'

import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'

import styles from '$screens/relatives/_styles/tutorial.modal.styles.js'

class TutorialModal extends Component {
  onDismiss = this.props.onDismiss ?? function () {}

  state = {
    visible: this.props.visible,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    return {
      ...prevState,
      visible: newProps.visible,
    }
  }

  dismissButtonAction = () => {
    this.onDismiss()
  }

  render() {
    return null
  }

  _render_() {
    const colorSettings = this.props.colorSettings
    const { visible } = this.state

    return (
      <Modal animationType={'fade'} visible={visible} transparent={true}>
        <View style={styles.container}>
          <View style={styles.contentContainer}>
            <View
              style={[
                styles.content,
                {
                  width: 339,
                  height:
                    Platform.OS === 'ios' || Platform.OS === 'android'
                      ? '73%'
                      : '85%',
                  borderRadius: 10,
                  overflow: 'hidden',
                },
              ]}
            >
              <View style={{ width: '100%', height: '100%' }}>
                <ImageBackground
                  source={require('$assets/images/new_assets/modal-bg-siblings.png')}
                  style={styles.contentHeader}
                >
                  <View style={styles.dismissButtonView}>
                    <TouchableOpacity
                      onPress={() => this.dismissButtonAction()}
                    >
                      <Image
                        source={require('$assets/images/new_assets/icon-close-modal-w.png')}
                        style={{ width: 24, height: 24 }}
                      ></Image>
                    </TouchableOpacity>
                  </View>
                </ImageBackground>

                <View
                  style={[
                    styles.contentBody,
                    {
                      marginHorizontal: 16,
                      marginVertical: 25,
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontFamily: 'karla-bold',
                      fontSize: 21,
                      lineHeight: 24,
                      color: 'rgb(65,70,97)',
                      textAlign: 'center',
                    }}
                  >
                    {i18n.t('now_lets_add_your_brothers_and_sisters')?.default}
                  </Text>

                  <Text
                    style={{
                      marginVertical: 16,
                      fontFamily: 'montserrat',
                      fontSize: 15,
                      lineHeight: 24,
                      color: 'rgb(102,102,102)',
                      textAlign: 'center',
                    }}
                  >
                    {
                      i18n.t(
                        'you_can_mark_a_sibling_as_adopted_comma_twin_comma_or_half-sibling_by_selecting_them_to_edit_their_profile_dot'
                      )?.default
                    }
                  </Text>

                  <Text
                    style={{
                      marginBottom: 16,
                      fontFamily: 'montserrat',
                      fontSize: 14,
                      lineHeight: 24,
                      color: 'rgb(102,102,102)',
                      textAlign: 'center',
                    }}
                  >
                    {
                      i18n.t(
                        'dont_forget_to_edit_details_for_anyone_who_has_had_a_disease_or_genetic_testing'
                      )?.default
                    }
                  </Text>

                  <TouchableOpacity
                    onPress={() => this.dismissButtonAction()}
                    style={[
                      styles.nextButton,
                      {
                        backgroundColor: colorSettings?.btn_filled_enabled_1,
                        borderColor: colorSettings?.btn_filled_border_1,
                        marginTop: 8,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.nextButtonText,
                        {
                          color: colorSettings?.btn_filled_text_1,
                        },
                      ]}
                    >
                      {i18n.t('continue')?.default}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  const { store } = state
  return {
    colorSettings: store.colorSettings,
  }
}

export default connect(mapStateToProps, null)(TutorialModal)
