import { i18n } from '$localization/config.js'

import * as actionTypes from './actionTypes'

const oneMinute = 60000
const refreshInterval = __DEV__ ? oneMinute * 5 : oneMinute

const initialState = {
  /** Defaults */
  selectedLanguage: 'en',
  showLoadingView: false,
  isFirstLogin: false,
  loginTimeOut: 600000,
  shouldShowGlobalLoader: true,
  showModalInstructions: true,
  shouldAddPhoneNumber: true,
  shouldProceedToMain: false,
  shouldShowOtherFamilyMembersTutorial: false,
  shouldDelayOtherFamilyMembersTutorial: false,

  allowBackgroundRefresh: true,
  backgroundRefreshInterval: refreshInterval,

  notifBadgeRefresh: true,
  notifBadgeRefreshInterval: refreshInterval,

  surveyBadgeRefresh: true,
  surveyBadgeRefreshInterval: refreshInterval,
  surveyBadgeCount: 0,

  diseaseCategory: [],
  diseaseCategoryList: null,

  appSettings: null,
  splashSettings: null,
  colorSettings: null,

  customWebViewUri: '',
  tutorialWithSurvey: false,
  bottomTabBarTitles: {
    Home: i18n.t('home').title,
    Family: i18n.t('family').title,
    Risks: i18n.t('risks').title,
    Surveys: i18n.t('surveys').title,
  },
  lastScreenLogin: '',
  inviteID: null,
  userHasPassword: false,

  isError: {
    isShown: false,
    status: '',
    message: '',
    type: '',
  },
  isOnboarding: true,
  currentScreenStack: '',
  navigationState: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_IS_FIRST_LOGIN:
      return {
        ...state,
        isFirstLogin: action.data,
      }

    case actionTypes.TURN_ON_IS_FIRST_LOGIN:
      return {
        ...state,
        isFirstLogin: true,
      }

    case actionTypes.TURN_OFF_IS_FIRST_LOGIN:
      return {
        ...state,
        isFirstLogin: false,
      }

    case actionTypes.SHOW_GLOBAL_LOADER:
      return {
        ...state,
        shouldShowGlobalLoader: true,
      }

    case actionTypes.HIDE_GLOBAL_LOADER:
      return {
        ...state,
        shouldShowGlobalLoader: false,
      }

    // To be deprecated
    case actionTypes.STORE_ACCOUNT:
      return {
        ...state,
        account: action.data,
      }

    case actionTypes.SET_SHOW_LOADING_VIEW:
      return {
        ...state,
        showLoadingView: action.data,
      }

    // To be deprecated
    case actionTypes.STORE_PROBAND:
      return {
        ...state,
        proband: action.data,
      }

    case actionTypes.CHANGE_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.data,
      }

    case actionTypes.SET_APP_SETTINGS:
      return {
        ...state,
        appSettings: action.data,
      }

    case actionTypes.SET_SPLASH_SETTINGS:
      return {
        ...state,
        splashSettings: action.data,
      }

    case actionTypes.SET_COLOR_SETTINGS:
      return {
        ...state,
        colorSettings: action.data,
      }

    case actionTypes.SET_SURVEY_STATUS:
      return {
        ...state,
        tutorialWithSurvey: action.data,
      }

    case actionTypes.STORE_SURVEYCOUNT:
      return {
        ...state,
        surveyBadgeCount: action.data,
      }

    case actionTypes.SET_DISEASE_CATEGORIES:
      return {
        ...state,
        diseaseCategory: action.data,
      }

    case actionTypes.SET_SUB_CATEGORIES:
      return {
        ...state,
        diseaseCategoryList: action.data,
      }

    case actionTypes.SET_BOTTOM_TAB_BAR_TITLES:
      return {
        ...state,
        bottomTabBarTitles: {
          Home: i18n.t('home').title,
          Family: i18n.t('family').title,
          Risks: i18n.t('risks').title,
          Surveys: i18n.t('surveys').title,
        },
      }

    case actionTypes.SET_CUSTOM_WEB_VIEW_URI:
      return {
        ...state,
        customWebViewUri: action.data,
      }

    case actionTypes.SHOULD_ADD_PHONE_NUMBER:
      return {
        ...state,
        shouldAddPhoneNumber: action.data,
      }

    case actionTypes.SET_BG_REFRESH_ALL:
      var val = action.data
      return {
        ...state,
        allowBackgroundRefresh: val,
        notifBadgeRefresh: val,
        surveyBadgeRefresh: val,
      }

    case actionTypes.SET_BG_REFRESH_INTERVAL_ALL:
      var val = action.data
      return {
        ...state,
        backgroundRefreshInterval: val,
        notifBadgeRefreshInterval: val,
        surveyBadgeRefreshInterval: val,
      }

    case actionTypes.SET_SHOULD_PROCEED_TO_MAIN:
      return {
        ...state,
        shouldProceedToMain: action.data,
      }

    case actionTypes.SET_SHOULD_SHOW_OTHER_FAMILY_MEMBERS_TUTORIAL:
      return {
        ...state,
        shouldShowOtherFamilyMembersTutorial: action.data,
      }

    case actionTypes.SET_DELAY_OTHER_FAMILY_MEMBERS_TUTORIAL:
      return {
        ...state,
        shouldDelayOtherFamilyMembersTutorial: action.data,
      }

    case actionTypes.SET_LAST_LOGIN_SCREEN:
      return {
        ...state,
        lastScreenLogin: action.data,
      }

    case actionTypes.SET_INVITE_ID:
      return {
        ...state,
        inviteID: action.data,
      }

    case actionTypes.SET_USER_HAS_PASSWORD:
      return {
        ...state,
        userHasPassword: action.data,
      }

    case actionTypes.SET_NOTIFICATION_ERROR:
      return {
        ...state,
        isError: action.data,
      }

    case actionTypes.SET_IS_ONBOARDING:
      return {
        ...state,
        isOnboarding: action.data,
      }

    case actionTypes.SET_CURRENT_SCREEN_STACK:
      return {
        ...state,
        currentScreenStack: action.data,
      }

    case actionTypes.SET_NAVIGATION_STATE:
      return {
        ...state,
        navigationState: action.data,
      }

    default:
      return state
  }
}
