import { Text, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import styles from '../../../static/main.styles'
import { i18n } from '$localization/config.js'

function ContinueButtonPage3({
  tutorialWithSurvey,
  onFamilyScreenWalkthroughCloseAction,
}) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const navigation = useNavigation()
  const buttonTitleDefault = i18n.t('continue')?.default
  const buttonTitleWithSurvey = i18n.t('go_to_surveys')?.default

  const continueButtonAction = () => {
    onFamilyScreenWalkthroughCloseAction()

    if (tutorialWithSurvey) navigation.navigate('Surveys')
  }

  return (
    <TouchableOpacity
      style={styles.roundedContinueButton(
        colorSettings?.btn_filled_enabled_1,
        colorSettings?.btn_filled_border_1
      )}
      onPress={continueButtonAction}
    >
      <Text
        style={styles.roundedContinueButtonText(
          colorSettings?.btn_filled_text_1
        )}
      >
        {tutorialWithSurvey ? buttonTitleWithSurvey : buttonTitleDefault}
      </Text>
    </TouchableOpacity>
  )
}

export default ContinueButtonPage3
