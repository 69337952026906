import * as actionTypes from './actionTypes'

const initialState = {
  riskFactors: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_RISK_FACTORS:
      return {
        ...state,
        riskFactors: action.data,
      }

    case actionTypes.CLEAR_RISK_FACTORS:
      return {
        ...state,
        riskFactors: null,
      }

    default:
      return state
  }
}
