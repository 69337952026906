import { i18n } from '$localization/config.js'

const strings = {
  // DiseaseMainList.js
  diseaseMainScreen: {
    navTitle: 'Personal Details',
    infoAlertTitle: 'Disease',
    infoAlertBody:
      'This includes any type of health problem, disease, diagnosis, etc.',
    bottomButtonTitle: {
      next: 'Next',
      done: 'Done',
    },
    headerTitle: {
      user: 'Have you ever been diagnosed with any of the following types of conditions?',
      member:
        'Has this person ever been diagnosed with any of the following types of conditions?',
    },
    headerTitleSingleCategory: {
      user: 'Have you ever been diagnosed with any of the following?',
      member: 'Has this person ever been diagnosed with any of the following?',
    },
    subtitle: 'Select the type of Disease',
    loadingTitle: 'Saving data\nPlease wait',
  },
}

export default strings
