import { FlatList } from 'react-native'
import styles from '../styles'
import GeneticTestingListItem from './GeneticTestingListItem'
import { i18n } from '$localization/config.js'

function GeneticTestingSection({ genes }) {
  const positiveGenesData = genes.filter(
    (gene) => gene.result !== 'n' && gene.result !== 'ln'
  )
  const userHasAnyPositiveGenes = positiveGenesData?.length > 0
  const hasNegativeGeneticTestingResults = genes.some(
    (gene) => gene.result === 'n' || gene.result === 'ln'
  )
  const negativeResult = {
    umls_name: i18n.t('negative_genetic_testing.default'),
    result: 'n',
  }

  return (
    <FlatList
      style={styles.sectionListSummaryDetailsContainer}
      renderItem={({ item }) => <GeneticTestingListItem item={item} />}
      data={
        userHasAnyPositiveGenes
          ? positiveGenesData
          : hasNegativeGeneticTestingResults && [negativeResult]
      }
    />
  )
}

export default GeneticTestingSection
