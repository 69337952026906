import { Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import styles from '../styles'
import { i18n } from '$localization/config.js'

function SectionHeader({ title }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <View style={styles.sectionHeaderContainer}>
      <Text style={styles.sectionHeaderText(colorSettings)}>
        {i18n.t(title).default}
      </Text>
    </View>
  )
}

export default SectionHeader
