import { Platform } from 'react-native'

export const WEB = 'web'
export const IOS = 'ios'
export const ANDROID = 'android'

export const IS_MOBILE = () => Platform.OS === IOS || Platform.OS === ANDROID
export const IS_IOS = () => Platform.OS === IOS
export const IS_ANDROID = () => Platform.OS === ANDROID
export const IS_WEB = () => Platform.OS === WEB
