import { Platform } from 'react-native'

const headerNavigationDefaultOptions = (
  headerTintColor,
  headerBackgroundColor
) => {
  const options = {
    headerBackTitle: null,
    headerTintColor,
    headerStyle: {
      backgroundColor: headerBackgroundColor,
      borderBottomWidth: 0,
      shadowColor: 'transparent',
      elevation: 0,
      shadowOpacity: 0,
      boxShadow: '',
    },
    headerTitleAlign: 'center',
  }

  if (Platform.OS === 'web') {
    options.animationEnabled = false
    options.headerLeftContainerStyle = {
      width: '28%',
      alignItems: 'center',
    }

    options.headerRightContainerStyle = {
      width: '28%',
      alignItems: 'center',
    }
  }

  return options
}

export default headerNavigationDefaultOptions
