import { SectionList, View } from 'react-native'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import ErrorBoundary from 'react-native-error-boundary'
import styles from './styles'
import SectionHeader from './components/SectionHeader'
import SectionListItem from './components/SectionListItem'
import { getSearchedFamilyMembersWithDiseaseOrGeneticTestingData } from './helpers/helper'
import { GenerateHtmlTemplate } from './HtmlTemplate/HtmlTemplate'

function FamilySummaryScreen() {
  const navigation = useNavigation()
  const [familySummaryData, setFamilySummaryData] = useState([])
  const otherMembersData = useSelector(
    (state) => state.probandStore.familySummaryData
  )

  const filterFamilyMembersWithDiseaseOrGeneticTestingData = () => {
    const familyMembersWithData = otherMembersData
      ?.map((familyType) =>
        getSearchedFamilyMembersWithDiseaseOrGeneticTestingData(familyType.data)
      )
      .flat()

    const uniqueFamilyMembers = familyMembersWithData.filter(
      (member, index, self) =>
        self.findIndex((current) => current.member_id === member.member_id) ===
        index
    )

    const personalHistoryData = uniqueFamilyMembers.filter(
      (item) => item.relationship_to_proband === 'proband'
    )
    const familyHistoryData = uniqueFamilyMembers.filter(
      (item) => item.relationship_to_proband !== 'proband'
    )

    const sectionData = [
      {
        title: 'personal_health_history',
        data:
          personalHistoryData.length > 0
            ? personalHistoryData
            : [{ error: 'no_known_health_issues' }],
      },
      {
        title: 'family_health_history',
        data:
          familyHistoryData.length > 0
            ? familyHistoryData
            : [{ error: 'no_known_health_issues' }],
      },
    ]

    return setFamilySummaryData(sectionData)
  }

  useEffect(() => {
    filterFamilyMembersWithDiseaseOrGeneticTestingData()
  }, [otherMembersData])

  useEffect(() => {
    const generatedHtmlTemplate = GenerateHtmlTemplate(familySummaryData)
    navigation.setParams({
      htmlTemplate: generatedHtmlTemplate,
    })
  }, [familySummaryData])

  return (
    <ErrorBoundary>
      <View style={styles.container}>
        <SectionList
          style={styles.tabContainer}
          showsVerticalScrollIndicator={false}
          sections={familySummaryData}
          keyExtractor={(item, index) => item + index}
          renderItem={({ item }) => <SectionListItem currentMember={item} />}
          renderSectionHeader={({ section: { title } }) => (
            <SectionHeader title={title} />
          )}
        />
      </View>
    </ErrorBoundary>
  )
}

export default FamilySummaryScreen
