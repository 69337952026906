import { Text } from 'react-native'
import styles from '../styles'
import { i18n } from '$localization/config.js'

function GeneticTestingListItem({ item }) {
  const renderGeneticResultText = () => {
    if (!item?.result) return

    switch (item.result) {
      case 'unsure':
        return `${item.umls_name} - ${i18n.t('unsure.title')}`
      case 'vus':
        return `${item.umls_name} - VUS`
      case 'n':
        return `${item.umls_name}`
      default:
        return `${item.umls_name} - ${i18n.t('positive').lowercase}`
    }
  }

  return (
    <Text style={styles.sectionListSummaryDetailsText}>
      {renderGeneticResultText()}
    </Text>
  )
}

export default GeneticTestingListItem
