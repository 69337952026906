import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  clinicianLogoContainer: {
    alignSelf: 'center',
    borderWidth: 0.5,
    borderColor: 'rgba(255,255,255,0.2)',
    height: 240,
    width: 240,
  },
})

export default styles
