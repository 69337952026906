import Ionicons from '@expo/vector-icons/Ionicons'
import React from 'react'
import { useSelector } from 'react-redux'

function SectionHeaderButtons({ expandedSections, sectionTitle }) {
  const isSectionCollapsed = expandedSections?.has(sectionTitle)
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return isSectionCollapsed ? (
    <Ionicons
      name="chevron-down-outline"
      size={24}
      color={colorSettings?.member_section_title}
    />
  ) : (
    <Ionicons
      name="chevron-forward-outline"
      size={24}
      color={colorSettings?.member_section_title}
    />
  )
}

export default SectionHeaderButtons
