import { useSelector } from 'react-redux'
import { Text, View } from 'react-native'
import styles from '../main.styles'
import { i18n } from '$localization/config.js'

function CreatePasswordNavigationText({ navigateToPasswordScreen }) {
  const splashSettings = useSelector((state) => state.store.splashSettings)

  return (
    <View style={styles.createPasswordButtonContainer}>
      <Text
        style={[
          styles.createPasswordButtonText,
          { marginBottom: 10, fontWeight: '550' },
        ]}
      >
        {i18n.t('birthdate_confirmed_select_next_to_continue')?.default}
      </Text>
      <Text
        style={[
          styles.createPasswordButtonText,
          {
            color: splashSettings?.custom_welcome_createPasswordTextColor,
          },
        ]}
        onPress={navigateToPasswordScreen}
      >
        {i18n.t('dont_want_to_do_this_next_time')?.default}
      </Text>
      <Text
        style={[
          styles.createPasswordButtonText,
          {
            color: splashSettings?.custom_welcome_createPasswordTextColor,
          },
        ]}
        onPress={navigateToPasswordScreen}
      >
        {i18n.t('create_a_password')?.default}
      </Text>
    </View>
  )
}

export default CreatePasswordNavigationText
