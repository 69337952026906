import { StyleSheet, Platform } from 'react-native'

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    flex: 1.0,
  },
  contentContainer: {
    flex: 1.0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    backgroundColor: 'white',
    alignItems: 'center',
    height: Platform.OS === 'web' ? '60%' : 500,
    borderRadius: 10,
  },
  flexContainer: {
    height: '100%',
    width: '100%',
  },
  imageContainer: {
    flex: 3,
  },
  dismissButtonView: {
    position: 'absolute',
    top: 10,
    right: 5,
  },
  textContainer: {
    flex: 3,
    justifyContent: 'space-evenly',
  },
  buttonContainer: {
    flex: 2,
  },
  topButtonContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  leftButton: {
    width: '50%',
    borderTopWidth: 2,
    borderRightWidth: 2,
    borderRightColor: 'rgb(234,234,234)',
    borderTopColor: 'rgb(234,234,234)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightButton: {
    width: '50%',
    height: '100%',
    borderTopWidth: 2,
    borderTopColor: 'rgb(234,234,234)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomButtonContainer: {
    flex: 1,
    borderTopWidth: 2,
    borderTopColor: 'rgb(234,234,234)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 16,
    color: 'rgb(65,70,97)',
  },
})

export default styles
