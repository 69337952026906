import { Component } from 'react'

import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  KeyboardAvoidingView,
} from 'react-native'
import {
  Collapse,
  CollapseHeader,
  CollapseBody,
} from 'accordion-collapse-react-native'
import xs from '$assets/styles/style'

import Account from '$data_models/Account'
import Proband from '$data_models/Proband'

import { i18n } from '$localization/config.js'

{
  /* highligh button when selected | button select */
}
class SelectButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectOption: false,
    }
    this._onSelect = this._onSelect.bind(this)
  }

  _onSelect = () => {
    this.setState((previousState) => ({
      selectOption: !previousState.selectOption,
    }))

    if (this.props.tapped) {
      this.props.tapped(this.state.selectOption)
    }

    if (this.props.buttonName == 'OTHER') {
      this.props.params()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectOption !== prevState.selectOption) {
      if (this.props.add && this.props.remove) {
        if (this.state.selectOption) {
          this.props.add(this.props.buttonName)
        } else {
          this.props.remove(this.props.buttonName)
        }
      }
    }
  }

  render() {
    return (
      <TouchableOpacity
        style={[
          styles.buttonSelect,
          { width: '100%', marginBottom: 14 },
          this.state.selectOption
            ? {
                backgroundColor: 'rgba(245,245,245,.5)',
                borderColor: 'rgba(245,245,245,.3)',
              }
            : '',
        ]}
        onPress={this._onSelect}
        activeOpacity={1}
      >
        <Text style={styles.selectButtonText}>{this.props.buttonName}</Text>
      </TouchableOpacity>
    )
  }
}

export default class PersonalScreenDiagnosisD extends Component {
  account = new Account()

  proband = new Proband()

  constructor(props) {
    super(props)

    // Get Account and Proband data from previous screen
    this.account = this.props.route.params?.account
    this.proband = this.props.route.params?.proband

    this.state = {
      selected: false,
      toggleCollapse: false,
      others: '',
      genes: [],
    }
  }

  _onPress = () => {
    this.setState((previousState) => ({ selected: !previousState.selected }))
  }

  toggleCollapse = () => {
    this.setState((previousState) => ({
      toggleCollapse: !previousState.toggleCollapse,
    }))
  }

  nextButtonAction = () => {
    const { navigation } = this.props

    navigation.navigate('PersonalDetailsNavigator', {
      screen: 'PersonalEthnicity',
      params: {
        account: this.account,
        proband: this.proband,
      },
    })
  }

  onClearArray = () => {
    this.setState({ genes: [] })
  }

  addGenes = (item) => {
    const add = this.state.genes.concat(item)
    this.setState({ genes: add })
  }

  removeGenes = (removeItem) => {
    const { genes } = this.state
    const filtered = genes.filter((item) => item !== removeItem)
    this.setState({ genes: filtered })
  }

  render() {
    return (
      <View style={xs.mainContainer}>
        {/* Content Body */}
        <ScrollView contentContainerStyle={styles.contentContainer}>
          {/* main title */}
          <Text style={styles.titleText}>
            {i18n.t('is_there_a_known_mutation_throughout_your_family').default}
          </Text>
          <View style={styles.buttonContainerAdopted}>
            <TouchableOpacity
              style={[
                styles.buttonSelect,
                this.state.selected
                  ? {
                      backgroundColor: 'rgba(245,245,245,.5)',
                      borderColor: 'rgba(245,245,245,.3)',
                    }
                  : '',
              ]}
              onPress={this._onPress}
              activeOpacity={1}
            >
              <Text style={styles.selectButtonText}>
                {i18n.t('yes').default}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.buttonSelect,
                !this.state.selected
                  ? {
                      backgroundColor: 'rgba(245,245,245,.5)',
                      borderColor: 'rgba(245,245,245,.3)',
                    }
                  : '',
              ]}
              onPress={this._onPress}
              activeOpacity={1}
            >
              <Text style={styles.selectButtonText}>
                {i18n.t('no').default}
              </Text>
            </TouchableOpacity>
          </View>
          <Collapse
            style={{ width: '100%' }}
            diasbled
            isCollapsed={this.state.selected}
          >
            <CollapseHeader />
            <CollapseBody>
              {/* horizontal border */}
              <View style={[styles.horizontalBorder, { marginBottom: 15 }]} />
              <Text style={[styles.titleText, { fontSize: 18 }]}>
                {i18n.t('which_gene_is_it').default}
              </Text>
              <View
                style={[
                  styles.buttonContainerAdopted,
                  {
                    flexDirection: 'column',
                    marginBottom: 10,
                    justifyContent: 'center',
                  },
                ]}
              >
                <SelectButton
                  buttonName="APC"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="ATM"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="AXIN2"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="BARD1"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="BMPRIA"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="BRCA1"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="BRCA2"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="BRIP1"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="CDH1"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="CDKN2A"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="CHECK2"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="EPCAM"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="GALNT12"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="GREM1"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="MEN1"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="MLH1"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="MSH2"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="MSH3"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="MSH6"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="MUTYH"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="NBN"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="NF1"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="NF2"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="NTHL1"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="PALB2"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="PMS2"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="POLD1"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="POLE"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="PTEN"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="RAD51D"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="RB1"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="RET"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="SDHAF2"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="SDHB"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="SDHC"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="SDHD"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="SMAD4"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="STK11"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="TP53"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="TSC1"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="TSC2"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="VHL"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton
                  buttonName="WT1"
                  add={this.addGenes}
                  remove={this.removeGenes}
                />
                <SelectButton buttonName="Unsure" />
                <Collapse
                  diasbled
                  isCollapsed={this.state.toggleCollapse}
                  style={{ width: '100%' }}
                >
                  <CollapseHeader>
                    <SelectButton
                      buttonName="OTHER"
                      params={this.toggleCollapse}
                    />
                  </CollapseHeader>
                  <CollapseBody>
                    <TextInput
                      style={{
                        borderBottomColor: 'rgba(255,255,255,0.25)',
                        textAlign: 'center',
                        borderBottomWidth: 2,
                        fontFamily: 'montserrat',
                        fontSize: 20,
                        paddingVertical: 15,
                        marginBottom: 20,
                        color: '#fff',
                      }}
                      placeholder={i18n.t('please_specify').default}
                      placeholderTextColor="rgba(255,255,255,0.25)"
                      selectionColor="white"
                      autoCapitalize="none"
                      keyboardType="email-address"
                      onChangeText={(others) => (this.setState = { others })}
                    />
                  </CollapseBody>
                </Collapse>
              </View>
            </CollapseBody>
          </Collapse>
        </ScrollView>

        <KeyboardAvoidingView behavior="padding">
          <View style={xs.nxtBtnContainer}>
            <TouchableOpacity style={xs.nxtBtn} onPress={this.nextButtonAction}>
              <Text style={xs.nxtBtnText}>Done</Text>
            </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(155,89,182,1.0)',
    flex: 1.0,
  },
  contentContainer: {
    // backgroundColor: "red",
    marginHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerProgressBarContainer: {
    justifyContent: 'center',
    height: 10,
    marginTop: 10,
    marginBottom: 10,
    marginHorizontal: 18,
    borderRadius: 10,
    backgroundColor: 'rgba(155,89,182,1.0)',
    overflow: 'hidden',
  },
  headerProgressBar: {
    transform: [{ scaleX: 1.0 }, { scaleY: 5 }],
  },
  progressText: {
    justifyContent: 'center',
    color: 'rgba(255,255,255,0.25)',
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    textAlign: 'center',
  },
  titleText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    fontFamily: 'karla-bold',
    fontSize: 24,
    marginTop: 23,
    width: '100%',
  },
  itemButtonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemButton: {
    marginVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 340,
    height: 60,
    borderWidth: 2.0,
    borderColor: 'rgba(255,255,255,0.5)',
    borderRadius: 30,
  },
  itemButtonText: {
    // backgroundColor: "black",
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
    marginHorizontal: 18,
  },
  subItemButton: {
    marginVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 195,
    height: 40,
    borderWidth: 2.0,
    borderColor: 'rgba(255,255,255,0.5)',
    borderRadius: 20,
  },
  subItemButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-semibold',
    fontSize: 12,
    lineHeight: 22,
    textAlign: 'center',
    marginHorizontal: 18,
  },
  switchLabelText: {
    color: '#fff',
    fontFamily: 'karla-bold',
    fontSize: 18,
    textAlign: 'left',
  },
  nextButton: {
    justifyContent: 'center',
    backgroundColor: 'rgba(132,209,192,1.0)',
    height: '100%',
    width: '100%',
  },
  nextButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  buttonSelect: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    borderWidth: 1,
    borderColor: '#fff',
    height: 60,
    width: '45%',
  },
  selectButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  buttonContainerAdopted: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-around',
    paddingTop: 30,
  },
  controlButton: {
    width: 80,
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputField: {
    // backgroundColor: "black",
    width: 80,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'center',
  },
  horizontalBorder: {
    width: '120%',
    borderBottomWidth: 0.7,
    borderBottomColor: 'rgba(255,255,255,.3)',
    marginTop: 40,
    alignSelf: 'center',
  },
})
