import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  tabContainer: {
    maxWidth: 800,
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: 15,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    paddingHorizontal: 18,
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  sectionHeaderContainer: {
    width: '100%',
    height: 50,
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    paddingBottom: 5,
  },
  sectionHeaderText: (colorSettings) => ({
    fontSize: 18,
    fontFamily: 'karla-bold',
    color: colorSettings.member_section_title,
  }),
  sectionListItemContainer: {
    width: '100%',
    paddingVertical: 5,
  },
  sectionListItemHeaderContainer: {
    width: '100%',
    paddingHorizontal: 13,
  },
  sectionListItemHeaderText: (colorSettings) => ({
    fontSize: 16,
    fontFamily: 'karla-bold',
    color: colorSettings?.member_section_title,
  }),
  sectionListSummaryDetailsContainer: {
    width: '100%',
    paddingHorizontal: 25,
  },
  sectionListSummaryDetailsText: {
    fontSize: 14,
    color: 'rgb(102,102,102)',
    fontFamily: 'montserrat',
    paddingVertical: 3,
  },
})

export default styles
