import { Component } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Dimensions,
} from 'react-native'

import { i18n } from '$localization/config.js'

import styles from '$screens/relatives/_styles/tutorial.modal.styles.js'

export default class TutorialPage1 extends Component {
  parentClass = this.props.parentClass

  dismissButtonAction = this.parentClass?.dismissButtonAction ?? function () {}

  state = {
    currentWidth: Dimensions.get('window').width,
  }

  onChangeWidth = () => {
    this.setState({ currentWidth: Dimensions.get('window').width })
  }

  componentDidMount() {
    this.dimensionSubscription = Dimensions.addEventListener(
      'change',
      this.onChangeWidth
    )
  }

  componentWillUnmount() {
    this.dimensionSubscription?.remove()
  }

  render() {
    const { colorSettings } = this.props
    const buttonWidth = this.state.currentWidth < 450 ? 200 : 260

    return (
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        <Text style={styles.messageHeadText}>
          {i18n.t('dont_worry_if_you_dont_know_some_information')?.default}
        </Text>

        <Text style={styles.messageBodyText}>
          {`\u2022 ${
            i18n.t(
              'its_all_optional_and_you_can_always_enter_or_change_it_later'
            )?.default
          }`}
        </Text>

        <Text style={styles.messageBodyText}>
          {`\u2022 ${
            i18n.t(
              'after_you_add_your_family_members_you_will_have_the_chance_to_invite_them_to_fill_their_own_data'
            )?.default
          }`}
        </Text>

        <View style={styles.containerButton}>
          <TouchableOpacity
            style={[
              styles.nextButton,
              {
                backgroundColor: colorSettings?.btn_filled_enabled_1,
                borderColor: colorSettings?.btn_filled_border_1,
              },
            ]}
            onPress={() => this.dismissButtonAction()}
          >
            <Text
              style={[
                styles.nextButtonText,
                {
                  color: colorSettings?.btn_filled_text_1,
                  width: buttonWidth,
                },
              ]}
            >
              {i18n.t('continue')?.default}
            </Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    )
  }
}
