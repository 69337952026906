import * as actionTypes from './actionTypes'

const initialState = {
  surveys: [],
  clinicianSurveys: [],
  optionalSurveys: [],
  selectedSurvey: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.CLEAR_SURVEYS:
      /* Update this for every additional data in store */
      return {
        surveys: [],
        clinicianSurveys: [],
        optionalSurveys: [],
        selectedSurvey: null,
      }

    case actionTypes.SET_SURVEYS:
      return {
        ...state,
        surveys: action.data,
      }

    case actionTypes.SET_CLINICIAN_SURVEYS:
      return {
        ...state,
        clinicianSurveys: action.data,
      }

    case actionTypes.SET_OPTIONAL_SURVEYS:
      return {
        ...state,
        optionalSurveys: action.data,
      }

    case actionTypes.SET_SELECTED_SURVEY:
      return {
        ...state,
        selectedSurvey: action.data,
      }

    default:
      return state
  }
}
