import { Component } from 'react'

import { Text } from 'react-native'

import { connect } from 'react-redux'

class BottomTabBarTitle extends Component {
  render() {
    return (
      <Text
        style={{
          color: this.props.focused ? 'rgb(70,75,101)' : 'rgb(65,70,98)',
          fontSize: 12,
          textAlign: 'center',
        }}
      >
        {this.props.bottomTabBarTitles[this.props.tabBarName] || '{--}'}
      </Text>
    )
  }
}

function mapStateToProps(state) {
  const { store } = state
  return {
    account: store.account,
    proband: store.proband,
    isFirstLogin: store.isFirstLogin,
    bottomTabBarTitles: store.bottomTabBarTitles,
  }
}

export default connect(mapStateToProps, null)(BottomTabBarTitle)
