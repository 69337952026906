import { View, Text, TouchableOpacity } from 'react-native'
import Ionicons from 'react-native-vector-icons/Ionicons'
import MaterialIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import translateDisease from '$screens/personal_details/relative_gene_test/_utils/TextTranslation.js'
import styles from './Styles'
import { handleDisplayAgeFromAgeString } from '$screens/main/family/edit_profile/_utils/Utils'

function SelectedDiseaseList({
  item,
  colorSettings,
  toggleEdit,
  toggleRemove,
}) {
  return (
    <View style={styles.container}>
      <Text
        style={[
          styles.title,
          {
            color: colorSettings?.text_input_title_color_2 || 'black',
          },
        ]}
      >
        {`${translateDisease({ diseaseName: item.disease_name })}, ${
          handleDisplayAgeFromAgeString(item?.age_diagnosed_string) ??
          item.age_diagnosed ??
          '?'
        }`}
      </Text>
      <TouchableOpacity
        style={styles.editButton}
        onPress={() => toggleEdit(item.disease_id)}
      >
        <MaterialIcons
          name="pencil-outline"
          size={20}
          color={colorSettings?.text_input_label_color_2 || 'black'}
        />
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.deleteButton}
        onPress={() => toggleRemove(item.disease_id)}
      >
        <Ionicons
          name="trash"
          size={20}
          color={colorSettings?.text_input_label_color_2 || 'black'}
        />
      </TouchableOpacity>
    </View>
  )
}

// SelectedDiseaseList.propTypes = {
//   colorSettings: PropTypes.shape({
//     text_input_title_color_2: PropTypes.string,
//     text_input_label_color_2: PropTypes.string,
//   }),
//   item: PropTypes.shape({
//     disease_name: PropTypes.string,
//     disease_id: PropTypes.number,
//     age_diagnosed: PropTypes.string,
//   }),
//   toggleEdit: PropTypes.func,
//   toggleRemove: PropTypes.func,
// }

SelectedDiseaseList.defaultProps = {
  colorSettings: {},
  item: {},
  toggleEdit: () => {},
  toggleRemove: () => {},
}

export default SelectedDiseaseList
