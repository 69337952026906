import { Component } from 'react'
import { Text, ScrollView } from 'react-native'
import { i18n } from '$localization/config.js'

import styles from '$screens/relatives/_styles/tutorial.modal.styles.js'

export default class TutorialPage0 extends Component {
  parentClass = this.props.parentClass

  screenWidth = this.props.screenWidth

  render() {
    return (
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        <Text style={styles.messageHeadText}>
          {
            i18n.t(
              'enter_details_about_family_members_who_have_had_diseases_for_more_accurate_risk_assessment_dot'
            )?.default
          }
        </Text>

        <Text style={[styles.messageBodyText]}>
          {
            i18n.t(
              'you_can_enter_names_comma_ages_comma_and_health_information_by_tapping_on_their_profile_dot'
            )?.default
          }
        </Text>
      </ScrollView>
    )
  }
}
