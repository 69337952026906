import { View, Text } from 'react-native'
import { useSelector } from 'react-redux'
import styles from '../styles'
import { i18n } from '$localization/config.js'

function ShortWorkflowHeaderText({ customScreenText }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  const topScreenText = customScreenText?.topText
    ? customScreenText?.topText
    : i18n.t('how_many_of_each_type_of_family_member_do_you_have.default')

  const middleScreenText = customScreenText?.middleText
    ? customScreenText?.middleText
    : i18n.t('include_half-siblings_and_deceased_family_members.lowercase')

  return (
    <View style={styles.shortWorkflowHeaderContainer}>
      <View style={styles.shortWorkflowHeaderTextContainer}>
        <Text style={styles.shortWorkflowHeaderTextMain(colorSettings)}>
          {topScreenText}
        </Text>
      </View>
      <View style={styles.shortWorkflowHeaderTextContainer}>
        <Text style={styles.shortWorkflowHeaderTextSub(colorSettings)}>
          ({middleScreenText})
        </Text>
      </View>
    </View>
  )
}

export default ShortWorkflowHeaderText
