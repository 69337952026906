import { combineReducers } from 'redux'

import DefaultStore from '$redux/defaults/reducer.js'
import AccountStore from '$redux/account/reducer.js'
import ProbandStore from '$redux/proband/reducer.js'
import MemberProfileStore from '$redux/member_profile/reducer.js'
import RiskFactorStore from '$redux/risk_factor/reducer.js'
import ClinicianStore from '$redux/clinician/reducer.js'
import SurveyStore from '$redux/survey/reducer.js'
import DiseaseStore from '$redux/diseases/reducer.js'
import GeneTestingStore from '$redux/gene_testing/reducer.js'
import NotificationsStore from '$redux/notifications/reducer.js'
import CustomFlowRoutes from '$redux/customFlowRoutes/reducer.js'

const RootReducer = combineReducers({
  store: DefaultStore,
  accountStore: AccountStore,
  probandStore: ProbandStore,
  memberProfileStore: MemberProfileStore,
  riskFactorStore: RiskFactorStore,
  clinicianStore: ClinicianStore,
  surveyStore: SurveyStore,
  diseaseStore: DiseaseStore,
  geneTestingStore: GeneTestingStore,
  notificationsStore: NotificationsStore,
  customFlowRoutesStore: CustomFlowRoutes,
})

export default RootReducer
