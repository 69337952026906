import styles from '../main.styles'
import { Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { i18n } from '$localization/config.js'

const CustomValidateDobHeaderText = ({ patientData }) => {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  return (
    <View style={styles.validateBirthdateTextContainer}>
      <Text
        style={[
          styles.validateBirthdateText,
          { color: colorSettings?.text_input_placeholder_color_1 },
        ]}
      >
        {i18n.t('hi_to_be_sure_lets_confirm_your_birthdate.default', {
          name: patientData?.first_name,
        })}
      </Text>
    </View>
  )
}

export default CustomValidateDobHeaderText
