import { Pressable, Text, View } from 'react-native'
import React from 'react'
import { useSelector } from 'react-redux'
import { i18n } from '$localization/config.js'
import styles from '../styles'
import SectionHeaderButtons from './SectionHeaderButtons'

const WEB_SCALED_MAIN_CONTAINER = 'web_scaled_main_container'
const FAMILY_SECTION_TITLE = 'family_section_title'

function SectionHeader({ section, expandedSections, setExpandedSections }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const ZERO = 0

  if (!section || !section.data || section.data.length === ZERO) return null

  const sectionTitle = section.title ? section.title.toLowerCase() : ''
  const translatedTitle = i18n.t(sectionTitle)?.default
  const title = translatedTitle !== undefined ? translatedTitle : '--'

  const handleToggleCollapsibleSections = (_title) => {
    const sections = new Set(expandedSections)
    if (sections.has(_title)) {
      sections.delete(_title)
    } else {
      sections.add(_title)
    }

    setExpandedSections(sections)
  }

  return (
    <Pressable
      style={styles.sectionHeaderContainer(colorSettings)}
      onPress={() => handleToggleCollapsibleSections(sectionTitle)}
    >
      <View
        style={styles.sectionHeaderTitleContainer}
        nativeID={WEB_SCALED_MAIN_CONTAINER}
      >
        <Text
          nativeID={FAMILY_SECTION_TITLE}
          style={styles.sectionHeaderTitleText(colorSettings)}
        >
          {title}
        </Text>
      </View>
      <View style={styles.sectionHeaderTitleButtonContainer}>
        <SectionHeaderButtons
          expandedSections={expandedSections}
          sectionTitle={sectionTitle}
        />
      </View>
    </Pressable>
  )
}

export default SectionHeader
