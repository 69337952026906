function getRelationshipToProband(memberId, pedigreeData) {
  let relationship = null

  // Define a recursive function to search for the member_id
  function searchForMember(memberData) {
    if (memberData.member_id === memberId) {
      relationship = memberData.relationship_to_proband
      return
    }

    if (memberData.children) {
      memberData.children.forEach((child) => {
        searchForMember(child)
      })
    }

    if (memberData.partners) {
      memberData.partners.forEach((partner) => {
        searchForMember(partner)
      })
    }
  }

  // Iterate through all pedigree data
  pedigreeData.forEach((pedigree) => {
    pedigree.data.forEach((member) => {
      searchForMember(member)
    })
  })

  return relationship
}

export default getRelationshipToProband
