import ButtonWithListView from './ButtonMainWithList.view'

export const ButtonMainWithListLogic = ({
  colorSettings,
  buttonItem,
  onPress,
  data,
  showList = false,
  toggleEdit,
  toggleRemove,
}) => (
  <ButtonWithListView
    colorSettings={colorSettings}
    buttonItem={buttonItem}
    updateState={onPress}
    data={data}
    showList={showList}
    toggleEdit={toggleEdit}
    toggleRemove={toggleRemove}
  />
)
