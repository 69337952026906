import { StyleSheet } from "react-native";

const style = StyleSheet.create({
  container: {
    flex: 1,
    width: undefined,
    height: undefined
  }
});

export default style;
