export const setNavigationEventAction = async (
  shouldDelayOtherFamilyMembersTutorial,
  saveDelayOtherFamilyMembersTutorial,
  saveShouldShowOtherFamilyMembersTutorial,
  saveShouldReloadFamilyList,
  shouldReloadFamilyList,
  getFamilyMembers
) => {
  if (shouldDelayOtherFamilyMembersTutorial) {
    saveDelayOtherFamilyMembersTutorial(false)
    setTimeout(() => {
      if (saveShouldShowOtherFamilyMembersTutorial)
        saveShouldShowOtherFamilyMembersTutorial(true)
    }, 500)
  }

  if (shouldReloadFamilyList) {
    getFamilyMembers()
    saveShouldReloadFamilyList(false)
  }
}
