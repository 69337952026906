import { Text, TouchableOpacity } from 'react-native'
import styles from '../styles'

function CustomTabBarButton({ route, index, currentIndex, jumpTo }) {
  const isFocused = currentIndex === index

  return (
    <TouchableOpacity
      key={route.key}
      style={styles.customTabBarButton(isFocused)}
      onPress={() => jumpTo(route.key)}
    >
      <Text style={styles.customTabBarButtonText}>{route.title}</Text>
    </TouchableOpacity>
  )
}

export default CustomTabBarButton
