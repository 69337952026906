import React, { useState } from 'react'
import {
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  Keyboard,
} from 'react-native'

import { Sae } from 'react-native-textinput-effects'
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome'
import useStateWithCallback from 'use-state-with-callback'

export default function CustomTextInputView({
  changeValue,
  inputType,
  label,
  ...props
}) {
  const [imageURI, setImageURI] = useState(
    require('$assets/images/icon/eye-close/black.png')
  )
  const [secureTextEntry, setSecureTextEntry] = useState(true)
  const [inputValue, setInputValue] = useStateWithCallback(
    '',
    (currentValue) => {
      if (inputValue) changeValue(currentValue)
    }
  )

  const handleShowPassword = () => {
    const imageLocation = secureTextEntry
      ? require('$assets/images/icon/eye/black.png')
      : require('$assets/images/icon/eye-close/black.png')

    setSecureTextEntry((prevValue) => !prevValue)
    setImageURI(imageLocation)
  }

  const onChangeText = (val) => {
    if (!changeValue) {
      return
    }

    setInputValue(val, () => changeValue(inputValue))
  }

  const renderAccordingToType = (inputType) => {
    switch (inputType) {
      case 'password':
        return (
          <View style={styles.mainContainer}>
            <View style={{ width: '90%' }}>
              <Sae
                label={label}
                iconClass={FontAwesomeIcon}
                iconName=""
                iconColor=""
                inputPadding={8}
                style={styles.textInputContainer}
                labelStyle={styles.textInputLabel}
                // active border height
                borderHeight={2}
                // TextInput props
                autoCapitalize="none"
                autoCorrect={false}
                inputStyle={styles.textInputField}
                secureTextEntry={secureTextEntry}
                selectionColor="rgb(74,74,74)"
                onChangeText={(val) => {
                  onChangeText(val)
                }}
                onSubmitEditing={Keyboard.dismiss}
                {...props}
              />
            </View>
            <View style={{ width: '10%', paddingTop: 17 }}>
              <TouchableOpacity onPress={handleShowPassword}>
                <Image
                  style={{ height: 24, width: 24, resizeMode: 'contain' }}
                  source={imageURI}
                />
              </TouchableOpacity>
            </View>
          </View>
        )
      default:
        return (
          <View style={styles.mainContainer}>
            <View style={{ width: '100%' }}>
              <Sae
                label={label}
                iconClass={FontAwesomeIcon}
                iconName=""
                iconColor=""
                inputPadding={8}
                style={styles.textInputContainer}
                labelStyle={styles.textInputLabel}
                // active border height
                borderHeight={2}
                // TextInput props
                autoCapitalize="none"
                autoCorrect={false}
                inputStyle={styles.textInputField}
                selectionColor="rgb(74,74,74)"
                onChangeText={(text) => {
                  onChangeText(text)
                }}
                onSubmitEditing={Keyboard.dismiss}
                {...props}
              />
            </View>
          </View>
        )
    }
  }

  return <View>{renderAccordingToType(inputType)}</View>
}

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70%',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(74,74,74,0.5)',
  },
  textInputContainer: {
    width: '100%',
  },
  textInputLabel: {
    fontFamily: 'montserrat',
    fontSize: 18,
    color: 'rgba(74,74,74,0.5)',
    marginBottom: 10,
  },
  textInputField: {
    color: 'rgb(74,74,74)',
  },
})
