import { View, Text } from 'react-native'

import ButtonMain from '../button_main'
import MainList from '../main_list'

const ButtonMainWithListView = ({
  colorSettings,
  updateState,
  buttonItem,
  data,
  showList,
  toggleEdit,
  toggleRemove,
}) => {
  const ShowList = () => {
    if (showList) {
      return (
        <MainList
          colorSettings={colorSettings}
          data={data}
          toggleEdit={toggleEdit}
          toggleRemove={toggleRemove}
        />
      )
    }
    return <View />
  }

  return (
    <View>
      <ButtonMain
        colorSettings={colorSettings}
        onPress={(val) => updateState(val)}
        buttonItem={buttonItem}
      />
    </View>
  )
}

export default ButtonMainWithListView
