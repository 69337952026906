import { Component } from 'react'
import { View, Text, TextInput, TouchableOpacity } from 'react-native'

import { connect } from 'react-redux'

import { i18n } from '$localization/config.js'

import section_styles from '$screens/main/family/edit_profile/_styles/section.styles.js'

import { toggleVitalStatusAction } from '$redux/member_profile/actions.js'

const DECEASED = 'deceased'

class VitalStatusField extends Component {
  colorSettings = this.props.colorSettings

  parentController = this.props.parentController

  memberProfile = this.props.memberProfile

  profileData = this.memberProfile?.profileData

  state = {
    memberProfile: this.memberProfile,
    profileData: this.profileData,
    isDeceased: this.profileData?.deceased,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { memberProfile } = newProps
    const profileData = memberProfile?.profileData
    return {
      ...prevState,
      profileData,
      isDeceased: profileData?.deceased,
    }
  }

  toggleSwitchButton = (isActive) => {
    const { colorSettings } = this
    return {
      backgroundColor: isActive
        ? colorSettings?.vital_status_active_bg || 'white'
        : 'transparent',
      textColor: isActive
        ? colorSettings?.vital_status_active_text || 'rgba(74,74,74,1)'
        : colorSettings?.vital_status_inactive_text || 'white',
    }
  }

  toggleIsDeceased = (isDeceased) => {
    this.parentController?._updateState_(DECEASED, isDeceased)
  }

  render() {
    const { isDeceased } = this.state

    return (
      <View>
        <View style={section_styles.contentSeparatorLine} />
        <View style={section_styles.contentItem}>
          <View style={section_styles.contentItemLabelTextContainer}>
            <Text
              style={[
                section_styles.contentItemLabelText,
                { color: this.colorSettings?.text_input_label_color_1 },
              ]}
            >
              {i18n.t('status').default}
            </Text>
          </View>

          <View
            style={[
              section_styles.contentItemTextInputContainer,
              { alignItems: 'flex-end' },
            ]}
          >
            <View
              style={[
                section_styles.contentItemRectangularSwitchContainer,
                {
                  backgroundColor:
                    this.colorSettings?.vital_status_inactive_bg ||
                    'rgba(74,74,74,1)',
                },
              ]}
            >
              <View style={{ flexDirection: 'row', flex: 1.0 }}>
                <TouchableOpacity
                  onPress={() => this.toggleIsDeceased(false)}
                  activeOpacity={1.0}
                  style={[
                    section_styles.contentItemRectangularSwitchButton,
                    {
                      backgroundColor: this.toggleSwitchButton(!isDeceased)
                        ?.backgroundColor,
                    },
                  ]}
                >
                  <Text
                    style={[
                      section_styles.contentItemRectangularSwitchButtonText,
                      {
                        color: this.toggleSwitchButton(!isDeceased)?.textColor,
                      },
                    ]}
                  >
                    {i18n.t('alive').default}
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => this.toggleIsDeceased(true)}
                  activeOpacity={1.0}
                  style={[
                    section_styles.contentItemRectangularSwitchButton,
                    {
                      backgroundColor:
                        this.toggleSwitchButton(isDeceased)?.backgroundColor,
                    },
                  ]}
                >
                  <Text
                    style={[
                      section_styles.contentItemRectangularSwitchButtonText,
                      {
                        color: this.toggleSwitchButton(isDeceased)?.textColor,
                      },
                    ]}
                  >
                    {i18n.t('deceased').default}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </View>
    )
  }
}

export default VitalStatusField
