import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  bottomSheetButtonContainer: {
    width: '100%',
    height: 45,
    paddingHorizontal: 7,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  defaultBottomSheetButton: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
  },
  defaultBottomSheetButtonText: {
    fontSize: 15,
    fontWeight: '400',
  },
})

export default styles
