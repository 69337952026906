const existingAllSections = [
  'you_and_your_children',
  'brothers_and_sisters',
  'parents',
  'auntsuncles_dads_side',
  'auntsuncles_moms_side',
  'grandparents_dads_side',
  'grandparents_moms_side',
  'other_family_members',
]

export default existingAllSections
