import { i18n } from '$localization/config.js'

import { showDefaultAlert } from '$navigation/_utils/NavigationUtils.js'
import { isAgeValid } from '../../../../_utils/Utils'

export const isEmpty = (obj) => Object.keys(obj).length === 0

export const isNotValidAOD = (
  age,
  memberDiseases,
  triggerAlert = true,
  ageString
) => {
  if (isEmpty(memberDiseases)) return

  for (const disease of memberDiseases) {
    const hasAgeOfDiagnosisUnit = disease?.age_diagnosed_string?.length > 1

    for (const skipLogic of disease.skip_logics) {
      const AOD = Number(skipLogic.answer)

      if (Number.isInteger(AOD)) {
        if (AOD > age) {
          if (triggerAlert) {
            showDefaultAlert(
              i18n.t('oops').default,
              i18n.t(
                'the_age_of_diagnosis_cannot_be_greater_than_this_persons_current_ageage_of_death'
              ).default
            )
          }
          return true
        }
      }
    }
    if (!hasAgeOfDiagnosisUnit) {
      const defaultAgeValueAndUnit = `${disease.age_diagnosed} years`

      if (triggerAlert && !isAgeValid(ageString, defaultAgeValueAndUnit)) {
        showDefaultAlert(
          i18n.t('oops').default,
          i18n.t(
            'the_age_of_diagnosis_cannot_be_greater_than_this_persons_current_ageage_of_death'
          ).default
        )
        return true
      }
    } else if (!isAgeValid(ageString, disease?.age_diagnosed_string)) {
      if (triggerAlert) {
        showDefaultAlert(
          i18n.t('oops').default,
          i18n.t(
            'the_age_of_diagnosis_cannot_be_greater_than_this_persons_current_ageage_of_death'
          ).default
        )
        return true
      }
    }
  }
  return false
}
