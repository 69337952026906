import { StyleSheet } from 'react-native'

const greyishBlack = 'rgba(74,74,74,0.5)'
const lightGreen = 'rgba(132,209,192,1)'
const white = '#fff'
const black = 'black'

export default StyleSheet.create({
  container: {
    backgroundColor: white,
    width: '100%',
    flex: 1.0,
  },
  contentContainer: {
    flex: 1.0,
    width: '100%',
  },
  content: {
    justifyContent: 'center',
    flex: 1.0,
  },
  textInputField: {
    fontFamily: 'montserrat',
    fontSize: 18,
    fontWeight: 'normal',
    color: black,
  },
  helperTextContainer: {
    marginVertical: 10,
  },
  passwordHelperText: {
    color: greyishBlack,
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'left',
  },
  submitButton: {
    justifyContent: 'center',
    backgroundColor: lightGreen,
    width: '100%',
    height: '100%',
    borderWidth: 1.0,
  },
  submitButtonText: {
    color: white,
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  keyboardAvoiding: {
    flex: 1,
  },
  scrollViewContainer: {
    flex: 1,
    alignItems: 'center',
    width: '100%',
  },
  textInputPasswordContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
  },
  textInputPassword: {
    paddingRight: 24,
  },
  showPasswordContainer: {
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 1,
  },
  showPasswordImage: {
    height: 24,
    width: 24,
    resizeMode: 'contain',
  },
  signUpContainer: {
    flex: 1,
    width: '100%',
    maxWidth: 500,
    paddingHorizontal: 50,
    marginTop: 20,
  },
  nextButtonContainer: {
    height: 60,
    width: '100%',
  },
})
