import { StyleSheet } from 'react-native'

const grey = 'rgb(74, 74, 74)'
const fadedWhite = 'rgb(204, 204, 204)'

export default StyleSheet.create({
  datePickerContainer: {
    alignItems: 'center',
    flex: 1,
    width: '100%',
  },
  datePickerButton: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  yearStyle: {
    borderStyle: 'solid',
    borderColor: fadedWhite,
    borderRadius: 3,
    marginRight: 10,
    marginTop: 7,
    height: 34,
    width: '100%',
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    color: grey,
    flex: 1,
    alignSelf: 'center',
  },
  monthStyle: {
    borderStyle: 'solid',
    borderColor: fadedWhite,
    borderRadius: 3,
    marginRight: 10,
    marginTop: 7,
    height: 34,
    width: '100%',
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    color: grey,
    flex: 1,
  },
  dayStyle: {
    borderStyle: 'solid',
    borderColor: fadedWhite,
    borderRadius: 3,
    marginTop: 7,
    height: 34,
    fontFamily: 'montserrat-medium',
    fontSize: 15,
    color: grey,
    flex: 1,
  },
  dateContainer: {
    width: '100%',
    flexDirection: 'row',
  },
  inputField: {
    // backgroundColor: "black",
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    // color: "white",
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'center',
    height: 50,
    borderBottomWidth: 0.5,
    alignSelf: 'center',
  },
  mainContainer: {
    width: '100%',
  },
})
