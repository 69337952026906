import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    // backgroundColor: "rgba(155,89,182,1.0)",
    flex: 1.0,
  },
  contentContainer: {
    paddingHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerProgressBarContainer: {
    // backgroundColor: "rgba(155,89,182,1.0)",
    justifyContent: 'center',
    height: 10,
    marginTop: 10,
    marginBottom: 10,
    marginHorizontal: 18,
    borderRadius: 10,
    overflow: 'hidden',
  },
  headerProgressBar: {
    transform: [{ scaleX: 1.0 }, { scaleY: 5 }],
  },
  progressText: {
    // color: "rgba(255,255,255,0.25)",
    justifyContent: 'center',
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    textAlign: 'center',
  },
  titleText: {
    // color: "white",
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily: 'karla-bold',
    fontSize: 24,
    marginTop: 23,
    marginHorizontal: 0,
  },
  itemButtonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemButton: {
    marginVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 340,
    height: 60,
    borderWidth: 2.0,
    borderRadius: 30,
    // borderColor: "rgba(255,255,255,0.5)",
  },
  itemButtonText: {
    // backgroundColor: "black",
    // color: "#fff",
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
    marginHorizontal: 18,
  },
  subItemButton: {
    marginVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 195,
    height: 40,
    borderWidth: 2.0,
    // borderColor: "rgba(255,255,255,0.5)",
    borderRadius: 20,
  },
  subItemButtonText: {
    // color: "#fff",
    fontFamily: 'montserrat-semibold',
    fontSize: 12,
    lineHeight: 22,
    textAlign: 'center',
    marginHorizontal: 18,
  },
  switchLabelText: {
    // color: "#fff",
    fontFamily: 'karla-bold',
    fontSize: 18,
    textAlign: 'left',
  },
  nextButton: {
    // backgroundColor: "rgba(132,209,192,1.0)",
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  nextButtonText: {
    // color: "#fff",
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  buttonSelect: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    borderWidth: 1,
    // borderColor: '#fff',
    height: 60,
    width: '45%',
  },
  selectButtonText: {
    // color: "#fff",
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  buttonContainerAdopted: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-around',
    paddingTop: 30,
  },
  controlButton: {
    width: 80,
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputField: {
    // backgroundColor: "black",
    // color: "white",
    width: 80,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'center',
  },
  horizontalBorder: {
    width: '120%',
    borderBottomWidth: 0.7,
    // borderBottomColor: 'rgba(255,255,255,.3)',
    marginTop: 32,
    alignSelf: 'center',
  },
})
