import * as actionTypes from './actionTypes'

const resetDiseasesStore = () => ({
  type: actionTypes.RESET_DISEASES_STORE,
})

const setSelectedDiseaseDetails = (data) => ({
  type: actionTypes.SET_SELECTED_DISEASE_DETAILS,
  data,
})

const setDiseaseProband = (data) => ({
  type: actionTypes.SET_DISEASE_PROBAND,
  data,
})

const setIndividualDisease = (data) => ({
  type: actionTypes.SET_INDIVIDUAL_DISEASE,
  data,
})

const setScreenWhereToNavigate = (fromMainScreen) => ({
  type: actionTypes.SET_SCREEN_WHERE_TO_NAVIGATE,
  data: fromMainScreen,
})

const setSkipLogic = (skipLogic) => ({
  type: actionTypes.SET_SKIP_LOGIC,
  data: skipLogic,
})

const setMemberDisease = (diseases) => ({
  type: actionTypes.SET_CONVERTED_MEMBER_DISEASE,
  data: diseases,
})

const setMemberSkipLogic = (diseases) => ({
  type: actionTypes.SET_CONVERTED_MEMBER_SKIP_LOGIC,
  data: diseases,
})

const setFromOnboardingDiseases = (data) => ({
  type: actionTypes.SET_FROM_ONBOARDING_DISEASES,
  data,
})

const setTypeByOrg = (data) => ({
  type: actionTypes.SET_TYPE_BY_ORG,
  data,
})

const setIsSingleCategoryDisease = (data) => ({
  type: actionTypes.SET_IS_SINGLE_CATEGORY_DISEASE,
  data,
})

export {
  setSelectedDiseaseDetails,
  setDiseaseProband,
  setIndividualDisease,
  setScreenWhereToNavigate,
  setSkipLogic,
  setMemberDisease,
  setMemberSkipLogic,
  setFromOnboardingDiseases,
  resetDiseasesStore,
  setTypeByOrg,
  setIsSingleCategoryDisease,
}
