import * as actionTypes from './actionTypes'

import MemberProfile from '$data_models/MemberProfile.js'

const initialState = {
  memberProfile: new MemberProfile(),
  currentMember: null, // Family list, member_id, relationship
  didAddNewMember: false,
  addNewMemberPayload: [],
  addNewTwinsPayload: null,
  sharingSettingMember: null,
  showInviteHelperModal: true,
  invitedMember: null,

  /* Reloaders */
  shouldSectionalReload: false,
  shouldReloadParents: false,
  shouldReloadParentsBloodRelation: false,
  shouldReloadTwins: false,
  shouldReloadInviteStatus: false,
  shouldReloadDiseases: false,
  shouldReloadGeneticTestings: false,
  /* Reloaders */
}

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_MEMBER_PROFILE:
      return {
        ...state,
        memberProfile: action.data,
      }

    case actionTypes.SET_NEW_PARENTS_FOR_MEMBER:
      var relationshipData = state.memberProfile?.relationshipData
      var { mother, father } = action.data
      return {
        ...state,
        memberProfile: {
          ...state.memberProfile,
          relationshipData: {
            ...relationshipData,
            mother,
            father,
          },
        },
      }

    case actionTypes.CLEAR_MEMBER_PROFILE:
      return {
        ...state,
        memberProfile: {},
      }

    case actionTypes.TOGGLE_VITAL_STATUS:
      return {
        memberProfile: {
          ...state.memberProfile,
          profileData: {
            ...state.memberProfile.profileData,
            deceased: action.data,
          },
        },
      }

    case actionTypes.STORE_AGE_OF_DEATH:
      return {
        memberProfile: {
          ...state.memberProfile,
          profileData: {
            ...state.memberProfile.profileData,
            ageOfDeath: action.data,
          },
        },
      }

    case actionTypes.SET_CURRENT_MEMBER:
      return {
        ...state,
        currentMember: action.data,
      }

    case actionTypes.CLEAR_CURRENT_MEMBER:
      return {
        ...state,
        currentMember: null,
      }

    case actionTypes.SET_DID_ADD_NEW_MEMBER:
      return {
        ...state,
        didAddNewMember: action.data,
      }

    case actionTypes.SET_ADD_NEW_MEMBER_PAYLOAD:
      return {
        ...state,
        addNewMemberPayload: action.data,
      }

    case actionTypes.SET_ADD_TWINS_PAYLOAD:
      return {
        ...state,
        addNewTwinsPayload: action.data,
      }

    case actionTypes.SET_SHARING_SETTING_MEMBER:
      return {
        ...state,
        sharingSettingMember: action.data,
      }

    case actionTypes.SHOW_INVITE_HELPER_MODAL:
      return {
        ...state,
        showInviteHelperModal: action.data,
      }

    case actionTypes.SET_INVITED_MEMBER:
      return {
        ...state,
        invitedMember: action.data,
      }

    case actionTypes.CLEAR_SHARING_SETTING_MEMBER:
      return {
        ...state,
        sharingSettingMember: null,
      }

    /* Reloaders */
    case actionTypes.SET_RELOADERS_TO_DEFAULT:
      return {
        ...state,
        shouldSectionalReload: false,
        shouldReloadParents: false,
        shouldReloadParentsBloodRelation: false,
        shouldReloadTwins: false,
        shouldReloadInviteStatus: false,
        shouldReloadDiseases: false,
        shouldReloadGeneticTestings: false,
      }

    case actionTypes.SET_SHOULD_SECTIONAL_RELOAD:
      return {
        ...state,
        shouldSectionalReload: action.data,
      }

    case actionTypes.SET_SHOULD_RELOAD_PARENTS:
      return {
        ...state,
        shouldReloadParents: action.data,
      }

    case actionTypes.SET_SHOULD_RELOAD_PARENTS_BLOOD_RELATION:
      return {
        ...state,
        shouldReloadParentsBloodRelation: action.data,
      }

    case actionTypes.SET_SHOULD_RELOAD_TWINS:
      return {
        ...state,
        shouldReloadTwins: action.data,
      }

    case actionTypes.SET_SHOULD_RELOAD_INVITE_STATUS:
      return {
        ...state,
        shouldReloadInviteStatus: action.data,
      }

    case actionTypes.SET_SHOULD_RELOAD_DISEASES:
      return {
        ...state,
        shouldReloadDiseases: action.data,
      }

    case actionTypes.SET_SHOULD_RELOAD_GENETIC_TESTINGS:
      return {
        ...state,
        shouldReloadGeneticTestings: action.data,
      }
    /* Reloaders */

    default:
      return state
  }
}
