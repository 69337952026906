import { Component } from 'react'
import { View, Text, Modal, TouchableOpacity, StyleSheet } from 'react-native'

import { i18n } from '$localization/config.js'

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    position: 'absolute',
    height: '100%',
    width: '100%',
    padding: 16,
  },
  content: {
    // backgroundColor: "#ccc",
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    overflow: 'hidden',
  },
  modalTitleContainer: {
    backgroundColor: 'white',
    width: '100%',
    padding: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitle: {
    color: 'rgba(74,74,74,1.0)',
    fontFamily: 'karla-bold',
    fontSize: 24,
    textAlign: 'center',
  },
  modalContentContainer: {
    backgroundColor: 'white',
    width: '100%',
    paddingBottom: 16,
    justifyContent: 'center',
  },
  contentText: {
    color: 'rgba(74,74,74,1.0)',
    fontFamily: 'montserrat',
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
  },
  modalFooterContainer: {
    backgroundColor: 'white',
    width: '100%',
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
  normalButton: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 60,
    borderTopColor: 'rgba(234,234,234,1.0)',
    borderTopWidth: 2.0,
  },
  normalButtonText: {
    color: 'rgba(117,84,136,1.0)',
    fontFamily: 'montserrat',
    fontSize: 14,
    textAlign: 'center',
  },
})

class AlertModal extends Component {
  visible = this.props.visible

  toolTipContent = this.props.toolTipContent

  toggleTooltip = this.props.toggleTooltip

  errorType = this.props.errorType

  contentMessage = null

  constructor(props) {
    super(props)

    // Initialize state
    this.state = {
      visible: this.props.visible,
      toolTipContent: this.props.toolTipContent,
      toggleTooltip: this.props.toggleTooltip,
      errorType: this.props.errorType,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const newState = prevState

    newState.visible = newProps.visible
    newState.toolTipContent = newProps.toolTipContent
    newState.toggleTooltip = newProps.toggleTooltip
    newState.errorType = newProps.errorType
    return newState
  }

  render() {
    if (!this.errorType) {
      return null
    }

    if (this.errorType === 'missing_pw') {
      this.contentMessage = (
        <View style={{ width: '100%' }}>
          <Text style={styles.contentText}>
            {i18n.t('please_complete_the_fields').default}
          </Text>
        </View>
      )
    } else if (this.errorType === 'mismatch_pw') {
      this.contentMessage = (
        <View style={{ width: '100%' }}>
          <Text style={styles.contentText}>
            {i18n.t('new_password_does_not_match').default}
          </Text>
        </View>
      )
    } else if (this.errorType === 'old_pw') {
      this.contentMessage = (
        <View style={{ width: '100%' }}>
          <Text style={styles.contentText}>
            {i18n.t('your_current_password_is_incorrect').default}
          </Text>
        </View>
      )
    } else if (this.errorType === 'default') {
      this.contentMessage = []
      this.contentMessage.push(
        <View style={{ paddingLeft: 18, width: '100%' }}>
          <Text style={[styles.contentText, { textAlign: 'left' }]}>
            {i18n.t('password_must_contain').default}
          </Text>
        </View>
      )
      this.contentMessage.push(
        <View style={{ paddingHorizontal: 24, width: '100%' }}>
          <Text style={[styles.contentText, { textAlign: 'left' }]}>
            <Text>{i18n.t('at_least_8_characters').default}</Text>
            {'\n'}
            <Text>{`- ${i18n.t('one_uppercase_letter').default}`}</Text>
            {'\n'}
            <Text>{`- ${i18n.t('one_lowercase_letter').default}`}</Text>
            {'\n'}
            <Text>{`- ${i18n.t('one_number').default}`}</Text>
            {'\n'}
            <Text>{`- ${i18n.t('one_special_character').default}`}</Text>
            {'\n'}
            <Text>{`- ${i18n.t('not_too_common').default}`}</Text>
            {'\n'}
            <Text>{`- ${
              i18n.t(
                'and_shouldnt_be_too_similar_to_your_other_personal_information'
              ).default
            }`}</Text>
          </Text>
        </View>
      )
    }

    return (
      <Modal animationType="fade" transparent visible={this.state.visible}>
        <View style={styles.container}>
          <View style={styles.content}>
            <View style={styles.modalContainer}>
              <View style={styles.modalTitleContainer}>
                <Text style={styles.modalTitle}>
                  {i18n.t('password').default}
                </Text>
              </View>

              <View style={styles.modalContentContainer}>
                {this.contentMessage}
              </View>

              <View style={styles.modalFooterContainer}>
                <TouchableOpacity
                  style={styles.normalButton}
                  activeOpacity={0.5}
                  onPress={() => this.props.toggleTooltip()}
                >
                  <Text style={styles.normalButtonText}>
                    {i18n.t('okay').default}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    )
  }
}

export default AlertModal
