import { Platform } from 'react-native'
import { i18n } from '$localization/config.js'

const biometricsTypeName = (biometricsType) => {
  let biometricsName

  switch (biometricsType) {
    case 'fingerprint':
      if (Platform.OS === 'android') {
        biometricsName = i18n.t('biometrics').default
        break
      }
      biometricsName = 'Touch ID'
      break
    case 'face-recognition':
      if (Platform.OS === 'android') {
        biometricsName = i18n.t('biometrics').default
        break
      }
      biometricsName = 'Face ID'
      break
    case 'eye-outline':
      biometricsName = i18n.t('iris_recognition').default
      break
    default:
      biometricsName = i18n.t('biometrics').default
  }
  return biometricsName
}

export default biometricsTypeName
