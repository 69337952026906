import * as actionTypes from './actionTypes'

const initialState = {
  geneList: null,
  positiveTest: [],
  negativeTest: [],
  fromOnboarding: true,
  isGeneticallyTested: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.RESET_GENE_TESTING_STORE:
      /* Update this for every additional data in store */
      return {
        ...state,
        geneList: null,
        positiveTest: [],
        negativeTest: [],
        fromOnboarding: true,
      }

    case actionTypes.SET_POSITIVE_TEST:
      return {
        ...state,
        positiveTest: action.data,
      }

    case actionTypes.SET_NEGATIVE_TEST:
      return {
        ...state,
        negativeTest: action.data,
      }

    case actionTypes.SET_GENE_LIST:
      return {
        ...state,
        geneList: action.data,
      }

    case actionTypes.RESET_POSITIVE_TEST:
      return {
        ...state,
        positiveTest: [],
      }

    case actionTypes.SET_FROM_ONBOARDING_GENE_TEST:
      return {
        ...state,
        fromOnboarding: action.data,
      }

    case actionTypes.SET_GENETICALLY_TESTED:
      return {
        ...state,
        isGeneticallyTested: action.data,
      }
    default: {
      return state
    }
  }
}
