import { View, Text, Image } from 'react-native'

import { i18n } from '$localization/config.js'
import { hideRisk } from '../constans/constans'
import styles from '../static/main.styles'

function DummyFamilyTab({ screenWidth }) {
  const familyTabPositioning = () => {
    const styleA = {
      left: screenWidth / 4 + 14,
      right: screenWidth / 2 + 14,
    }
    const styleB = {
      width: screenWidth / 4 + 14,
    }

    return hideRisk ? styleB : styleA
  }

  return (
    <View>
      <View style={styles.dummyFamilyTabContainer}>
        <View
          style={[
            styles.dummyFamilyTabContentContainer('rgba(255,255,255,1.0)'),
            familyTabPositioning(),
          ]}
        >
          <View style={styles.dummyFamilyTabContent}>
            <Image
              source={require('$assets/images/icon/Tab_bar/Icons/family/Active.png')}
              style={styles.dummyFamilyTabContentImage}
            />
            <Text style={styles.dummyFamilyTabContentText('rgb(65,70,98)')}>
              {i18n.t('family').title}
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}

export default DummyFamilyTab
