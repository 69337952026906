import { useState } from 'react'
import ToggleWithInputView from './ToggleWithInput.view'

const ToggleWithInputLogic = ({
  colorSettings,
  buttonTitle, // button
  inputTitle, // input
  inputAge, // input
  setToggle = false, // button
  onPress, // button
  returnAge, // input
  memberAge, // input
  memberAgeOfDeath,
}) => {
  const [isToggled, setIsToggled] = useState(setToggle)

  const setOnPress = () => {
    onPress(!isToggled)
    setIsToggled((val) => !val)
  }

  return (
    <ToggleWithInputView
      colorSettings={colorSettings}
      buttonTitle={buttonTitle} // button
      // currentState={val => setIsToggled(val)}
      inputAge={inputAge} // input
      setOnPress={setOnPress} // button
      inputTitle={inputTitle} // input
      setToggle={isToggled} // button
      getCurrentAge={returnAge}
      memberAge={memberAge}
      memberAgeOfDeath={memberAgeOfDeath}
    />
  )
}

export default ToggleWithInputLogic
