export const handleCollapseSectionsWhileSearchingMember = (
  title,
  setExpandedSections
) =>
  setExpandedSections((prevState) => {
    const newExpandedSections = new Set(prevState.expandedSections)

    if (newExpandedSections.has(title)) return newExpandedSections

    newExpandedSections.add(title)

    return newExpandedSections
  })
