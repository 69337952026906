import { TextInput, View } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons'
import { useSelector } from 'react-redux'
import { i18n } from '$localization/config.js'
import styles from '../styles'

function MemberSearchComponent({ onChangeText }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <View style={styles.memberSearchContainer(colorSettings)}>
      <View style={styles.memberSearchBarTextInputContainer}>
        <View style={styles.memberSearchBarInputIconContainer}>
          <Ionicons
            size={20}
            name="search"
            color={colorSettings?.text_input_placeholder_color_1}
          />
        </View>
        <TextInput
          style={styles.memberSearchBarTextInput(colorSettings)}
          placeholder={`${i18n.t('search').default}...`}
          placeholderTextColor={colorSettings?.text_input_placeholder_color_1}
          clearButtonMode="always"
          returnKeyType="done"
          onChangeText={(value) => onChangeText(value)}
        />
      </View>
    </View>
  )
}

export default MemberSearchComponent
