import { showDefaultAlert } from '$navigation/_utils/NavigationUtils'
import { i18n } from '$localization/config.js'

const errorResponseHandler = (response, saveError) => {
  const { status, error } = response
  switch (status) {
    case 400:
      showDefaultAlert(
        i18n.t('oops')?.default,
        i18n.t('looks_like_something_went_wrong')?.default
      )
      break
    case 404:
      showDefaultAlert(
        i18n.t('oops')?.default,
        i18n.t('looks_like_something_went_wrong')?.default
      )
      break
    default:
      saveError({
        isShown: true,
        status,
        message: error,
      })
  }
}

const resizeClinicianLogo = ({ width, height }) => {
  switch (true) {
    case width > 550:
      return 340
    case width < 550 && height < 650:
      return 180
    case width < 550:
      return 240
    default:
      return 220
  }
}

const resizeFamgenixLogo = (dimensions) => {
  return dimensions.width <= 500
    ? { width: 120, height: 40 }
    : { width: 200, height: 100 }
}

export { errorResponseHandler, resizeClinicianLogo, resizeFamgenixLogo }
