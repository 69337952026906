import { i18n } from '$localization/config.js'

const translateDisease = ({ diseaseName = '', isLowerCased = false }) => {
  if (diseaseName) {
    const selectedDiseaseName = diseaseName
      .toLowerCase()
      .replace(/\//g, ' ')
      .replace(/[^\w-\s]/gi, '')
      .replace(/\s/g, '_')

    const lowerCaseTitle = i18n.t(selectedDiseaseName).lowercase || diseaseName
    const defaultTitle = i18n.t(selectedDiseaseName).default || diseaseName

    return isLowerCased ? lowerCaseTitle : defaultTitle
  }
  return diseaseName
}

export default translateDisease
