import * as actionTypes from './actionTypes'

const clearSurveysAction = () => ({
  type: actionTypes.CLEAR_SURVEYS,
})

const setSurveysAction = (data) => ({
  type: actionTypes.SET_SURVEYS,
  data,
})

const setClinicianSurveysAction = (data) => ({
  type: actionTypes.SET_CLINICIAN_SURVEYS,
  data,
})

const setOptionalSurveysAction = (data) => ({
  type: actionTypes.SET_OPTIONAL_SURVEYS,
  data,
})

const setSelectedSurveyAction = (data) => ({
  type: actionTypes.SET_SELECTED_SURVEY,
  data,
})

export {
  clearSurveysAction,
  setSurveysAction,
  setClinicianSurveysAction,
  setOptionalSurveysAction,
  setSelectedSurveyAction,
}
