import * as actionTypes from './actionTypes'

const initialState = {
  proband: null,
  probandProfile: null,
  basicFamilyMembers: null,
  familySummaryData: null,
  shouldReloadFamilyList: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_PROBAND:
      return {
        ...state,
        proband: action.data,
      }

    case actionTypes.CLEAR_PROBAND:
      return {
        ...state,
        proband: null,
      }

    case actionTypes.SET_PROBAND_PROFILE:
      return {
        ...state,
        probandProfile: action.data,
      }

    case actionTypes.CLEAR_PROBAND_PROFILE:
      return {
        ...state,
        probandProfile: null,
      }

    case actionTypes.SET_PROBAND_ID:
      return {
        ...state,
        proband: {
          ...state.proband,
          probandID: action.data,
        },
      }

    case actionTypes.SET_PROBAND_FAMILY_ID:
      return {
        ...state,
        proband: {
          ...state.proband,
          familyID: action.data,
        },
      }

    case actionTypes.SET_PROBAND_CLINICIAN_CODE:
      return {
        ...state,
        proband: {
          ...state.proband,
          clinicianCode: action.data,
        },
      }

    case actionTypes.SET_PROBAND_BIOLOGICAL_GENDER:
      return {
        ...state,
        proband: {
          ...state.proband,
          biologicalGender: action.data,
        },
      }

    case actionTypes.SET_PROBAND_PRONOUN:
      return {
        ...state,
        proband: {
          ...state.proband,
          pronoun: action.data,
        },
      }

    case actionTypes.SET_PROBAND_GENDER_IDENTITY:
      return {
        ...state,
        proband: {
          ...state.proband,
          genderIdentity: action.data,
        },
      }

    case actionTypes.SET_BASIC_FAMILY_MEMBERS:
      return {
        ...state,
        basicFamilyMembers: action.data,
      }

    case actionTypes.SET_FAMILY_SUMMARY_DATA:
      return {
        ...state,
        familySummaryData: action.data,
      }

    case actionTypes.CLEAR_BASIC_FAMILY_MEMBERS:
      return {
        ...state,
        basicFamilyMembers: null,
      }

    case actionTypes.SET_SHOULD_RELOAD_FAMILY_LIST:
      return {
        ...state,
        shouldReloadFamilyList: action.data,
      }

    case actionTypes.SET_PROBAND_GENETIC_TESTING:
      return {
        ...state,
        proband: {
          ...state.proband,
          memberGeneticTesting: action.data,
        },
      }

    case actionTypes.CLEAR_MEMBER_GENETIC_TESTING:
      return {
        ...state,
        proband: {
          ...state.proband,
          memberGeneticTesting: null,
        },
      }

    case actionTypes.SET_PROBAND_ETHNICITY:
      return {
        ...state,
        proband: {
          ...state.proband,
          ethnicity: action.data,
        },
      }

    case actionTypes.CLEAR_PROBAND_ETHNICITY:
      return {
        ...state,
        proband: {
          ...state.proband,
          ethnicity: null,
        },
      }

    case actionTypes.SET_PROBAND_ASHKENAZI:
      return {
        ...state,
        proband: {
          ...state.proband,
          ashkenazi: action.data,
        },
      }

    case actionTypes.CLEAR_PROBAND_ASHKENAZI:
      return {
        ...state,
        proband: {
          ...state.proband,
          ashkenazi: null,
        },
      }

    case actionTypes.SET_PROBAND_RACE:
      return {
        ...state,
        proband: {
          ...state.proband,
          race: action.data,
        },
      }

    case actionTypes.CLEAR_PROBAND_RACE:
      return {
        ...state,
        proband: {
          ...state.proband,
          race: null,
        },
      }

    case actionTypes.SET_PROBAND_DISEASE:
      return {
        ...state,
        proband: {
          ...state.proband,
          memberDisease: action.data,
        },
      }

    case actionTypes.CLEAR_PROBAND_DISEASE:
      return {
        ...state,
        proband: {
          ...state.proband,
          memberDisease: null,
        },
      }

    case actionTypes.SET_PROBAND_SKIP_LOGIC:
      return {
        ...state,
        proband: {
          ...state.proband,
          memberSkipLogic: action.data,
        },
      }

    case actionTypes.CLEAR_PROBAND_SKIP_LOGIC:
      return {
        ...state,
        proband: {
          ...state.proband,
          memberSkipLogic: null,
        },
      }

    case actionTypes.CLEAR_OTHER_PROBAND_DETAILS:
      return {
        ...state,
        proband: {
          ...state.proband,
          patientID: '',
          patientAdopted: false,
          fatherAdopted: false,
          motherAdopted: false,
        },
      }

    case actionTypes.SET_PROBAND_IMMEDIATE_FAMILY:
      const {
        familyID,
        probandID,
        motherID,
        fatherID,
        patGrandfatherID,
        patGrandmotherID,
        matGrandfatherID,
        matGrandmotherID,
      } = action.data
      return {
        ...state,
        proband: {
          ...state.proband,
          familyID,
          probandID,
          motherID,
          fatherID,
          patGrandfatherID,
          patGrandmotherID,
          matGrandfatherID,
          matGrandmotherID,
        },
      }

    default:
      return state
  }
}
