export default class AccountGeneTesting {
  tempToken = {
    accessToken: 'OlIVicAkxtcj40hfH1kV6is8rJsnoy',
  }

  memberdetails = {
    proband_id: 21,
    member_id: 21,
  }
}
