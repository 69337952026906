import { Component } from 'react'
import { SafeAreaView, Platform } from 'react-native'

import { WebView } from 'react-native-webview'
import PDFReader from 'rn-pdf-reader-js'

class SubmitIdeas extends Component {
  constructor(props) {
    super(props)

    this.site =
      'https://famhis.atlassian.net/servicedesk/customer/portal/2/group/3/create/15'

    this.state = {
      viewOnWeb: true,
    }
  }

  renderOnAndroid = () => {
    if (this.state.viewOnWeb) {
      return <WebView style={{ flex: 1 }} source={{ uri: this.site }} />
    }

    return <PDFReader source={{ uri: this.site }} />
  }

  renderOnIOS = () => (
    <WebView style={{ flex: 1 }} source={{ uri: this.site }} />
  )

  renderViews = () => {
    if (Platform.OS === 'android') {
      return this.renderOnAndroid()
    }
    return this.renderOnIOS()
  }

  render() {
    return <SafeAreaView style={{ flex: 1 }}>{this.renderViews()}</SafeAreaView>
  }
}

export default SubmitIdeas
