import { Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { styles } from '../styles.main'

function ClinicianWelcomeText({ welcomeScreenText }) {
  const colorSplashSettings = useSelector((state) => state.store.splashSettings)

  return (
    <View style={[styles.textContainer]}>
      <Text
        style={[
          styles.welcomeText,
          {
            color: colorSplashSettings?.custom_welcome_testColor || 'black',
            fontSize: colorSplashSettings?.custom_welcome_fontSize || 16,
          },
        ]}
      >
        {welcomeScreenText}
      </Text>
    </View>
  )
}

export default ClinicianWelcomeText
