export const SET_NEGATIVE_TEST = 'SET_NEGATIVE_TEST'
export const SET_GENE_LIST = 'SET_GENE_LIST'

export const SET_POSITIVE_TEST = 'SET_POSITIVE_TEST'
export const RESET_POSITIVE_TEST = 'CLEAR_POSITIVE_LIST'

export const RESET_GENE_TESTING_STORE = 'RESET_GENE_TESTING_STORE'

export const SET_FROM_ONBOARDING_GENE_TEST = 'SET_FROM_ONBOARDING_GENE_TEST'

export const SET_GENETICALLY_TESTED = 'SET_GENETICALLY_TESTED'
