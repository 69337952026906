import { StyleSheet } from 'react-native'

const borderColor = 'rgb(216,216,216)'

export default StyleSheet.create({
  iconButton: {
    width: 50,
    height: 50,
  },
  iconContainer: {
    borderWidth: 1,
    borderRadius: 25,
    borderColor,
    padding: 10,
    alignContent: 'center',
  },
})
