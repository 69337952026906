import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { useSelector } from 'react-redux'

import InviteFamilyMember from '$screens/main/dashboard/invite/InviteFamilyMember.js'
import { i18n } from '$localization/config.js'
import headerNavigationDefaultOptions from '$navigation/_utils/HeaderNavigationDefaultOptions'
import HeaderTitleComponent from '$navigation/_components/HeaderTitleComponent'
import LeftButtonComponent from '$navigation/_components/LeftButtonComponent'

const Stack = createStackNavigator()

export default function InviteFamilyMemberNavigator() {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="InviteFamilyMember"
        component={InviteFamilyMember}
        options={({ navigation }) => ({
          ...headerNavigationDefaultOptions(
            colorSettings?.nav_title_light,
            colorSettings?.post_onboarding_nav_bgcolor
          ),
          headerTitle: () => (
            <HeaderTitleComponent
              headerTitle={i18n.t('send_family_invite').default}
              headerTintColor={colorSettings?.nav_title_light}
              headerBackgroundColor={colorSettings?.post_onboarding_nav_bgcolor}
            />
          ),
          headerLeft: () => (
            <LeftButtonComponent
              navigation={navigation}
              closeButtonType="back"
              backArrowColor={colorSettings?.nav_title_light}
            />
          ),
        })}
      />
    </Stack.Navigator>
  )
}
