import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  ImageBackground,
} from 'react-native'

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'green',
    width: '100%',
    borderRadius: 10,
    overflow: 'hidden',
  },
  content: {
    flex: 1.0,
    margin: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentTitleText: {
    fontFamily: 'karla-bold',
    fontSize: 18,
    color: 'white',
    textAlign: 'center',
  },
  contentMessageText: {
    fontFamily: 'montserrat',
    fontSize: 12,
    color: 'white',
    textAlign: 'center',
  },
  nextButtonContainer: {
    marginVertical: 8,
    height: 40,
    width: 195,
    borderRadius: 20,
    borderWidth: 2.0,
    borderColor: 'white',
  },
  nextButton: {
    flex: 1.0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  nextButtonTitleText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 12,
    color: 'white',
    textAlign: 'center',
  },
})

const InviteCard = ({
  contentTitle = '',
  contentMessage = '',
  buttonTitle = '',
  cardIcon = null,
  backgroundImage = null,
  buttonOnPress = null,
  colorSettings = null,
}) => (
  <View style={styles.container}>
    <ImageBackground
      style={{ height: '100%', width: '100%' }}
      // source={ backgroundImage }
      style={{
        backgroundColor: colorSettings?.invite_family_bgcolor || 'white',
      }}
    >
      <View style={styles.content}>
        <Image
          style={{
            marginVertical: 8,
            width: 77,
            height: 77,
            resizeMode: 'contain',
          }}
          source={cardIcon}
        />

        <Text
          style={[
            styles.contentTitleText,
            {
              color: colorSettings?.text_input_title_color_2 || 'black',
            },
          ]}
        >
          {contentTitle}
        </Text>

        <View style={{ marginVertical: 8 }}>
          <Text
            style={[
              styles.contentMessageText,
              {
                color: colorSettings?.text_input_label_color_2 || 'black',
              },
            ]}
          >
            {contentMessage}
          </Text>
        </View>

        <View
          style={[
            styles.nextButtonContainer,
            {
              borderColor: colorSettings?.btn_no_fill_text_2 || 'black',
            },
          ]}
        >
          <TouchableOpacity
            style={styles.nextButton}
            onPress={() => buttonOnPress()}
          >
            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Text
                style={[
                  styles.nextButtonTitleText,
                  {
                    color: colorSettings?.btn_no_fill_text_2 || 'black',
                  },
                ]}
              >
                {buttonTitle}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </ImageBackground>
  </View>
)

export default InviteCard
