import { StyleSheet } from 'react-native'

const bgColor = 'rgba(155,89,182,1.0)'
const whiteColor = 'white'

export default StyleSheet.create({
  container: {
    backgroundColor: bgColor,
    flex: 1.0,
  },
  contentContainer: {
    paddingHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: whiteColor,
    fontFamily: 'karla-bold',
    fontSize: 24,
    marginTop: 23,
    width: '80%',
  },
  buttonContainerAdopted: {
    alignItems: 'center',
    width: '100%',
    paddingTop: 30,
    flexDirection: 'column',
    marginBottom: 10,
    justifyContent: 'center',
  },
  emptyDiseaseListMessageContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    marginTop: 50,
  },
  emptyDiseaseListMessageText: {
    textAlign: 'center',
    fontFamily: 'montserrat-medium',
    fontSize: 17,
  },
  containerStyleLoadingView: {
    height: '100%',
    marginTop: 150,
  },
  subTitleText: {
    fontSize: 18,
  },
})
