import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  mainContainer: {
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    marginLeft: 10,
  },
  button: {
    paddingVertical: 10,
  },
  title: {
    fontFamily: 'montserrat-medium',
    fontSize: 15,
  },
})

export default styles
