import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    backgroundColor: 'rgb(245,245,245)',
  },
  contentContainer: {
    backgroundColor: 'rgb(245,245,245)',
    // height: "100.1%",
  },
  content: {
    paddingHorizontal: 16,
  },
  navigationButton: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    height: '100%',
    alignItems: 'center',
  },
  navigationButtonText: {
    color: '#fff',
    fontFamily: 'montserrat',
    fontSize: 18,
    textAlign: 'right',
  },
  section: {
    backgroundColor: 'rgb(245,245,245)',
    paddingTop: 8,
    width: '100%',
    alignItems: 'center',
  },
  sectionTitleView: {
    flexDirection: 'row',
    width: '100%',
    height: 40,
    alignItems: 'center',
  },
  sectionTitleText: {
    color: 'rgb(65,70,97)',
    fontFamily: 'karla-bold',
    fontSize: 14,
    marginHorizontal: 16,
  },

  itemView: {
    backgroundColor: 'white',
    flexDirection: 'row',
    marginVertical: 5,
    paddingHorizontal: 8,
    height: 75,
    alignItems: 'center',
    borderColor: 'rgba(200,200,200,0.5)',
    borderWidth: 2,
    borderRadius: 5,
  },
  itemViewFull: {
    backgroundColor: 'white',
    flexDirection: 'row',
    height: 75,
    alignItems: 'center',
    borderColor: 'rgba(200,200,200,0.5)',
  },
  itemImageContainer: {
    flex: 0.2,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemDetailsContainer: {
    flex: 0.8,
    marginHorizontal: 8,
    height: '100%',
    justifyContent: 'center',
  },
  itemDetailsTitleText: {
    color: 'rgb(74,74,74)',
    fontFamily: 'montserrat-medium',
    fontSize: 12,
  },
  itemDetailsSubtitleText: {
    color: 'rgb(102,102,102)',
    fontFamily: 'montserrat',
    fontSize: 11,
  },

  clinicianLogoContainer: {
    alignSelf: 'center',
    borderWidth: 0.5,
    borderColor: 'rgba(255,255,255,0.2)',
    height: 240,
    width: 240,
  },
})
