const urlLinkingConfig = {
  screens: {
    AuthStack: {
      screens: {
        AuthNavigator: {
          screens: {
            MainNavigator: {
              screens: {
                CustomLoginScreen: {
                  path: '/',
                  parse: {
                    id: (inviteID) => `id=${inviteID}`,
                  },
                },
                WelcomeScreen: {
                  path: 'welcome',
                  parse: {
                    id: (userID) => `/${userID}`,
                  },
                },
                CustomValidateDOB: 'signup/login',
                CustomCreatePassword: 'signup/create_password',
                CustomInputScreen: 'signup/custom_input',
                SignupMainScreen: 'signup',
                SignupScreenPhone: 'signup/phone_verification',
                SignupScreenPhoneCode: 'signup/phone_verification_code',
                EnvSecretScreen: 'env',
                ForgotPWSelectOption: {
                  path: 'forgot_password',
                },
                ForgotPWEnterEmail: {
                  path: 'forgot_password/enter_email',
                },
                ForgotPWEnterPhoneNumber: 'forgot_password/enter_phone_number',
                ForgotPWEnterCode: 'forgot_password/enter_code',
                ForgotPWEnterNewPassword: 'forgot_password/enter_new_password',
                SignupFamilyInviteNavigator: {
                  screens: {
                    SignupFamilyInviteCode: 'signup/family_invite_code',
                    SignupFamilyInvitePhoneCode:
                      'signup/family_invite_phone_code',
                  },
                },
              },
            },
            SuccessScreenFamilyInvite: 'signup/success_screen_invite_family',
            SignupClinicianNavigator: {
              screens: {
                SignupClinicianCode: 'signup/clinician_invite_code',
              },
            },
            SignupFamilySharingSettingsNavigator: {
              screens: {
                SignupFamilySharingSettingsMain: 'family_sharing_settings',
                SignupFamilySharingSettingsDetail:
                  'family_sharing_settings_details',
              },
            },
            SuccessScreenAccountCreated: 'signup/success_account_created',
            SuccessScreenResetPassword: 'signup/success_password_reset',
          },
        },
      },
    },
    OnboardingStack: {
      screens: {
        PersonalDetailsNavigator: {
          screens: {
            PersonalDetailsNavigator: {
              screens: {
                PersonalGender: 'onboarding/personal_details/gender',
                PersonalGenderIdentity:
                  'onboarding/personal_details/gender_identity',
                PersonalDiagnosis: 'onboarding/personal_details/diagnosis',
                PersonalGeneticTesting:
                  'onboarding/personal_details/genetic_testing',
                PersonalScreenDiagnosisC:
                  'onboarding/personal_details/screen_diagnosisC',
                PersonalScreenDiagnosisD:
                  'onboarding/personal_details/screen_diagnosisD',
                PersonalEthnicity:
                  'onboarding/personal_details/personal_ethnicity',
                PersonalAncestry:
                  'onboarding/personal_details/personal_ancestry',
                skipLogic: 'onboarding/personal_details/skip_logic',
                DiseaseListMainScreen:
                  'onboarding/personal_details/disease_list_main',
                OtherDiseasesList:
                  'onboarding/personal_details/disease_list_sub',
                DiseaseSkipLogic:
                  'onboarding/personal_details/disease_skip_logic',
                SurveyCustom: 'onboarding/survey/custom',
              },
            },
            SuccessScreenPersonalHealthInfo:
              'onboarding/success_personal_health_info',
          },
        },
      },
    },
    PostOnboardingStack: {
      screens: {
        RelativesNavigator: {
          screens: {
            MainStack: {
              screens: {
                ParentsGrandparentsList:
                  'post_onboarding/parents_grandparents_list',
                ChildrenList: 'post_onboarding/children_list',
                SiblingsList: 'post_onboarding/siblings_list',
                PaternalAuntsUnclesList:
                  'post_onboarding/paternal_aunts_uncles_list',
                MaternalAuntsUnclesList:
                  'post_onboarding/maternal_aunts_uncles_list',
                EditProfileView: 'post_onboarding/edit_profile',
                DiseasesTypeView: 'post_onboarding/disease_type',
                DiseasesListView: 'post_onboarding/disease_list',
                DiseasesSkipLogicView: 'post_onboarding/disease_skip_logic',
                PositiveGeneTestingMain:
                  'post_onboarding/positive_gene_testing',
                NegativeGeneTesting: 'post_onboarding/negative_gene_testing',
              },
            },
            RelativeDiseasesNavigator: {
              screens: {
                DiseaseTypesMain:
                  'post_onboarding/relative/disease/disease_types',
                DiseasesList: 'post_onboarding/relative/disease/disease_list',
                EditMemberDisease:
                  'post_onboarding/relative/disease/edit_member_disease',
              },
            },
            RelativeGeneTestNavigator: {
              screens: {
                RelativePositiveGeneTestScreen:
                  'post_onboarding/relative/gene/positive_gene_testing',
                RelativeNegativeGeneTestScreen:
                  'post_onboarding/relative/gene/negative_gene_testing',
              },
            },
            SelectParentNavigator: {
              screens: {
                SelectParent: 'post_onboarding/relative/select_parent',
              },
            },
            SelectParentBloodRelationNavigator: {
              screens: {
                SelectParentsBloodRelation:
                  'post_onboarding/relative/select_blood_relation',
              },
            },
            SelectTwinNavigator: {
              screens: {
                SelectTwin: 'post_onboarding/relative/select_twins',
                SelectTwinType: 'post_onboarding/relative/select_twins/type',
              },
            },
          },
        },
        RelationshipInfoTutorialNavigator: {
          screens: {
            RelationshipInfoTutorial:
              'post_onboarding/relationship/relationship_info_tutorial',
          },
        },
      },
    },
    MainStack: {
      screens: {
        Main: {
          screens: {
            BottomTabNavigator: {
              screens: {
                Home: {
                  screens: {
                    DashboardTab: 'home',
                    FamilyMainView: 'home/family',
                    EditProfileView: 'home/family/edit_profile',
                    DiseasesTypeView: 'home/family/edit_profile/disease_type',
                    DiseasesListView: 'home/family/edit_profile/disease_list',
                    DiseasesSkipLogicView:
                      'home/family/edit_profile/disease_skip_logic',
                    PositiveGeneTesting:
                      'home/family/edit_profile/positive_gene_testing',
                    NegativeGeneTesting:
                      'home/family/edit_profile/negative_gene_testing',
                    RisksScreenView: 'home/risk',
                    RiskInfoView: 'home/risk/info',
                    SurveyScreenView: 'home/survey',
                    SharingSettingsMain: 'home/sharing_settings',
                    SharingSettingsDetail: 'home/sharing_settings/detail',
                    ClinicianListView: 'home/clinician',
                    ClinicianDetailsView: 'home/clinician/details',
                    GeneticResources: 'home/genetic_resources',
                    GeneticResourcesWebView: 'home/genetic_resources/web_view',
                    NotificationsMain: 'home/notifications',
                    NotificationDetail: 'home/notification/detail',
                    InviteMain: 'home/invite',
                    InviteFamilyMemberMain: 'home/invite/family_member',
                    CustomWebView: 'home/custom_web',
                  },
                },
                Family: {
                  screens: {
                    FamilyMainViewTab: 'family',
                    EditProfileView: 'family/edit_profile',
                    SurveyScreenView: 'family/survey',
                    DiseasesTypeView: 'family/edit_profile/disease_type',
                    DiseasesListView: 'family/edit_profile/disease_list',
                    DiseasesSkipLogicView:
                      'family/edit_profile/disease_skip_logic',
                    PositiveGeneTestingMain:
                      'family/edit_profile/positive_gene_testing',
                    NegativeGeneTesting:
                      'family/edit_profile/negative_gene_testing',
                  },
                },
                Risks: {
                  screens: {
                    RisksTab: 'risks',
                    GeneticResources: 'risks/genetic_resources',
                    CustomWebView: 'risks/custom_web_view',
                    RiskInfoView: 'risks/info',
                  },
                },
                Surveys: {
                  screens: {
                    SurveysTab: 'surveys',
                    GeneticResources: 'surveys/genetic_resources',
                  },
                },
              },
            },
            AddMemberNavigator: {
              screens: {
                AddFamilyMemberView: 'add_member/add_family_member',
              },
            },
            RelativeDiseasesNavigator: {
              screens: {
                DiseaseTypesMain: 'relative_disease/disease_type',
                DiseasesList: 'relative_disease/disease_list',
                EditMemberDisease: 'relative_disease/edit_member_disease',
              },
            },
            RelativeGeneTestNavigator: {
              screens: {
                RelativePositiveGeneTestScreen: 'relative_gene_test/positive',
                RelativeNegativeGeneTestScreen: 'relative_gene_test/negative',
              },
            },
            SelectParentNavigator: {
              screens: {
                SelectParent: 'select_parent',
              },
            },
            SelectParentBloodRelationNavigator: {
              screens: {
                SelectParentsBloodRelation: 'select_parent/blood_relation',
              },
            },
            SelectTwinNavigator: {
              screens: {
                SelectTwin: 'select_twin',
                SelectTwinType: 'select_twin_type',
              },
            },
            InviteFamilyMemberNavigator: {
              screens: {
                InviteFamilyMember: 'invite_family_member',
              },
            },
            SurveyCancerNavigator: {
              screens: {
                SurveyCancer: 'survey/cancer',
              },
            },
            SurveyCustomNavigator: {
              screens: {
                SurveyCustom: 'survey/custom',
              },
            },
            GeneralSettingsNavigator: {
              screens: {
                ViewOnWeb: 'view_on_web',
              },
            },
            ChangePasswordNavigator: {
              screens: {
                ChangePassword: 'change_password',
                SuccessScreenPasswordChanged: 'change_password/success',
              },
            },
            UpdatePhoneNumberNavigator: {
              screens: {
                UpdatePhoneNumber: 'update_phone_number',
              },
            },
            ResearchDataSettingsNavigator: {
              screens: {
                ResearchDataSettings: 'research_data_settings',
              },
            },
            ContactUsNavigator: {
              screens: {
                ContactUs: 'contact_us',
              },
            },
            LoginSettings: {
              screens: {
                BiometricsSettings: 'biometrics_settings',
              },
            },
            SubmitIdeas: {
              screens: {
                SubmitIdeasSettings: 'submit_ideas',
              },
            },
            AddClinicianNavigator: {
              screens: {
                AddClinicianView: 'add_clinician',
              },
            },
          },
        },
      },
    },
  },
}

export default urlLinkingConfig
