import { Component } from 'react'
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { i18n } from '$localization/config.js'

export default class SurveyButtonComponent extends Component {
  state = {
    buttonIcon: this.props.buttonIcon,
    buttonTitle: this.props.buttonTitle,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { buttonIcon } = newProps
    const { buttonTitle } = newProps
    return {
      ...prevState,
      buttonIcon,
      buttonTitle,
    }
  }

  titleConverter = (title) => {
    let newTitle = title
    try {
      const convertedTitle = title.toLowerCase().split(/[ ]+/).join('_')
      newTitle = i18n.t(convertedTitle)?.default ?? title
      return newTitle
    } catch (err) {
      return newTitle
    }
  }

  render() {
    const colorSettings = this.props.colorSettings ?? {}
    const onPressItem = this.props.onPressItem ?? function () {}

    return (
      <View>
        <TouchableOpacity
          onPress={onPressItem}
          style={[
            styles.button,
            {
              backgroundColor:
                colorSettings?.dashboard_section_btn_bg || 'white',
            },
          ]}
        >
          <Image
            style={{
              flex: Platform.OS === 'web' ? 0.4 : 0.6,
              resizeMode: 'contain',
              width: 24,
              height: 24,
            }}
            source={this.state.buttonIcon}
          />
          <Text
            style={{
              flex: 1.8,
              textAlign: 'left',
              fontFamily: 'karla-bold',
              fontSize: 18,
              color: colorSettings?.dashboard_section_btn_text || 'black',
            }}
          >
            {this.titleConverter(this.state.buttonTitle)}
          </Text>
          <Image
            style={{
              flex: 0.6,
              width: 10,
              height: 16,
              resizeMode: 'contain',
            }}
            source={require('$assets/images/new_assets/icon-dash-arrow.png')}
          />
        </TouchableOpacity>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    height: 80,
    borderRadius: 10,
    marginHorizontal: 16,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: 'rgba(0,0,0, .2)', // IOS
    shadowOffset: { height: 1, width: -1 }, // IOS
    shadowOpacity: 1, // IOS
    shadowRadius: 4, // IOS
    backgroundColor: '#fff',
    marginBottom: 10,
  },
})
