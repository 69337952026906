import { Platform, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  headerContainer: {
    backgroundColor: 'rgb(101,209,185)',
    paddingHorizontal: 20,
    paddingVertical: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    textAlign: 'center',
    color: 'white',
    fontFamily: 'montserrat',
    fontSize: 14,
  },
  inputLabelText: {
    marginVertical: 8,
    fontFamily: 'montserrat',
    fontSize: 14,
    color: 'rgba(74,74,74,0.5)',
  },
  textInputContainer: {
    height: 40,
    marginHorizontal: 0,
    alignItems: 'center',
  },
  textInputContent: {
    minWidth: '85%',
    flex: 1,
    flexDirection: 'row',
    height: '100%',
  },
  textInput: {
    width: '100%',
    fontFamily: 'montserrat',
    fontSize: 20,
    ...Platform.select({
      web: {
        outlineColor: '#ccc',
      },
    }),
  },
  textInputline: {
    backgroundColor: 'rgba(74,74,74,0.5)',
    height: 1.0,
    width: '100%',
  },
  buttonContainer: {
    height: 65,
  },
  scrollViewContentContainer: {
    marginHorizontal: 30,
    marginVertical: 30,
  },
  phoneInputViewOuterContainer: {
    justifyContent: 'center',
    marginHorizontal: 8,
  },
  phoneInputViewContainer: {
    backgroundColor: 'transparent',
  },
  textInputInnerContainer: {
    alignItems: 'center',
  },
  scrollView: {
    flexGrow: 4,
  },
})

export default styles
