import * as actionTypes from './actionTypes'

const resetGeneTestingStore = () => ({
  type: actionTypes.RESET_GENE_TESTING_STORE,
})

const setPositiveTest = (data) => ({
  type: actionTypes.SET_POSITIVE_TEST,
  data,
})

const setNegativeTest = (data) => ({
  type: actionTypes.SET_NEGATIVE_TEST,
  data,
})

const setGeneList = (data) => ({
  type: actionTypes.SET_GENE_LIST,
  data,
})

const resetPositiveList = () => ({
  type: actionTypes.RESET_POSITIVE_TEST,
})

const setFromOnboardingGeneTest = () => ({
  type: actionTypes.SET_FROM_ONBOARDING_GENE_TEST,
})

const setIsGeneticallyTested = (data) => ({
  type: actionTypes.SET_GENETICALLY_TESTED,
  data,
})

export {
  resetGeneTestingStore,
  setPositiveTest,
  setNegativeTest,
  setGeneList,
  resetPositiveList,
  setFromOnboardingGeneTest,
  setIsGeneticallyTested,
}
