import { NavigationActions } from '@react-navigation/compat'
import { CommonActions } from '@react-navigation/native'

import Alert from '$components/Alert'

import { i18n } from '$localization/config.js'

function getBackButtonType({
  isLastScreenBeforeLogin = false,
  isBackButtonHidden = false,
}) {
  if (isBackButtonHidden) return 'hidden'
  if (isLastScreenBeforeLogin) return 'custom'

  return 'back'

  // FIXME
}

function getStackRouterRoot(navigation) {
  // Get Stack Router Root to get global navigation parameters
  let currentNavigator = navigation
  let stackRouterRoot = null

  while (currentNavigator.state.key !== 'StackRouterRoot') {
    currentNavigator = currentNavigator.getParent()
  }

  stackRouterRoot = currentNavigator
  return stackRouterRoot
}

function getCurrentAppContainer(navigation) {
  // Get Stack Router Root to get global navigation parameters
  let currentNavigator = navigation
  let stackRouterRoot = null

  while (currentNavigator.state.key !== 'StackRouterRoot') {
    stackRouterRoot = currentNavigator
    currentNavigator = currentNavigator.getParent()
  }

  return stackRouterRoot
}

function showDefaultAlert(
  title = i18n.t('title').default,
  message = i18n.t('message').default
) {
  Alert.alert(title, message, [{ text: i18n.t('okay').title, style: 'cancel' }])
}

function showGenericError(message = i18n.t('data_error').default) {
  showDefaultAlert(`${i18n.t('error').default}`, message)
}

function showGenericServerError(status_code = null) {
  if (status_code) {
    showDefaultAlert(
      `${i18n.t('error').default}: ${status_code}`,
      `${i18n.t('sorry_we_cannot_process_your_request_this_time').default}`
    )
  } else {
    showDefaultAlert(
      `${i18n.t('server_error').default}`,
      `${i18n.t('sorry_we_cannot_process_your_request_this_time').default}`
    )
  }
}

function isEmpty(value) {
  return value === undefined || value === null || value === ''
}

function handleDefaultError({ errStatus, errMessage, onPress }) {
  const title = `${i18n.t('error').default}: ${errStatus}`
  const alertButtons = [{ text: i18n.t('okay').title, style: 'cancel' }]

  if (!isEmpty(onPress)) {
    alertButtons.push({ text: i18n.t('refresh').title, onPress })
  }

  Alert.alert(title, errMessage, alertButtons)
}

function resetStackAction(index = 0, hasGenderIdentity = false) {
  const navActions = [
    NavigationActions.navigate({ routeName: 'PersonalGender', key: undefined }),
  ]

  // TODO: Check if this is the correct way to navigate to sub-routes with NavigationActions
  if (hasGenderIdentity) {
    navActions.push(
      NavigationActions.navigate({
        routeName: 'PersonalDetailsNavigator',
        key: undefined,
        action: NavigationActions.navigate({
          routeName: 'PersonalGenderIdentity',
        }),
      })
    )
  }

  switch (index) {
    case 1:
      navActions.push(
        NavigationActions.navigate({
          routeName: 'PersonalDetailsNavigator',
          key: undefined,
          action: NavigationActions.navigate({
            routeName: 'DiseaseListMainScreen',
          }),
        })
      )
      break
    case 2:
      navActions.push(
        NavigationActions.navigate({
          routeName: 'PersonalDetailsNavigator',
          key: undefined,
          action: NavigationActions.navigate({
            routeName: 'DiseaseListMainScreen',
          }),
        }),
        NavigationActions.navigate({
          routeName: 'PersonalDetailsNavigator',
          key: undefined,
          action: NavigationActions.navigate({
            routeName: 'PersonalGeneticTesting',
          }),
        })
      )
      break
    case 3:
      navActions.push(
        NavigationActions.navigate({
          routeName: 'PersonalDetailsNavigator',
          key: undefined,
          action: NavigationActions.navigate({
            routeName: 'DiseaseListMainScreen',
          }),
        }),
        NavigationActions.navigate({
          routeName: 'PersonalDetailsNavigator',
          key: undefined,
          action: NavigationActions.navigate({
            routeName: 'PersonalGeneticTesting',
          }),
        }),
        NavigationActions.navigate({
          routeName: 'PersonalDetailsNavigator',
          key: undefined,
          action: NavigationActions.navigate({
            routeName: 'PersonalEthnicity',
          }),
        })
      )
      break
    case 4:
      navActions.push(
        NavigationActions.navigate({
          routeName: 'PersonalDetailsNavigator',
          key: undefined,
          action: NavigationActions.navigate({
            routeName: 'DiseaseListMainScreen',
          }),
        }),
        NavigationActions.navigate({
          routeName: 'PersonalDetailsNavigator',
          key: undefined,
          action: NavigationActions.navigate({
            routeName: 'PersonalGeneticTesting',
          }),
        }),
        NavigationActions.navigate({
          routeName: 'PersonalDetailsNavigator',
          key: undefined,
          action: NavigationActions.navigate({
            routeName: 'PersonalEthnicity',
          }),
        }),
        NavigationActions.navigate({
          routeName: 'PersonalDetailsNavigator',
          key: undefined,
          action: NavigationActions.navigate({
            routeName: 'PersonalAncestry',
          }),
        })
      )
      break
    default:
      break
  }

  const resetAction = CommonActions.reset({
    index: hasGenderIdentity ? index + 1 : index,
    key: undefined,
    routes: navActions,
  })

  return resetAction
}

function resetStackActionRelatives(index = 0) {
  const navActions = [
    NavigationActions.navigate({
      routeName: 'MainStack',
      key: undefined,
      action: NavigationActions.navigate({
        routeName: 'ParentsGrandparentsList',
      }),
    }),
  ]

  switch (index) {
    case 1:
      navActions.push(
        NavigationActions.navigate({
          routeName: 'MainStack',
          key: undefined,
          action: NavigationActions.navigate({
            routeName: 'ChildrenList',
          }),
        })
      )
      break
    case 2:
      navActions.push(
        NavigationActions.navigate({
          routeName: 'MainStack',
          key: undefined,
          action: NavigationActions.navigate({
            routeName: 'ChildrenList',
          }),
        }),
        NavigationActions.navigate({
          routeName: 'MainStack',
          key: undefined,
          action: NavigationActions.navigate({
            routeName: 'SiblingsList',
          }),
        })
      )
      break
    case 3:
      navActions.push(
        NavigationActions.navigate({
          routeName: 'MainStack',
          key: undefined,
          action: NavigationActions.navigate({
            routeName: 'ChildrenList',
          }),
        }),
        NavigationActions.navigate({
          routeName: 'MainStack',
          key: undefined,
          action: NavigationActions.navigate({
            routeName: 'SiblingsList',
          }),
        }),
        NavigationActions.navigate({
          routeName: 'MainStack',
          key: undefined,
          action: NavigationActions.navigate({
            routeName: 'PaternalAuntsUnclesList',
          }),
        })
      )
      break
    default:
      break
  }

  const resetAction = CommonActions.reset({
    index,
    key: undefined,
    routes: navActions,
  })

  return resetAction
}

export {
  getBackButtonType,
  getStackRouterRoot,
  getCurrentAppContainer,
  showDefaultAlert,
  showGenericError,
  showGenericServerError,
  resetStackAction,
  resetStackActionRelatives,
  handleDefaultError,
}
