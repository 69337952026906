import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  genesViewContainer: {
    marginTop: 2,
  },
  genesViewListItemContainer: {
    paddingVertical: 2,
  },
  genesViewListItemText: {
    color: 'rgb(102,102,102)',
    fontFamily: 'montserrat',
    fontSize: 11,
  },
})

export default styles
