import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 10,
    borderWidth: 2.0,
    borderColor: 'rgb(234,234,234)',
  },
  content: {
    flex: 1.0,
    width: '100%',
  },

  titleContainer: {
    marginHorizontal: 16,
    marginTop: 8,
    flexDirection: 'row',
    height: 50,
  },
  titleTextContainer: {
    justifyContent: 'center',
    flex: 0.8,
  },
  titleText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 14,
    textAlign: 'left',
    color: 'rgba(67,70,97,1.0)',
  },

  contentItem: {
    marginHorizontal: 16,
    marginVertical: 8,
    flexDirection: 'row',
    height: 50,
  },
  contentItemLabelTextContainer: {
    justifyContent: 'center',
    flex: 0.4,
  },
  contentItemLabelText: {
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    textAlign: 'left',
    color: 'rgba(153,153,153,1.0)',
  },

  infoButton: {
    justifyContent: 'flex-start',
  },
  infoButtonIcon: {
    width: 20,
    height: 20,
  },

  contentSeparatorLine: {
    width: '100%',
    borderBottomWidth: 1.0,
    borderBottomColor: '#ccc',
  },

  contentItemTextInputContainer: {
    flex: 0.6,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
  },

  contentItemTextInput: {
    width: '100%',
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    color: 'rgb(74,74,74)',
    textAlign: 'right',
  },

  contentItemRectangularSwitchContainer: {
    backgroundColor: 'rgb(101,209,185)',
    borderRadius: 4.0,
    width: '95%',
    height: 30,
  },
  contentItemRectangularSwitchButton: {
    justifyContent: 'center',
    borderRadius: 4.0,
    margin: 3.0,
    flex: 0.5,
  },
  contentItemRectangularSwitchButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    textAlign: 'center',
  },

  contentItemButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    minWidth: 130,
    borderColor: '#ccc',
    borderWidth: 1.0,
    borderRadius: 4.0,
    paddingHorizontal: 10,
  },
  contentItemButtonText: {
    color: 'rgb(153,153,153)',
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    textAlign: 'center',
  },

  contentItemListContainer: {
    width: '100%',
  },
  contentItemListTitleContainer: {
    flexDirection: 'row',
    height: 20,
  },
  contentItemListTitle: {
    fontFamily: 'montserrat-semibold',
    fontSize: 14,
    color: 'rgb(74,74,74)',
    textAlign: 'left',
  },

  itemListRowContainer: {
    flexDirection: 'column',
    borderRadius: 4,
    marginHorizontal: 13,
    borderWidth: 1.0,
    borderColor: '#ccc',
  },
  itemListRow: {
    flexDirection: 'row',
    height: 40,
    marginVertical: -0.5,
  },
  itemListRowLabelContainer: {
    flex: 0.8,
    height: '100%',
    paddingHorizontal: 12,
    borderWidth: 1.0,
    borderColor: '#ccc',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  itemListRowLabelText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 13,
    color: 'rgb(74,74,74)',
    textAlign: 'left',
  },
  itemListRowAccessoryContainer: {
    flex: 0.2,
    height: '100%',
    borderWidth: 1.0,
    borderColor: '#ccc',
  },
  itemListRowAccessory: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonAddContainer: {
    flexDirection: 'row',
  },
  healthHistoryAddButton: {
    alignItems: 'flex-end',
    marginRight: 5,
  },
  healthHistoryAddButtonText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 15,
    fontWeight: '600',
  },
  choiceOfDiagnosisAgeContainer: {
    width: 300,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  choiceOfDiagnosisAgeButton: {
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  choiceOfDiagnosisAgeButtonText: {
    fontSize: 16,
    color: 'white',
    fontFamily: 'montserrat',
    textDecorationLine: 'underline',
  },
})
