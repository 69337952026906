import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
  },
  textInput: {
    width: '100%',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
  },
  searchBar: {
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: 30,
    borderWidth: 2,
    minHeight: 60,
    marginBottom: 10,
    alignSelf: 'center',
    width: '100%',
    paddingHorizontal: 35,
    paddingVertical: 10,
  },
  imageContainer: {
    justifyContent: 'center',
  },
})

export default styles
