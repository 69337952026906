/** This model should be used during onboarding and authentication only  */
export default class Proband {
  // Account object
  // (TODO: To be removed) (see Account.js)
  account = {
    authCredentials: {
      accessToken: '',
      refreshToken: '',
    },
    userID: '',
    accountID: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    dialingCode: '',
    phoneNumber: '',
    dob: '',
    age: '',
  }

  // Family ID and Proband ID
  familyID = ''

  probandID = ''

  // Immediate family member IDs
  // (TODO: To be removed)
  motherID = ''

  fatherID = ''

  patGrandfatherID = ''

  patGrandmotherID = ''

  matGrandfatherID = ''

  matGrandmotherID = ''

  // Other details
  patientID = ''

  biologicalGender = ''

  genderIdentity = ''

  pronoun = ''

  race = ''

  ethnicity = ''

  ashkenazi = false

  // (TODO: To be removed)
  deceased = false

  causeOfDeath = ''

  patientAdopted = false

  fatherAdopted = false

  motherAdopted = false

  // Diseases / Skip Logic / Gene Testing
  // (TODO: To be removed)
  memberDisease = null

  memberSkipLogic = null

  memberGeneticTesting = null

  // Clinician code
  // (Note: To be used during onboarding only)
  clinicianCode = null
}
