import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    // backgroundColor: "white",
    width: '100%',
    // height: 300, // temporary
    // paddingVertical: 8,
    borderRadius: 10,
    borderWidth: 2.0,
    borderColor: 'rgb(234,234,234)',
    overflow: 'hidden',
  },
  content: {
    // backgroundColor: "gray",
    // flex: 1.0,
    // width: "100%",
  },

  titleContainer: {
    // backgroundColor: "gray",
    marginHorizontal: 16,
    marginTop: 8,
    // marginVertical: 8,
    flexDirection: 'row',
    height: 50,
  },
  titleTextContainer: {
    // backgroundColor: "pink",
    justifyContent: 'center',
    flex: 0.8,
  },
  titleText: {
    // backgroundColor: "lime",
    fontFamily: 'montserrat-semibold',
    fontSize: 14,
    textAlign: 'left',
    color: 'rgba(67,70,97,1.0)',
  },

  contentItem: {
    // backgroundColor: "green",
    // margin: 16,
    marginHorizontal: 16,
    marginVertical: 8,
    flexDirection: 'row',
    // alignItems: "center",
    height: 50,
  },
  contentItemLabelTextContainer: {
    // backgroundColor: "green",
    justifyContent: 'center',
    // alignItems: "flex-end",
    flex: 0.4,
  },
  contentItemLabelText: {
    // backgroundColor: "lime",
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    textAlign: 'left',
    color: 'rgba(153,153,153,1.0)',
  },

  infoButton: {
    justifyContent: 'flex-start',
  },
  infoButtonIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },

  contentSeparatorLine: {
    width: '100%',
    borderBottomWidth: 1.0,
    borderBottomColor: '#ccc',
    // borderBottomColor: "rgb(234,234,234)",
  },

  contentItemTextInputContainer: {
    // backgroundColor: "pink",
    flex: 0.6,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  contentItemTextInput: {
    // backgroundColor: "yellow",
    width: '100%',
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    color: 'rgb(74,74,74)',
    textAlign: 'right',
  },

  contentItemRectangularSwitchContainer: {
    backgroundColor: 'rgb(101,209,185)',
    borderRadius: 4.0,
    width: '95%',
    height: 30,
  },
  contentItemRectangularSwitchButton: {
    justifyContent: 'center',
    borderRadius: 4.0,
    margin: 3.0,
    flex: 0.5,
  },
  contentItemRectangularSwitchButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    textAlign: 'center',
  },

  contentItemButton: {
    // backgroundColor: "beige",
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    width: 130,
    borderColor: '#ccc',
    borderWidth: 1.0,
    borderRadius: 4.0,
  },
  contentItemButtonText: {
    color: 'rgb(153,153,153)',
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    textAlign: 'center',
  },

  contentItemListContainer: {
    // backgroundColor: "pink",
    // flex: 1.0,
    width: '100%',
  },
  contentItemListTitleContainer: {
    // backgroundColor: "aqua",
    flexDirection: 'row',
    // flex: 1.0,
    height: 20,
  },
  contentItemListTitle: {
    fontFamily: 'montserrat-semibold',
    fontSize: 14,
    color: 'rgb(74,74,74)',
    textAlign: 'left',
  },

  itemListRowContainer: {
    // backgroundColor: "beige",
    flexDirection: 'column',
    borderRadius: 4,
    marginHorizontal: 16,
    marginVertical: 16,
    borderWidth: 1.0,
    borderColor: '#ccc',
    overflow: 'hidden',
  },
  itemListRow: {
    flexDirection: 'row',
    // flex: 1.0,
    height: 40,
    marginVertical: -0.5,
  },
  itemListRowLabelContainer: {
    flex: 0.8,
    height: '100%',
    paddingHorizontal: 12,
    borderWidth: 1.0,
    borderColor: '#ccc',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  itemListRowLabelText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 13,
    color: 'rgb(74,74,74)',
    textAlign: 'left',
  },
  itemListRowAccessoryContainer: {
    flex: 0.2,
    height: '100%',
    borderWidth: 1.0,
    borderColor: '#ccc',
  },
  itemListRowAccessory: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
