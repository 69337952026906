import { useState, useCallback } from 'react'
import InputTextAnswerView from './InputTextAnswer.view'

export const InputTextAnswerLogic = ({
  returnGeneTestInput,
  inputGeneTest,
  colorSettings,
  toggleAddRemove,
}) => {
  const [textInput, setTextInput] = useState(inputGeneTest)

  const validationChecks = () => returnGeneTestInput(textInput)

  const textOnFocus = () => {
    if (textInput.length === 0) {
      toggleAddRemove(true)
    }
  }
  return (
    <InputTextAnswerView
      currentInput={textInput}
      setCurrentInput={setTextInput}
      colorSettings={colorSettings}
      textOnFocus={textOnFocus}
      validationChecks={validationChecks}
    />
  )
}
