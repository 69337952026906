import { Component } from 'react'
import { Text, View, StyleSheet } from 'react-native'

import { connect } from 'react-redux'

const styles = StyleSheet.create({
  badgeContainer: {
    height: 16,
    width: 16,
    marginLeft: 14,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
  },
  badgeText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 10,
    alignSelf: 'center',
  },
})

class MainTabBadge extends Component {
  state = {
    surveyBadgeCount: this.props.surveyBadgeCount,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const { surveyBadgeCount } = newProps
    return { ...prevState, surveyBadgeCount }
  }

  render() {
    const { colorSettings } = this.props
    const surveyBadgeCount = this.props.surveyBadgeCount ?? 0

    if (surveyBadgeCount <= 0) return null
    return (
      <View
        style={[
          styles.badgeContainer,
          {
            backgroundColor: colorSettings?.post_onboarding_nav_bgcolor,
            borderColor: colorSettings?.post_onboarding_nav_bgcolor,
          },
        ]}
      >
        <Text
          style={[
            styles.badgeText,
            {
              color: colorSettings?.nav_title_light,
            },
          ]}
        >
          {surveyBadgeCount}
        </Text>
      </View>
    )
  }
}

function mapGlobalStateToProps(state) {
  const { store } = state
  return {
    surveyBadgeCount: store.surveyBadgeCount,
    colorSettings: store.colorSettings,
  }
}

export default connect(mapGlobalStateToProps, null)(MainTabBadge)
