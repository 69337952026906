import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  printPdfButtonContainer: {
    width: 30,
    marginHorizontal: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  printPdfButton: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navigationCollapsibleButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  navigationCollapsibleEachButtonContainer: {
    width: 30,
    marginHorizontal: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  navigationCollapsibleButton: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default styles
