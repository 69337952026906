import { View } from 'react-native'
import { useSelector } from 'react-redux'
import uuid from 'react-native-uuid'
import styles from '../styles'
import CustomTabBarButton from './CustomTabBarButton'

function CustomTabBarComponent({ navigation, jumpTo }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)
  const { routes, index } = navigation

  return (
    <View style={styles.customTabBarContainer(colorSettings)}>
      <View style={styles.customTabBarContent}>
        {routes.map((route, _index) => (
          <CustomTabBarButton
            route={route}
            key={uuid.v4()}
            index={_index}
            currentIndex={index}
            jumpTo={jumpTo}
          />
        ))}
      </View>
    </View>
  )
}

export default CustomTabBarComponent
