import { Image, View } from 'react-native'
import { styles } from '../styles.main'
import { resizeClinicianLogo } from '../helpers/helpers'

function ClinicianLogo({ welcomeScreenClinicianLogo, dimensions }) {
  const isDesktopBrowser = dimensions.height > 970
  return (
    <View
      style={[styles.clinicianLogoContainer, isDesktopBrowser && { flex: 0.2 }]}
    >
      <View
        style={[
          styles.clinicianLogo,
          {
            width: resizeClinicianLogo(dimensions),
            height: '100%',
          },
        ]}
      >
        <Image
          source={{
            uri: `${welcomeScreenClinicianLogo}`,
          }}
          style={{
            height: '100%',
            width: '100%',
            resizeMode: 'contain',
          }}
        />
      </View>
    </View>
  )
}

export default ClinicianLogo
