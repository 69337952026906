import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    backgroundColor: 'rgb(245,245,245)',
    width: '100%',
    flex: 1.0,
  },
  contentContainer: {
    marginHorizontal: 16,
  },
  content: {
    marginVertical: 16,
  },
  contentCentered: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1.0,
  },

  headerBarMainTitle: {
    fontFamily: 'karla-bold',
    fontSize: 18,
    textAlign: 'center',
    color: '#fff',
  },
  headerBarSubtitle: {
    fontFamily: 'karla-bold',
    fontSize: 12,
    textAlign: 'center',
    color: '#fff',
  },
  itemDetailsSubtitleText: {
    color: 'rgb(102,102,102)',
    fontSize: 11,
    fontFamily: 'montserrat-italic',
  },
  normalButtonFull: {
    borderWidth: 2.0,
    flexDirection: 'row',
    width: '100%',
    height: 50,
    borderColor: 'rgba(74,74,74,0.2)',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  normalButtonText: {
    color: 'rgba(153,153,153,0.7)',
    fontFamily: 'montserrat-medium',
    fontSize: 14,
    textAlign: 'center',
  },

  modalContainer: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },

  groupContainer: {
    backgroundColor: 'white',
    width: '100%',
    // width: "95%", //temporary
    paddingVertical: 8,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: 'rgb(234,234,234)',
  },
  groupContent: {
    width: '100%',
  },
  groupContentItemContainer: {
    // borderBottomWidth: 1,
    // borderBottomColor: "#ccc",
  },
  groupContentItem: {
    // backgroundColor: "#ddd",
    flexDirection: 'row',
    flex: 1.0,
    marginVertical: 18,
    // height: 48,
    paddingHorizontal: 16,
  },
  groupContentTitleTextContainer: {
    flex: 0.8,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  groupContentTitleText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 14,
    color: 'rgb(102,102,102)',
    textAlign: 'left',
  },
  groupContentItemLabelTextContainer: {
    // backgroundColor: "pink",
    flex: 0.4,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  groupContentItemLabelText: {
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    color: 'rgba(153,153,153,1.0)',
    textAlign: 'left',
  },
  groupContentItemHelperText: {
    fontFamily: 'montserrat',
    fontSize: 13,
    color: 'rgb(102,102,102)',
    textAlign: 'justify',
  },
  groupContentItemTextInputContainer: {
    // backgroundColor: "lime",
    flex: 0.6,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  groupContentItemTextInput: {
    // backgroundColor: "green",
    // height: "75%",
    // height: 20,
    width: '100%',
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    color: 'rgb(74,74,74)',
    textAlign: 'right',
  },
  groupContentItemValueText: {
    fontFamily: 'montserrat-bold',
    fontSize: 13,
    color: 'rgb(74,74,74)',
    textAlign: 'right',
  },
  groupContentItemValueTextEmpty: {
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    color: 'rgba(74,74,74,0.5)',
    textAlign: 'right',
  },
  groupContentItemValueControlButton: {
    // backgroundColor: "rgb(101,209,185)",
    width: 25,
    height: 25,
  },
  groupContentItemValueSwitchButtonContainer: {
    flex: 0.5,
    borderRadius: 4,
    margin: 3,
    justifyContent: 'center',
  },
  groupContentItemValueSwitchButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    textAlign: 'center',
  },

  groupContentItemValueSwitchBorderedButtonContainer: {
    borderWidth: 1.0,
    borderRadius: 4,
    margin: 3,
    justifyContent: 'center',
  },

  groupContentItemListContainer: {
    flexDirection: 'column',
    borderRadius: 4,
    marginHorizontal: 16,
    marginVertical: 16,
    borderWidth: 1.0,
    borderColor: '#ccc',
    overflow: 'hidden',
  },
  groupContentItemListRow: {
    flexDirection: 'row',
    flex: 1.0,
    height: 40,
    marginVertical: -0.5,
  },
  groupContentItemListRowLabelContainer: {
    flex: 0.8,
    height: '100%',
    paddingHorizontal: 12,
    borderWidth: 1.0,
    borderColor: '#ccc',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  groupContentItemListRowLabelText: {
    fontFamily: 'montserrat-semibold',
    fontSize: 13,
    color: 'rgb(74,74,74)',
    textAlign: 'left',
  },
  groupContentItemListRowAccessoryContainer: {
    flex: 0.2,
    height: '100%',
    borderWidth: 1.0,
    borderColor: '#ccc',
  },
  groupContentItemListRowAccessory: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  relationshipItemContainer: {
    flex: 1,
    flexDirection: 'row',
    borderRadius: 4,
    marginHorizontal: 16,
    // marginVertical: 8,
    marginBottom: 16,
    borderWidth: 1.0,
    borderColor: '#ccc',
  },
  relationshipItemTextContainer: {
    padding: 12,
  },
  relationshipItemContent: {
    flex: 0.85,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  relationshipItemImage: {
    backgroundColor: '#rgba(65,70,97,0.1)',
    marginHorizontal: 12,
    marginVertical: 12,
    width: 32,
    height: 32,
  },
  relationshipItemTitleText: {
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    color: 'rgb(74,74,74)',
    textAlign: 'left',
  },
  relationshipItemSubtitleText: {
    fontFamily: 'montserrat',
    fontSize: 11,
    color: 'rgb(74,74,74)',
    textAlign: 'left',
  },

  deleteButtonContainer: {
    flex: 0.15,
    flexDirection: 'column',
    borderLeftWidth: 1.0,
    borderColor: '#ccc',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteButton: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  addRemoveButtonContainer: {
    flexDirection: 'column',
    borderRadius: 4,
    marginHorizontal: 16,
    marginVertical: 16,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    overflow: 'hidden',
    // height: 40,
  },
  addRemoveButton: {
    flexDirection: 'row',
    // height: "100%",
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  addRemoveButtonText: {
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    color: 'rgb(153,153,153)',
    textAlign: 'center',
  },

  memberListContainer: {
    marginHorizontal: 16,
    marginBottom: 16,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  memberListItemContainer: {
    flex: 1.0,
    flexDirection: 'row',
    borderRadius: 4,
    borderWidth: 1.0,
    borderColor: '#ccc',
  },
  memberListItemContent: {
    flex: 1.0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  memberListItemImage: {
    backgroundColor: '#rgba(65,70,97,0.1)',
    marginHorizontal: 12,
    marginVertical: 12,
    width: 32,
    height: 32,
  },
  memberListItemTitleText: {
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    color: 'rgb(74,74,74)',
    textAlign: 'left',
  },
  memberListItemSubtitleText: {
    fontFamily: 'montserrat',
    fontSize: 11,
    color: 'rgb(74,74,74)',
    textAlign: 'left',
  },
  doneButtonContainer: {
    justifyContent: 'center',
    backgroundColor: 'rgba(132,209,192,1.0)',
    height: '100%',
    width: '100%',
  },
  doneButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  memberListDeleteIcon: {
    flex: 0.3,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteTwinIcon: {
    width: 14,
    height: 16,
    resizeMode: 'contain',
  },
})
