import { Component } from 'react'
import { View, Text } from 'react-native'

import { i18n } from '$localization/config.js'

import MemberDiseasesSection from './components/MemberDiseases.section.js'
import MemberGeneticTestingsSection from './components/MemberGeneticTestings.section.js'

import Proband from '$data_models/Proband.js'
import MemberProfile from '$data_models/MemberProfile.js'

import custom_styles from '$screens/main/family/edit_profile/_styles/main.styles.js'
import section_styles from '$screens/main/family/edit_profile/_styles/section.styles.js'

const HEALTH_HISTORY_DATA = 'healthHistoryData'
const DISEASES_DATA = 'diseasesData'
const IS_DIAGNOSED_WITH_DISEASE = 'diagnosedWithDisease'
const GENE_TESTS_DATA = 'geneTestsData'
const IS_GENETICALLY_TESTED = 'geneTested'

class HealthHistorySection extends Component {
  parentController = this.props.parentController

  colorSettings = this.props.colorSettings

  proband = this.props.currentProband ?? new Proband()

  memberProfile = this.props.memberProfile ?? new MemberProfile()

  currentMember = this.props.currentMember ?? null

  state = {
    memberProfile: this.memberProfile,
    healthHistoryData: this.memberProfile?.healthHistoryData,
    currentMember: this.currentMember,
  }

  static getDerivedStateFromProps(newProps, prevState) {
    const memberProfile = newProps?.memberProfile
    const currentMember = newProps?.currentMember
    const healthHistoryData = memberProfile?.healthHistoryData

    return {
      ...prevState,
      memberProfile,
      healthHistoryData,
      currentMember,
    }
  }

  /* UPDATED */
  _updateState_ = (key, val) => {
    const { healthHistoryData } = this.state

    healthHistoryData[key] = val
    this.parentController?.updateState(HEALTH_HISTORY_DATA, healthHistoryData)
  }

  // #####################
  // ## Member Diseases ##
  // #####################

  /* UPDATED */
  updateDiseasesData = (key, val) => {
    const { healthHistoryData } = this.state

    const diseasesData = healthHistoryData?.diseasesData
    diseasesData[key] = val
    this._updateState_(DISEASES_DATA, diseasesData)
  }

  /* UPDATED */
  toggleDiseasesSection = (val) => {
    this.updateDiseasesData(IS_DIAGNOSED_WITH_DISEASE, val)
  }

  /* UPDATED */
  navigateToDiseasesMain = () => {
    this.parentController?.navigateToDiseasesMain()
  }

  /* UPDATED */
  editDiseaseListItem = (itemID) => {
    this.parentController?.editDiseaseListItem(itemID)
  }

  /* UPDATED */
  deleteDiseaseListItem = async (itemID) => {
    await this.parentController?.deleteDiseaseListItem(itemID)
  }

  /* UPDATED */
  updateGeneTestsData = (key, val) => {
    const { healthHistoryData } = this.state

    const geneTestsData = healthHistoryData?.geneTestsData
    geneTestsData[key] = val
    this._updateState_(GENE_TESTS_DATA, geneTestsData)
  }

  /* UPDATED */
  toggleGeneticTestingsSection = (val) => {
    this.updateGeneTestsData(IS_GENETICALLY_TESTED, val)
  }

  /* TODO: To be deprecated */
  _toggleGeneticTestingsSection_ = (val) => {
    // Toggle 'Genetic Testing section' switch
    const { healthHistoryData } = this.state

    const geneTestsData = healthHistoryData?.geneTestsData

    geneTestsData.geneTested = val

    // Update parent component state
    this.parentController?.updateParentState(
      HEALTH_HISTORY_DATA,
      healthHistoryData
    )
  }

  /* UPDATED */
  navigateToGeneticTestings = () => {
    this.parentController?.navigateToGeneticTestings()
  }

  /* UPDATED */
  navigateToPositiveTestingsOnly = () => {
    this.parentController?.navigateToPositiveTestingsOnly()
  }

  /* UPDATED */
  navigateToNegativeTestingsOnly = () => {
    this.parentController?.navigateToNegativeTestingsOnly()
  }

  /* UPDATED */
  deleteGeneticTestingItem = (itemID) => {
    this.parentController?.deleteGeneticTestingItem(itemID)
  }

  render() {
    const { healthHistoryData } = this.state

    const { diseasesData, geneTestsData } = healthHistoryData

    return (
      <View
        style={[section_styles.container, { paddingBottom: 8, zIndex: -1 }]}
      >
        <View style={section_styles.content}>
          <View style={section_styles.titleContainer}>
            <View style={section_styles.titleTextContainer}>
              <Text style={section_styles.titleText}>
                {i18n.t('health_history')?.default}
              </Text>
            </View>
          </View>

          <MemberDiseasesSection
            colorSettings={this.colorSettings}
            parentController={this}
            diseasesData={diseasesData}
          />

          <View style={section_styles.contentSeparatorLine} />

          <MemberGeneticTestingsSection
            colorSettings={this.colorSettings}
            parentController={this}
            geneTestsData={geneTestsData}
          />
        </View>
      </View>
    )
  }
}

export default HealthHistorySection
