import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  titleText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    fontFamily: 'karla-bold',
    fontSize: 24,
    marginTop: 23,
    marginBottom: 17,
  },
})

export default styles
