import { Component } from 'react'

import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
} from 'react-native'

import {
  Collapse,
  CollapseHeader,
  CollapseBody,
} from 'accordion-collapse-react-native'
import SelectButton from './personalScreenDiagnosis_components/selectButtonComponent.js'

import Account from '$data_models/Account'
import Proband from '$data_models/Proband'
import SkipLogic from '$data_models/SkipLogic.js'

import { i18n } from '$localization/config.js'

export default class PersonalScreenDiagnosis extends Component {
  isRelative = this.props.route.params?.isRelative ?? false

  diseasesData = this.props.route.params?.diseasesData ?? []

  newSkipList = this.props.route.params?.mainDiseaseList ?? new SkipLogic()

  countDiseaseCancer = this.props.route.params?.countDiseaseCancer ?? null

  account = this.props.route.params?.account ?? new Account()

  proband = this.props.route.params?.proband ?? new Proband()

  parent = this.props.route.params?.parent ?? null

  gender = this.proband.biologicalGender

  diseaseDataAPI = ''

  dataShow = []

  dataHide = []

  constructor(props) {
    super(props)

    // Get parent navigator to get parameters.
    // TODO: THERE MIGHT BE A PROBLEM -> OBSERVE IT
    const parentNavigator = this.props.navigation.getParent()
    this.account = parentNavigator.getParam('account')

    this.state = {
      collapse: false,
      // selectedDiseases: '',
      diseasesList: '',
      skipLogicList: [],
      load: false,
    }
  }

  // purely skipLogic
  skipLogicList = (skipList, id) => {
    const newSkipList = JSON.parse(JSON.stringify(skipList))
    const { skipLogicList } = this.state
    const dataModelSkipList = JSON.parse(
      JSON.stringify(this.newSkipList.skip_logic_list)
    )
    let skipId = ''

    for (const val in newSkipList) {
      skipLogicList.push(newSkipList[val])
      skipId = newSkipList[val].disease_list_id
    }
    if (skipList.length !== 0) {
      this.setState({ newSkipList })

      const filtered = dataModelSkipList.filter((value, index, arr) => {
        if (arr[index].disease_list_id !== skipId) {
          return value
        }
      })

      this.newSkipList.skip_logic_list = filtered.concat(newSkipList)
    } else {
      for (const val in skipLogicList) {
        if (skipLogicList[val].disease_list_id == id) {
          skipLogicList.splice(val, 1)
        }
      }

      this.setState({ skipLogicList })

      for (const val in dataModelSkipList) {
        if (dataModelSkipList[val].disease_list_id == id) {
          this.newSkipList.skip_logic_list.splice(val, 1)
        }
      }
    }
  }

  addDiseaseToState = () => {
    this.setState({ diseasesList: this.newSkipList.diseases_list })
  }

  removeDisease = (disease) => {
    let diseaseList = this.state.diseasesList
    let position = ''

    for (const val in diseaseList) {
      if (disease == diseaseList[val].id) {
        position = val
      }
    }

    diseaseList = diseaseList.splice(position, 1)

    this.setState({ diseaseList })
  }

  storeData = () => {
    const { gender } = this
    const diseaseData = this.diseaseDataAPI.diseases

    for (const data in diseaseData) {
      const genderData = diseaseData[data].gender_specific

      if (gender == genderData || !genderData) {
        if (diseaseData[data].show == 'always') {
          this.dataShow.push(diseaseData[data])
        } else {
          this.dataHide.push(diseaseData[data])
        }
      }
    }

    this.forceUpdate()
    this.setState({ load: true })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.load !== this.state.load) {
      if (this.proband.memberDisease) {
        const diseaseAPI = this.diseaseDataAPI.diseases
        const probandDisease = this.proband.memberDisease.diseases
        const ref = this.refs
        const newDiseaseList = []

        probandDisease.map((item) => {
          let refName = `disease${id}`

          const id = item.disease_id
          const diseases = this.getDiseaseIdFromDiseases(id)

          if (diseases.skip_logistics) {
            this.mapSkipLogic(diseases, id)
          }

          diseases.skip_logistics = []
          diseases.age_diagnosed = item.age_diagnosed

          newDiseaseList.push(diseases, id)

          refName = `disease${diseases.id}`
          ref[refName].toggleSelect()
        })

        this.newSkipList.diseases_list = newDiseaseList
      }
    }
  }

  getDiseaseIdFromDiseases = (id) => {
    const diseaseAPI = this.diseaseDataAPI.diseases

    for (const val in diseaseAPI) {
      if (diseaseAPI[val].id === id) {
        if (diseaseAPI[val].disease_type == 'cancer-polyps') {
          return {
            ...diseaseAPI[val],
          }
        }
      }
    }
  }

  mapSkipLogic = (diseases, id) => {
    const skipLogistics = diseases.skip_logistics
    const memberSkipLogic = this.proband.memberSkipLogic.skip_logic
    const skipList = this.newSkipList.skip_logic_list
    const newSkipLogic = []

    memberSkipLogic.map((item) => {
      const skipId = item.skip_logic_id
      const skipLogic = this.getSkipLogicIdFromDiseases(id, skipId)

      skipLogic.answer = item.answer
      newSkipLogic.push(skipLogic)
    })

    const combineValues = skipList.concat(newSkipLogic)
    this.newSkipList.skip_logic_list = combineValues
  }

  getSkipLogicIdFromDiseases = (id, skipId) => {
    const diseaseAPI = this.diseaseDataAPI.diseases

    for (const val in diseaseAPI) {
      if (diseaseAPI[val].id === id) {
        const skip = diseaseAPI[val].skip_logistics

        for (const val2 in skip) {
          if (skip[val2].id === skipId) {
            return {
              ...skip[val2],
            }
          }
        }
      }
    }
  }

  navigateTo = (tapped, data, selectButtonInstance, edit) => {
    const { navigation } = this.props
    const disease = this.newSkipList.diseases_list
    const skipLogic = this.newSkipList.skip_logic_list
    let { id } = data
    const count = ''

    // navigate when tapped is true
    if (!tapped) {
      navigation.push('PersonalDetailsNavigator', {
        screen: 'skipLogic',
        params: {
          data,
          mainScreenData: this,
          disease: this.newSkipList,
          selectButton: selectButtonInstance,
          edit,
        },
      })
      // delete data from skip logic data model
    } else {
      for (const valA in disease) {
        if (id == disease[valA].id) {
          disease.splice(valA, 1)
          id = data.id
        }
      }

      for (let i = 0; i < skipLogic.length * 2; i++) {
        for (const val in skipLogic) {
          if (skipLogic[val].disease_list_id == id) {
            this.newSkipList.skip_logic_list.splice(val, 1)
          }
        }
      }
    }
  }

  accessSelectButton = (id) => {
    const ref = this.refs
    const refName = `disease${id}`

    ref[refName].toggleSelect()
    // this.forceUpdate()
  }

  showDiseases = (showData) => {
    const diseaseData = showData ? this.dataShow : this.dataHide
    const diseaseButton = []

    for (const data in diseaseData) {
      const diseaseName = diseaseData[data].disease_name
      const skipLogic = diseaseData[data]
      const refName = `disease${diseaseData[data].id}`

      diseaseButton.push(
        <SelectButton
          ref={refName}
          buttonName={diseaseName}
          data={skipLogic}
          prop={this}
          skipList={this.newSkipList}
        />
      )
    }

    return diseaseButton
  }

  toggleText = () => {
    if (this.state.collapse) {
      return i18n.t('show_less').default
    }

    return i18n.t('show_more').title
  }

  nextButtonAction = () => {
    const diseaseList = this.newSkipList.diseases_list
    const { skipLogicList } = this.state
    const newDiseaseList = {
      diseases: [],
    }
    const newSkipList = {
      skip_logic: [],
    }

    if (diseaseList) {
      for (const val in diseaseList) {
        const temp = {}
        temp.disease_id = diseaseList[val].id
        temp.age_diagnosed = diseaseList[val].age_diagnosed
        newDiseaseList.diseases.push(temp)
      }
    }

    if (skipLogicList) {
      for (const val in skipLogicList) {
        const temp = {}
        temp.skip_logic_id = skipLogicList[val].id
        temp.answer = skipLogicList[val].answer
        newSkipList.skip_logic.push(temp)
      }
    }

    if (this.countDiseaseCancer) {
      this.countDiseaseCancer(diseaseList.length, 'cancer-polyps')
    }

    this.proband.memberDisease = newDiseaseList
    this.proband.memberSkipLogic = newSkipList

    if (this.parent.fromCancer(this.proband)) {
      this.parent.fromCancer(this.proband)
    }

    this.props.navigation.goBack()
  }

  render() {
    return (
      <View style={styles.container}>
        {/* Content Body */}
        <ScrollView contentContainerStyle={styles.contentContainer}>
          <Text style={styles.titleText}>
            {
              i18n.t(
                'have_you_ever_had_any_of_the_following_cancers_of_gi_polyps'
              ).default
            }
          </Text>
          <Text style={[styles.titleText, { fontSize: 18 }]}>
            {i18n.t('select_all_that_apply').default}
          </Text>
          <View
            style={[
              styles.buttonContainerAdopted,
              {
                flexDirection: 'column',
                marginBottom: 10,
                justifyContent: 'center',
              },
            ]}
          >
            {/* disease buttons */}
            {this.showDiseases(true)}
            {/* show more button */}
            <Collapse
              isCollapsed={this.state.collapse}
              style={{ width: '100%' }}
            >
              <CollapseHeader>
                <TouchableOpacity
                  style={[
                    styles.showMoreButton,
                    this.state.collapse
                      ? {
                          backgroundColor: 'rgba(255,255,255,0.5)',
                          borderWidth: 0,
                        }
                      : null,
                  ]}
                  onPress={() =>
                    this.setState((prev) => ({ collapse: !prev.collapse }))
                  }
                  activeOpacity={1}
                >
                  <Text
                    style={{
                      fontFamily: 'montserrat-medium',
                      fontSize: 14,
                      textAlign: 'center',
                      color: '#fff',
                    }}
                  >
                    {this.toggleText()}
                  </Text>
                </TouchableOpacity>
              </CollapseHeader>
              <CollapseBody>
                {/* disease buttons */}
                {this.showDiseases(false)}
              </CollapseBody>
            </Collapse>
          </View>
        </ScrollView>

        {/* Next button */}
        <View style={{ height: 60, width: '100%', justifyContent: 'flex-end' }}>
          <View style={styles.nextButtonContainer}>
            <TouchableOpacity
              style={styles.nextButton}
              onPress={this.nextButtonAction}
            >
              <Text style={styles.nextButtonText}>
                {i18n.t('done').default}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(155,89,182,1.0)',
    flex: 1.0,
  },
  contentContainer: {
    // backgroundColor: "red",
    marginHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerProgressBarContainer: {
    justifyContent: 'center',
    height: 10,
    marginTop: 10,
    marginBottom: 10,
    marginHorizontal: 18,
    borderRadius: 10,
    backgroundColor: 'rgba(155,89,182,1.0)',
    overflow: 'hidden',
  },
  headerProgressBar: {
    transform: [{ scaleX: 1.0 }, { scaleY: 5 }],
  },
  progressText: {
    justifyContent: 'center',
    color: 'rgba(255,255,255,0.25)',
    fontFamily: 'montserrat-medium',
    fontSize: 12,
    textAlign: 'center',
  },
  titleText: {
    justifyContent: 'center',
    alignSelf: 'auto',
    alignItems: 'center',
    textAlign: 'center',
    color: 'white',
    fontFamily: 'karla-bold',
    fontSize: 24,
    marginTop: 23,
    width: '80%',
  },
  itemButtonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemButton: {
    marginVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 340,
    height: 60,
    borderWidth: 2.0,
    borderColor: 'rgba(255,255,255,0.5)',
    borderRadius: 30,
  },
  itemButtonText: {
    // backgroundColor: "black",
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
    marginHorizontal: 18,
  },
  subItemButton: {
    marginVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 195,
    height: 40,
    borderWidth: 2.0,
    borderColor: 'rgba(255,255,255,0.5)',
    borderRadius: 20,
  },
  subItemButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-semibold',
    fontSize: 12,
    lineHeight: 22,
    textAlign: 'center',
    marginHorizontal: 18,
  },
  switchLabelText: {
    color: '#fff',
    fontFamily: 'karla-bold',
    fontSize: 18,
    textAlign: 'left',
  },
  nextButton: {
    justifyContent: 'center',
    backgroundColor: 'rgba(132,209,192,1.0)',
    height: '100%',
    width: '100%',
  },
  nextButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  buttonSelect: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: 30,
    borderWidth: 1,
    borderColor: '#fff',
    height: 60,
    width: '45%',
  },
  selectButtonText: {
    color: '#fff',
    fontFamily: 'montserrat-medium',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
  },
  buttonContainerAdopted: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-around',
    paddingTop: 30,
  },
  controlButton: {
    width: 80,
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputField: {
    // backgroundColor: "black",
    width: 80,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontFamily: 'montserrat',
    fontSize: 20,
    textAlign: 'center',
  },
  polypsMainContainer: {
    width: '100%',
    marginTop: 10,
  },
  polypsAgeContainer: {
    paddingHorizontal: 10,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24,
  },
  polypsAgeContainertxt1: {
    fontFamily: 'montserrat',
    flex: 2,
    fontSize: 13,
    color: '#fff',
  },
  polypsAgeContainertxt2: {
    color: '#fff',
    fontFamily: 'montserrat-semibold',
    fontSize: 13,
  },
  polypsEditText: {
    color: '#fff',
    textAlign: 'right',
    fontFamily: 'montserrat-medium',
    fontSize: 13,
  },
  polypsOtherInfo: {
    fontFamily: 'montserrat-medium',
    flex: 2,
    fontSize: 13,
    color: '#fff',
  },
  showMoreButton: {
    borderWidth: 2,
    borderColor: 'rgba(255,255,255, .5)',
    height: 36,
    marginTop: 10,
    borderRadius: 18,
    justifyContent: 'center',
    marginBottom: 20,
    marginHorizontal: 100,
  },
})
