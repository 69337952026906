import styles from '../styles'
import DiseaseListItem from './DiseaseListItem'
import { FlatList } from 'react-native'

function DiseaseSection({ diseases }) {
  return (
    <FlatList
      style={styles.sectionListSummaryDetailsContainer}
      renderItem={({ item }) => <DiseaseListItem item={item} />}
      data={diseases}
    />
  )
}

export default DiseaseSection
