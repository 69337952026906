export default class AuthCredentials {
  accessToken = ''

  refreshToken = ''

  constructor(data) {
    this.accessToken = data?.accessToken ?? ''
    this.refreshToken = data?.refreshToken ?? ''
  }
}
