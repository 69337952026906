import * as actionTypes from './actionTypes'

const setCliniciansAction = (data) => ({
  type: actionTypes.SET_CLINICIANS,
  data,
})

const clearCliniciansAction = () => ({
  type: actionTypes.CLEAR_CLINICIANS,
})

const setSelectedClinicianAction = (data) => ({
  type: actionTypes.SET_SELECTED_CLINICIAN,
  data,
})

const clearSelectedClinicianAction = () => ({
  type: actionTypes.CLEAR_SELECTED_CLINICIAN,
})

const resetClinicianStore = () => ({
  type: actionTypes.RESET_CLINICIAN_STORE,
})

export {
  setCliniciansAction,
  clearCliniciansAction,
  setSelectedClinicianAction,
  clearSelectedClinicianAction,
  resetClinicianStore,
}
