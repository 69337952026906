import { Text, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'
import styles from '../../../static/main.styles'
import { i18n } from '$localization/config.js'

function ContinueButtonPage2({ onFamilyScreenWalkthroughCloseAction }) {
  const colorSettings = useSelector((state) => state.store.colorSettings)

  return (
    <TouchableOpacity
      style={styles.roundedContinueButton(
        colorSettings?.btn_filled_enabled_1,
        colorSettings?.btn_filled_border_1
      )}
      onPress={onFamilyScreenWalkthroughCloseAction}
    >
      <Text
        style={styles.roundedContinueButtonText(
          colorSettings?.btn_filled_text_1
        )}
      >
        {i18n.t('continue')?.default}
      </Text>
    </TouchableOpacity>
  )
}

export default ContinueButtonPage2
