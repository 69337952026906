import { StyleSheet } from 'react-native'

const textColor = '#fff'
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    flexDirection: 'row',
    marginBottom: 16,
  },
  title: {
    fontFamily: 'montserrat-medium',
    fontSize: 13,
    color: textColor,
    flex: 0.7,
  },
  image: { alignSelf: 'center', height: 24, width: 24 },
  editButton: {
    flex: 0.2,
    marginLeft: 30,
  },
  deleteButton: {
    flex: 0.1,
  },
})

export default styles
