/*
Restrictions:
1 = Hidden for Grandparents and/or above
2 = Hidden for Parents
3 = Hidden for Proband, Siblings, Cousins
4 = Hidden for Children, Nephews/Nieces
5 = Hidden for Grandchildren and below
5 = Hidden for Uncles and Aunts
proband = Hidden for Proband
partner = Hidden for Partners
*/

const RELATIVE_TYPES = [
  {
    type: 'partner',
    title: 'Spouses or Partners',
    gender: null,
    restrictions: ['partner'],
    relativeSide: '',
  },
  {
    type: 'son',
    title: 'Sons',
    gender: 'm',
    restrictions: [],
    relativeSide: '',
  },
  {
    type: 'daughter',
    title: 'Daughters',
    gender: 'f',
    restrictions: [],
    relativeSide: '',
  },
  {
    type: 'brother',
    title: 'Brothers',
    gender: 'm',
    restrictions: ['partner'],
    relativeSide: '',
  },
  {
    type: 'sister',
    title: 'Sisters',
    gender: 'f',
    restrictions: ['partner'],
    relativeSide: '',
  },
  {
    type: 'uncle',
    title: 'Uncles',
    gender: 'm',
    restrictions: ['partner', '1', '2', '6'],
    relativeSide: 'dad',
  },
  {
    type: 'aunt',
    title: 'Aunts',
    gender: 'f',
    restrictions: ['partner', '1', '2', '6'],
    relativeSide: 'dad',
  },
  {
    type: 'uncle',
    title: 'Uncles',
    gender: 'm',
    restrictions: ['partner', '1', '2', '6'],
    relativeSide: 'mom',
  },
  {
    type: 'aunt',
    title: 'Aunts',
    gender: 'f',
    restrictions: ['partner', '1', '2', '6'],
    relativeSide: 'mom',
  },
  {
    type: 'parent',
    title: 'Parents',
    gender: null,
    restrictions: ['proband', 'partner', '4', '3', '2', '6'],
    relativeSide: '',
  },
]

export default RELATIVE_TYPES
