import { i18n } from '$localization/config.js'

const convertToSeconds = (age) => {
  const [value, unit] = age?.split(' ')
  const weeksUnitToSeconds = 604800
  const daysUnitToSeconds = 86400
  const monthsUnitToSeconds = 2628000
  const yearsUnitToSeconds = 31536000

  if (!unit) {
    const ageUnitFromShortAgeString = age[age.length - 1]
    const ageFromShortAgeString = +age.slice(0, age.length - 1)

    switch (ageUnitFromShortAgeString) {
      case 'w':
        return ageFromShortAgeString * weeksUnitToSeconds
      case 'd':
        return ageFromShortAgeString * daysUnitToSeconds
      case 'm':
        return ageFromShortAgeString * monthsUnitToSeconds
      default:
        return age * yearsUnitToSeconds
    }
  }

  switch (unit) {
    case 'weeks':
      return value * weeksUnitToSeconds
    case 'days':
      return value * daysUnitToSeconds
    case 'months':
      return value * monthsUnitToSeconds
    default:
      return value * yearsUnitToSeconds
  }
}

export const isAgeValid = (age, ageToCompare) => {
  if (!ageToCompare || !age) return true

  const currentAgeInSeconds = convertToSeconds(age)
  const ageToCompareInSeconds = convertToSeconds(ageToCompare)

  return currentAgeInSeconds >= ageToCompareInSeconds
}

export const handleDisplayAgeFromAgeString = (ageString) => {
  const displayAgeString = ageString?.split(' ')
  let translatedAgeString = ''

  if (!displayAgeString) return null
  if (!displayAgeString[0]) return '?'

  switch (displayAgeString[1]) {
    case 'days':
      translatedAgeString = i18n.t('days.lowercase')
      break
    case 'weeks':
      translatedAgeString = i18n.t('weeks.lowercase')
      break
    case 'months':
      translatedAgeString = i18n.t('months.lowercase')
      break
    default:
      translatedAgeString = ''
      break
  }

  return `${displayAgeString[0] + translatedAgeString.substring(0, 1)}`
}
