export default class GeneTestingMethods {
  constructor(val) {
    this.list = val
  }

  insertionSort(arr) {
    for (let i = 1; i < arr.length; i++) {
      const holder = arr[i]
      const currentVal = arr[i].id
      for (var j = i - 1; j >= 0 && arr[j].id > currentVal; j--) {
        arr[j + 1] = arr[j]
      }
      arr[j + 1] = holder
    }
    return arr
  }

  getIndex(id) {
    const { list } = this
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) {
        return i
      }
    }
    return null
  }

  insert(val) {
    const { list } = this
    list.push(val)
    return this.insertionSort(list)
  }

  remove(val) {
    const { list } = this
    const index = this.getIndex(val.id)
    list.splice(index, 1)
    return this.insertionSort(list)
  }

  convert(geneType = 'positive') {
    const { list } = this
    const newList = list.map((val) => ({
      genetic_testing_id: val.id,
      result: geneType === 'positive' ? 'p' : 'n',
    }))
    const payload = {
      genetic_testing: newList,
    }
    return payload
  }

  filterMainList(geneTestList) {
    // filter first array with second array
    const { list } = this
    const filteredList = geneTestList.filter(
      (filterItem) =>
        !list.some((excludeItem) => filterItem.id === excludeItem.id)
    )
    return filteredList
  }
}
